import React, { useEffect, useState } from 'react'
import Menu from '@mui/material/Menu'
import { Calendar } from 'react-date-range'
import format from 'date-fns/format'
import { isNull } from 'lodash'
const DatePicker = (props) => {

    const { anchorEl, placeholder, closeCalendarMenufunc, handleCalendarChangefunc, label, maxDate, open, openCalendarMenufunc, value } = { ...props };
    let minSlctdDate = new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate());
    let maxSlctdDate = new Date(new Date().getFullYear() + 20, new Date().getMonth(), new Date().getDate());
    const [date, setDate] = useState(value);
    const [calendarDate, setCalendarDate] = useState(new Date());

    const handleCustomDate = (e) => {
        handleChange(formatValue(e.target.value))
    }
    const handleChange = (dates) => {
        let date = []
        let dateInput;
        if (typeof dates === 'string') {
            setDate(dates)
            const regex = /^(\d{2}\/\d{2}\/\d{4})/;
            const matches = dates.match(regex);
            if (matches) {
                dateInput = matches[1].split("/");
                date = new Date(dateInput[2], dateInput[0] - 1, dateInput[1])
            }
            else { console.log("Invalid date string format"); }
            date && handleSetData(date);
        }
        else {
            setDate(format(dates, 'MM/dd/yyyy'))
            setCalendarDate(dates)
            handleCalendarChangefunc(dates)
        }
    }
    const formatValue = (value) => {
        const formattedString = value.replace(/\D/g, ''); // Remove all non-numeric characters
        let formattedValue = '';
        if (formattedString.length >= 1) { formattedValue += formattedString.slice(0, 2); }
        if (formattedString.length >= 3) { formattedValue += '/' + formattedString.slice(2, 4); }
        if (formattedString.length >= 5) { formattedValue += '/' + formattedString.slice(4, 8); }
        return formattedValue;
    };
    const handleSetData = (date) => {
        // -100 years and +20 years
        if (date < minSlctdDate) {
            handleCalendarChangefunc(minSlctdDate)
            setCalendarDate(minSlctdDate)
            setDate(format(minSlctdDate, 'MM/dd/yyyy'))
        }
        else if (date > maxSlctdDate) {
            handleCalendarChangefunc(maxSlctdDate)
            setCalendarDate(maxSlctdDate)
            setDate(format(maxSlctdDate, 'MM/dd/yyyy'))
        }
        else {
            handleCalendarChangefunc(date)
            setCalendarDate(date)
        }
    }
    useEffect(() => {
        setCalendarDate(!isNull(value) ? new Date(value) : new Date())
        setDate(value)
    }, [value])

    return (
        <div className='input calender'>
            <label>{label}</label>
            <div className='calender-icon'>
                <input
                    value={date}
                    onChange={handleCustomDate}
                    className='form-control'
              
                    placeholder={placeholder ? "MM/DD/YYYY" : ''}
                />
                <i className="icon-calender input-icon" onClick={openCalendarMenufunc}><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></i>
            </div>
            <Menu
                className='calender-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={closeCalendarMenufunc}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Calendar
                    date={calendarDate}
                    maxDate={maxDate}
                    onChange={handleChange}
                />
            </Menu>
        </div>
    )
}

export default DatePicker