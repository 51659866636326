import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID, ServerRequestProtected } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import CalendarInput from "../../../../utils/CalendarInput"
import { useSelector, useDispatch } from "react-redux";
import InputMask from 'react-input-mask';
import handleNumericCheck from "../../../../utils/HandleNumericKeys";
import format from 'date-fns/format';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { EmployeeActiveTab } from "../../../../actions/EmployeeRecordAction"
import { NewEmployeeData } from "../../../../actions/NewEmployeeData"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { CancelButton } from "../../../../actions/CancelButton";
import jwt_decode from 'jwt-decode';
import EmployeeInformation from "../EmployeeInformation/EmployeeInformation"
import { DateFormat, DateFormat_dMy, DateFormatCalculations, DateFormat_YMd_Time } from "../../../../utils/Constants"
import { GoTo } from "../../../../actions/GotoPage"
import { UserLogout } from "../../../../actions/UserLogout"
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CountriesStates from "../../../shared/CountriesStates/CountriesStates"
import AddNewResign from "../Resign/AddNewResign"
import { NewTabAction } from "../../../../actions/HandleTabAction"
import DatePicker from "../../../../utils/DatePicker"

const BasicInformation = (props) => {
    let EmployeeList = useSelector(state => state)?.OPEN_TAB;
    let ActiveEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    const AUTHENTICATE_API_URL = process.env.REACT_APP_AUHTENTICATE
    const Role = useSelector(state => state.RoleReducer);
    let navigate = useNavigate();
    const { type } = { ...props }
    let dispatch = useDispatch();
    let Employee_Modal = {
        ProfilePicture: "",
        DepartmentID: "",
        DesignationID: "",
        Username: "",
        FirstName: "",
        MiddleName: "",
        LastName: "",
        Nationality: "",
        Gender: "",
        DateOfBirth: null,
        MaritalStatus: "",
        CNIC: "",
        Address: "",
        City: "",
        Province: "",
        PostalCode: "",
        MobileNumber: "",
        LaneLineNumber: "",
        Education: "",
        Email: "",
        AdditionalInfo: "",
        AuthRoleID: '',
        Role: "",
        JobTitle: "",
        DateOfInterview: null,
        DateOfFinalization: null,
        DateOfJoining: null,
        InitialSalary: "",
        DesignationID: "",    //dropdown
        DepartmentID: "",   //dropdown
        ReportingTo: "",  //dropdown Manager
        TeamID: "",//dropdown
        ShiftID: "",//dropdown
        ResignType: "",
        Reason: "",
        LastDate: null,
        NoticePeriod: "",
        DateOfTermination: "",
        GrossSalary: "",
        JobStatus: "",
    }

    let valModal = {}
    let ValidationModal = {
        Username: "",
        FirstName: "",
        LastName: "",
        CNICRequierd: "",
        CNIC: "",
        Role: "",
        Email: "",
        EmailRequired: "",
        Gender: "",
    }
    let joiningValModal = {}
    let Validation_Modal_Joining = {
        JobTitle: "",
        DesignationID: "",
        DepartmentID: "",
        TeamID: "",
        ResignType: "",
        DateOfJoining: "",
        GrossSalary: "",
        JobStatus: "",
    }
    const OrganizationID = useSelector((state) => state.Organization);
    const [isLoading, setIsLoading] = useState(false)
    const [editImage, setEditImage] = useState(false)
    const [EmployeeValidationModal, setEmployeeValidationModal] = useState(ValidationModal)
    const [JoiningValidationModal, setJoiningValidationModal] = useState(Validation_Modal_Joining)
    const [EmployeeID, setEmployeeID] = useState()
    const [EmployeeSaveUpdateModal, setEmployeeSaveUpdateModal] = useState({})
    const [mainEmployeeModal, setmainEmployeeModal] = useState(Employee_Modal);
    const [isOpenResign, setIsOpenResign] = useState(false)
    const [ResignationType, setResignationType] = useState("New");
    const [isRefresh, setIsRefresh] = useState(false);
    const [isSaved, setIsSaved] = useState(null)
    const [data, setData] = useState(mainEmployeeModal);
    const API_URL = process.env.REACT_APP_API

    const [resignID, setResignID] = useState(0)
    const [addDesignstionDropDown, setAddDesignstionDropDown] = useState([]);
    const [addDepartmentDropDown, setAddDepartmentDropDown] = useState([]);
    const [addTeamDropDown, setAddTeamDropDown] = useState([]);
    const [addShiftDropDown, setAddShiftDropDown] = useState([]);
    const [roletype, setRoleType] = useState("");
    const [reload, setReload] = useState(false);
    const [addreportingTotDropDown, setAddReportingToDropDown] = useState([]);
    const [dateValidation, setDateValidation] = useState({
        DateOfInterview: '',
        DateOfFinalization: "",
        DateOfJoining: ''
    })
    ///////Calendar////////
    const [dateOfInterview, setDateOfInterview] = useState();
    const [dateOfFinalization, setDateOfFinalization] = useState();
    const [dateOfJoining, setDateOfJoining] = useState();
    const [dateOfTermination, setDateOfTermination] = useState();

    const openDateOfInterview = Boolean(dateOfInterview)
    const openDateOfFinalization = Boolean(dateOfFinalization)
    const openDateOfJoining = Boolean(dateOfJoining)
    const openDateOfTermination = Boolean(dateOfTermination)

    const closeCalendarMenuJoining = () => {
        setDateOfJoining(null)
        setDateOfInterview(null)
        setDateOfFinalization(null)
        setDateOfTermination(null)
    };
    const openCalendarMenuJoining = (event, type) => {
        if (type === 'DateOfInterview') { setDateOfInterview(event.currentTarget) }
        else if (type === 'DateOfFinalization') { setDateOfFinalization(event.currentTarget) }
        else if (type === 'DateOfJoining') { setDateOfJoining(event.currentTarget) }
        else if (type === 'DateOfTermination') { setDateOfTermination(event.currentTarget) }
        else { console.log("No Type Exist") }
    };

    const handleCalendarChangeJoining = (date, type) => {
        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, 'MM/dd/yyyy')
        //===========to split YYYY-MM-dd Format Date then to Time============//
        const currDate = DateFormat_YMd_Time(date);
        //===========to Convert dd/MM/YYYY to YYYY-MM-dd then Time============//
        const interviewDate = DateFormatCalculations(mainEmployeeModal.DateOfInterview)
        const finalizationDate = DateFormatCalculations(mainEmployeeModal.DateOfFinalization)
        const joiningDate = DateFormatCalculations(mainEmployeeModal.DateOfJoining)
        const terminationDate = DateFormatCalculations(mainEmployeeModal.DateOfTermination)

        if (type === 'DateOfInterview') {
            if (finalizationDate && joiningDate && currDate <= finalizationDate && currDate <= joiningDate) {
                setmainEmployeeModal({ ...mainEmployeeModal, DateOfInterview: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfInterview: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfInterview: '' })
            }
            else if (joiningDate && !finalizationDate && currDate <= joiningDate) {
                setmainEmployeeModal({ ...mainEmployeeModal, DateOfInterview: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfInterview: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfInterview: '' })
            }
            else if (finalizationDate && !joiningDate && currDate <= finalizationDate) {
                setmainEmployeeModal({ ...mainEmployeeModal, DateOfInterview: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfInterview: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfInterview: '' })
            }
            else if (!finalizationDate && !interviewDate) {
                setmainEmployeeModal({ ...mainEmployeeModal, DateOfInterview: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfInterview: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfInterview: '' })
            }
            // else if (currDate <= interviewDate) {
            //     setmainEmployeeModal({ ...mainEmployeeModal, DateOfInterview: FormatedDateShow })
            //     setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfInterview: FormatedDateSave })
            //     setDateValidation({ ...dateValidation, DateOfInterview: '' })
            // }
            // else if (currDate <= finalizationDate) {
            //     setmainEmployeeModal({ ...mainEmployeeModal, DateOfInterview: FormatedDateShow })
            //     setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfInterview: FormatedDateSave })
            //     setDateValidation({ ...dateValidation, DateOfInterview: '' })
            // }
            else {
                setDateValidation({ ...dateValidation, DateOfInterview: 'error' })
            }
        }
        else if (type === 'DateOfFinalization') {

            if (interviewDate && joiningDate && currDate >= interviewDate && currDate <= joiningDate) {

                setmainEmployeeModal({ ...mainEmployeeModal, DateOfFinalization: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfFinalization: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfFinalization: '' })
            }
            else if (joiningDate && !interviewDate && currDate <= joiningDate) {

                setmainEmployeeModal({ ...mainEmployeeModal, DateOfFinalization: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfFinalization: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfFinalization: '' })
            }
            else if (interviewDate && !joiningDate && currDate >= interviewDate) {

                setmainEmployeeModal({ ...mainEmployeeModal, DateOfFinalization: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfFinalization: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfFinalization: '' })
            }
            else if (!interviewDate && !joiningDate) {

                setmainEmployeeModal({ ...mainEmployeeModal, DateOfFinalization: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfFinalization: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfFinalization: '' })
            }
            // else if (!joiningDate && currDate >= interviewDate) {

            //     setmainEmployeeModal({ ...mainEmployeeModal, DateOfFinalization: FormatedDateShow })
            //     setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfFinalization: FormatedDateSave })
            //     setDateValidation({ ...dateValidation, DateOfFinalization: '' })
            // }
            // else if (!interviewDate && currDate <= joiningDate) {

            //     setmainEmployeeModal({ ...mainEmployeeModal, DateOfFinalization: FormatedDateShow })
            //     setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfFinalization: FormatedDateSave })
            //     setDateValidation({ ...dateValidation, DateOfFinalization: '' })
            // }
            else {
                setDateValidation({ ...dateValidation, DateOfFinalization: 'error' })
            }
        }
        else if (type === 'DateOfJoining') {
            if (interviewDate && finalizationDate && currDate >= interviewDate && currDate >= finalizationDate) {
                setmainEmployeeModal({ ...mainEmployeeModal, DateOfJoining: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfJoining: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfJoining: '' })
            }
            else if (finalizationDate && !interviewDate && currDate >= finalizationDate) {
                setmainEmployeeModal({ ...mainEmployeeModal, DateOfJoining: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfJoining: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfJoining: '' })
            }
            else if (interviewDate && !finalizationDate && currDate >= interviewDate) {
                setmainEmployeeModal({ ...mainEmployeeModal, DateOfJoining: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfJoining: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfJoining: '' })
            }
            else if (!interviewDate && !finalizationDate) {
                setmainEmployeeModal({ ...mainEmployeeModal, DateOfJoining: FormatedDateShow })
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfJoining: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateOfJoining: '' })
            }
            else {
                setDateValidation({ ...dateValidation, DateOfJoining: 'error' })
            }
        }
        else if (type === 'DateOfTermination') {

            setmainEmployeeModal({ ...mainEmployeeModal, DateOfTermination: FormatedDateShow })
            setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, DateOfTermination: FormatedDateSave })

        }
        closeCalendarMenuJoining()
    }

    let token = useSelector(state => state.TokenReducer);
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let Token = token && jwt_decode(token)
    let UserLogged = Token.UserID + ""
    let EmolpyeeID = "";
    if (!isNull(type) && type === "Employee") {
        EmolpyeeID = activeEmployee + "";
    }
    else {
        EmolpyeeID = Token.UserID + "";
    }

    const [newEmployeeSaved, setNewEmployeeSaved] = useState(false);
    const [ddRoles, setDDRoles] = useState([])
    ///////Calendar////////
    const [dateofBirth, setDateofBirth] = useState();
    const openDateofBirth = Boolean(dateofBirth)

    const handleCalendarChange = (date, type) => {
        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, 'MM/dd/yyyy')

        let modal = { ...mainEmployeeModal };
        let saveModal = { ...EmployeeSaveUpdateModal }
        modal[type] = FormatedDateShow
        setmainEmployeeModal(modal)
        for (var property in Employee_Modal) {
            if (type === property) {
                saveModal[type] = FormatedDateSave
            }
        }
        setEmployeeSaveUpdateModal(saveModal)
        closeCalendarMenu()
        closeCalendarMenuJoining()
    }

    const closeCalendarMenu = () => {
        setDateofBirth(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'DateOfBirth') { setDateofBirth(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    ////////////////////////
    const handleResignationPopup = (ID, RequestType) => {
        setResignationType(RequestType);
        setIsOpenResign(true);
        setResignID(ID);
    }
    const ClosePopupScreen = () => {
        setIsOpenResign(false);
        if (isRefresh) {
            // LoadData()
        }
    }
    const ClosePopupScreenOnSave = () => {
        setIsOpenResign(false);
        setIsRefresh(Math.random())
    }

    let ResignPopup = ""
    if (isOpenResign) {
        ResignPopup = <AddNewResign isOpenResign={isOpenResign} onClose={ClosePopupScreen} ID={resignID} setIsRefresh={setIsRefresh} type={type} Data={mainEmployeeModal} resignType={ResignationType} onCloseSave={ClosePopupScreenOnSave} LoadData={() => {}} />
    }
    ////////////////////////
    useEffect(() => {
        setIsLoading(true)
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Department,
        }).then((response) => {
            setIsLoading(false)
            setAddDepartmentDropDown(response)
        }).catch((error) => {

            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Designation,
        }).then((response) => {
            setIsLoading(false)
            setAddDesignstionDropDown(response)
        }).catch((error) => {

            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })

        // ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
        //     "TableName": enumUtil.enumAPITableName.Employee,
        // }).then((response) => {
        //     setIsLoading(false)
        //     setAddReportingToDropDown(response)
        // }).catch((error) => {

        //     // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        //     setIsLoading(false)
        // })

        // ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
        //     "TableName": enumUtil.enumAPITableName.Team,
        // }).then((response) => {
        //     setIsLoading(false)
        //     setAddTeamDropDown(response)
        // }).catch((error) => {

        //     // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        //     setIsLoading(false)
        // })

        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Shift,
        }).then((response) => {
            setIsLoading(false)
            setAddShiftDropDown(response)
        }).catch((error) => {

            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })

        ServerRequestProtectedParentID(API_URL + "/LoadRolesDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Roles,
        }).then((response) => {
            setDDRoles(response)
            setIsLoading(false)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })
        // ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
        //     "TableName": enumUtil.enumAPITableName.Team,
        // }).then((response) => {
        //     setIsLoading(false)
        //     setAddTeamDropDown(response)
        // }).catch((error) => {
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        //     setIsLoading(false)
        // })

        ////Updating Opened Employee Id///
        if (EmolpyeeID == 0.1) {
            if (!newEmployeeSaved) { setEmployeeID(0) }
        } else { setEmployeeID(EmolpyeeID) }
        //////
        if (!isNull(EmployeeID)) {
            setIsLoading(true)
            ServerRequestProtected(API_URL + "/FindByID", 'post', {
                "ID": EmployeeID,
                "TableName": enumUtil.enumAPITableName.Employee
            }).then((response) => {
                setmainEmployeeModal(response);
                EmployeeSaveUpdateModal["AuthUserID"] = response?.AuthUserID;
                setResignID(response.ResignationID)
                setRoleType(response?.Role);
                if (UserLogged === EmolpyeeID) {
                    setReload(true);

                } else {
                    setReload(false);
                }
                if (EmolpyeeID === 0.1) { dispatch(NewEmployeeData({ id: response.EmployeeID, FirstName: response.FirstName })) }
                setIsLoading(false)
            }).catch((error) => {
                // NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [EmployeeID, isRefresh])
    const EmployeeValidation = async () => {
        let str = mainEmployeeModal.CNIC.replace(/_/g, "");
        let ValidationModal = {
            CNICRequierd: FormValidator(
                mainEmployeeModal.CNIC,
                enumUtil.enumValidationType.Required,
                'CNIC',
            ),
            Username: FormValidator(
                mainEmployeeModal.Username,
                enumUtil.enumValidationType.Required,
                'User Name',
            ),
            FirstName: FormValidator(
                mainEmployeeModal.FirstName,
                enumUtil.enumValidationType.Required,
                'First Name',
            ),
            LastName: FormValidator(
                mainEmployeeModal.LastName,
                enumUtil.enumValidationType.Required,
                'Last Name',
            ),
            CNIC: FormValidator(
                str.replace(/-/g, "",),
                enumUtil.enumValidationType.Length,
                'CNIC',
                13,
            ),
            EmailRequired: FormValidator(
                mainEmployeeModal.Email,
                enumUtil.enumValidationType.Required,
                'Email',
            ),
            Email: FormValidator(
                mainEmployeeModal.Email,
                enumUtil.enumValidationType.Email,
                'Email',
            ),
            Gender: FormValidator(
                mainEmployeeModal.Gender,
                enumUtil.enumValidationType.Required,
                'Gender',
            ),
            DateOfBirth: FormValidator(
                mainEmployeeModal.DateOfBirth,
                enumUtil.enumValidationType.Required,
                'Date of Birth',
            ),

        }
        await setEmployeeValidationModal(ValidationModal)
        valModal = ValidationModal

    }
    const JoiningValidation = async () => {
        let ValidationModal = {
            Role: FormValidator(
                mainEmployeeModal.AuthRoleID,
                enumUtil.enumValidationType.Required,
                'Role',
            ),
            JobTitle: FormValidator(
                mainEmployeeModal.JobTitle,
                enumUtil.enumValidationType.Required,
                'Job Title',
            ),
            DesignationID: FormValidator(
                mainEmployeeModal.DesignationID,
                enumUtil.enumValidationType.Required,
                'Designation ID',
            ),
            // DepartmentID: FormValidator(
            //     mainEmployeeModal.DepartmentID,
            //     enumUtil.enumValidationType.Required,
            //     'Department ID',
            // ),
            TeamID: FormValidator(
                mainEmployeeModal.TeamID,
                enumUtil.enumValidationType.Required,
                'Team ID',
            ),
            DateOfJoining: FormValidator(
                mainEmployeeModal.DateOfJoining,
                enumUtil.enumValidationType.Required,
                'Joining Date',
            ),
            DateOfFinalization: FormValidator(
                mainEmployeeModal.DateOfFinalization,
                enumUtil.enumValidationType.Required,
                'Hiring Date',
            ),
            GrossSalary: FormValidator(
                mainEmployeeModal.GrossSalary,
                enumUtil.enumValidationType.Required,
                'Gross Salary',
            ),
            JobStatus: FormValidator(
                mainEmployeeModal.JobStatus,
                enumUtil.enumValidationType.Required,
                'Job Status',
            ),
        }
        await setJoiningValidationModal(ValidationModal)
        joiningValModal = ValidationModal

    }
    const handleEmployeeChange = (e) => {

        let { name, value } = e.target;
        let modal = { ...mainEmployeeModal };
        let saveModal = { ...EmployeeSaveUpdateModal }
        if (name === "Username") {
            modal[name] = value.toString().trim().replace(/\s+/g, '')
        } else {
            modal[name] = value
        }

        setmainEmployeeModal(modal)
        for (var property in Employee_Modal) {
            if (name === property) {
                if (name === "Username") {
                    saveModal[name] = value.toString().trim().replace(/\s+/g, '');
                } else {
                    if (name === "AuthRoleID") {
                        let selectedText = e.target.options[e.target.selectedIndex].textContent;
                        saveModal["Role"] = selectedText
                    }
                    saveModal[name] = value
                }
            }
        }
        setEmployeeSaveUpdateModal(saveModal)
    }
    const handleEmployeeProfileChange = (e) => {
        const Type = e.target.files[0].type.substring(0, 5);
        if (Type === 'image') {
            let file = e.target.files[0]
            let reader = new FileReader();

            reader.onloadend = () => {
                let value = reader.result.toString();
                let { name } = e.target;
                let modal = { ...mainEmployeeModal };
                let saveModal = { ...EmployeeSaveUpdateModal }
                modal[name] = value
                setmainEmployeeModal(modal)
                for (var property in Employee_Modal) {
                    if (name === property) {
                        saveModal[name] = value
                    }
                }
                setEmployeeSaveUpdateModal(saveModal)
            }
            reader.readAsDataURL(file);
        }
    }
    const handleSaveEmployee = async () => {

        await EmployeeValidation()
        if (Role === 1) {
            await JoiningValidation()
        }

        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        let JoinValidation = FormValidator([joiningValModal], enumUtil.enumValidationType.NullCheck)

        if (validation || JoinValidation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        }
        else {
            // EmployeeSaveUpdateModal['OrganizationID'] = OrganizationID + "";
            let employeeName = mainEmployeeModal?.FirstName
            if (EmployeeSaveUpdateModal.CNIC != null) {
                EmployeeSaveUpdateModal['CNIC'] = EmployeeSaveUpdateModal.CNIC.replace(/-/g, "");
            }
            setEmployeeSaveUpdateModal(EmployeeSaveUpdateModal)
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Employee,
                ID: EmployeeID + "",
                // ParentID: OrganizationID + "",
                data: { ...EmployeeSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtected(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    setIsSaved(Math.random() * 100)
                    if (reload && !isNull(EmployeeSaveUpdateModal.Role) && roletype !== EmployeeSaveUpdateModal.Role) {

                        setReload(false);
                        navigate("../", { replace: true })
                        NotificationHandler("Logged Out", enumUtil.enumtoaster.success)
                        Cookies.remove("cookedToken")
                        dispatch(UserLogout());
                        dispatch(GoTo(''));
                    } else {
                        NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                        setNewEmployeeSaved(true)
                        setEmployeeID(response.id)
                        let obj = {
                            key: 2,
                            name: "Employee",
                            active: true,
                            data: {
                                id: response?.id,
                                FirstName: employeeName,
                                // PayrollDate: data.PayrollDate,
                                // Employees: data.Employees,
                                // Deductions: data.Deductions,
                                // TotalTax: data.TaxAmount,
                                // NetSalary: data.NetSalary,
                            },
                        };
                        let arrList = EmployeeList.map(item => {
                            if (item.data.id === 0.1 || item.data.id == response?.id) {
                                return obj;
                            } else { return item }
                        })

                        dispatch(NewTabAction(arrList));

                    }

                    // dispatch(EmployeeActiveTab(response.id))
                    // dispatch(EmployeeNewEmployee(response.id))

                }
            }).catch((error) => {
                setIsLoading(false);
                // setIsSaved(false);
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
            })
        }
    }
    const handleResendEmail = (e) => {
        handleSaveEmployee();
        let saveModal = {
            "UserID": mainEmployeeModal.EmployeeID,
            "UserName": mainEmployeeModal.Username,
            "Email": mainEmployeeModal.Email
        }
        ServerRequestProtected(AUTHENTICATE_API_URL + "/UpdateUser", 'post', saveModal)
            .then((response) => {
                setIsLoading(false)
                NotificationHandler("Email has been resent suceessfully", enumUtil.enumtoaster.success)
            }).catch((error) => {
                setIsLoading(false)
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
            })
    }

    useEffect(() => {
        setAddTeamDropDown([])
        setAddReportingToDropDown([])
        setAddShiftDropDown([])
        let saveTeamModal = {
            "TableName": enumUtil.enumAPITableName.Team,
        }
        if (!isNull(mainEmployeeModal?.DepartmentID)) {
            saveTeamModal = { ...saveTeamModal, "ID": mainEmployeeModal?.DepartmentID }
        }
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', saveTeamModal).then((response) => {
            setIsLoading(false)
            if (response?.length === 1) {
                setmainEmployeeModal({ ...mainEmployeeModal, TeamID: response[0]?.TeamID });
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, TeamID: response[0]?.TeamID + '' })
            }
            setAddTeamDropDown(response)
        }).catch((error) => {
            mainEmployeeModal['TeamID'] = ""
            mainEmployeeModal['ReportingTo'] = ""
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })
        let saveShiftModal = {
            "TableName": enumUtil.enumAPITableName.Shift,
        }
        if (!isNull(mainEmployeeModal?.DepartmentID)) {
            saveShiftModal = { ...saveShiftModal, "ID": mainEmployeeModal?.DepartmentID }
        }
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', saveShiftModal).then((response) => {
            setIsLoading(false)
            setAddShiftDropDown(response)
        }).catch((error) => {

            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })
    }, [mainEmployeeModal?.DepartmentID])

    useEffect(() => {
        setAddReportingToDropDown([])
        let saveModal = {
            "TableName": enumUtil.enumAPITableName.TeamLead,
        }
        if (!isNull(mainEmployeeModal?.TeamID)) {
            saveModal = { ...saveModal, "ID": mainEmployeeModal?.TeamID }
        }
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', saveModal).then((response) => {
            setIsLoading(false)
            if (response?.length === 1) {
                setmainEmployeeModal({ ...mainEmployeeModal, ReportingTo: response[0]?.EmployeeID });
                setEmployeeSaveUpdateModal({ ...EmployeeSaveUpdateModal, ReportingTo: response[0]?.EmployeeID + '' })
            }
            setAddReportingToDropDown(response)
        }).catch((error) => {
            mainEmployeeModal['ReportingTo'] = ""
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })
    }, [mainEmployeeModal?.TeamID])

    return (
        <React.Fragment>
            {isLoading ? <Loader /> : null}
            {ResignPopup}
            {/* <div className="app-padding">
           <div className="h-100 tabs-container">
               <div className="row h-100">
                   <div className="col-md-6">
                       <div className="row">
                           <div className="col-md-6">
                               <div className="emp-profile-img mb-4 pl-4">
                                    <div className="img-container circle s-150 hk-imgHandler">
                                    {mainEmployeeModal.ProfilePicture ? (<img  src={mainEmployeeModal.ProfilePicture} alt="" />
                                    ):(<img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />)}
                                        <input type="file" 
                                        id="ProfilePicture"
                                        name="ProfilePicture"
                                        onChange={handleEmployeeProfileChange}/>
                                    </div>
                                    <div className="upload-emp-img mt-3">
                                    <label className='d-flex align-items-center  gap-10'>
                                        <input type="file" 
                                        id="ProfilePicture"
                                        name="ProfilePicture"
                                        onChange={handleEmployeeProfileChange}/>
                                        <i className="bi bi-camera fs-20 lh-1"></i>
                                        Change profile photo</label>
                                    </div> 
                               </div>
                               <div className={EmployeeValidationModal.Username?"form-group input error":"form-group input"}>
                                   <label>Username<span className='req'>*</span></label>
                                   <input type="text"
                                       className="form-control"
                                       name="Username"
                                       id="Username"
                                       value={mainEmployeeModal.Username}
                                       onChange={handleEmployeeChange}
                                   />
                                  {EmployeeValidationModal.Username}
                               </div>
                               <div className={EmployeeValidationModal.FirstName?"form-group input error":"form-group input"}>
                                   <label>First Name<span className='req'>*</span></label>
                                   <input type="text"
                                       className="form-control"
                                       name="FirstName"
                                       id="FirstName"
                                       value={mainEmployeeModal.FirstName}
                                       onChange={handleEmployeeChange}
                                   />
                                  {EmployeeValidationModal.FirstName}
                               </div>
                               <div className="form-group input">
                                   <label>Middle Name</label>
                                   <input type="text"
                                       className="form-control"
                                       name="MiddleName"
                                       id="MiddleName"
                                       value={mainEmployeeModal.MiddleName}
                                       onChange={handleEmployeeChange}
                                   />
                               </div>
                               <div className={EmployeeValidationModal.LastName?"form-group input error":"form-group input"}>
                                   <label>Last Name<span className='req'>*</span></label>
                                   <input type="text"
                                       className="form-control"
                                       name="LastName"
                                       id="LastName"
                                       value={mainEmployeeModal.LastName}
                                       onChange={handleEmployeeChange}
                                   />
                                   {EmployeeValidationModal.LastName}
                               </div>
                               
                           </div>
                           <div className="col-md-6">
                           <div className="form-group input">
                                   <label>Nationality</label>
                                   <select type="text"
                                       className="form-control"
                                       name="Nationality"
                                       id="Nationality"
                                       maxLength={35}
                                       value={mainEmployeeModal.Nationality}
                                       onChange={handleEmployeeChange}
                                   >
                                     <option>Select Nationality</option>
                                        <option value="Afghanistan">Afghanistan</option><option value="Albania">Albania</option><option value="Algeria">Algeria</option><option value="Antarctica">Antarctica</option><option value="Argentina">Argentina</option><option value="Armenia">Armenia</option>
                                        <option value="Australia">Australia</option><option value="Austria">Austria</option><option value="Azerbaijan">Azerbaijan</option><option value="Bahamas">Bahamas</option><option value="Bahrain">Bahrain</option><option value="Bangladesh">Bangladesh</option>
                                        <option value="Belarus">Belarus</option><option value="Belgium">Belgium</option><option value="Bermuda">Bermuda</option><option value="Botswana">Botswana</option><option value="Bhutan">Bhutan</option><option value="Bolivia">Bolivia</option><option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                        <option value="Brazil">Brazil</option><option value="British Virgin Islands">British Virgin Islands</option><option value="Bulgaria">Bulgaria</option><option value="Cambodia">Cambodia</option><option value="Canada">Canada</option>
                                        <option value="Chile">Chile</option><option value="China">China</option><option value="Colombia">Colombia</option><option value="Croatia">Croatia</option><option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option><option value="Czech Republic">Czech Republic</option><option value="Denmark">Denmark</option><option value="Egypt">Egypt</option><option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option><option value="Finland">Finland</option><option value="France">France</option>
                                        <option value="Germany">Germany</option><option value="Ghana">Ghana</option><option value="Greece">Greece</option><option value="Greenland">Greenland</option><option value="Hong Kong">Hong Kong</option><option value="Hungary">Hungary</option><option value="Iceland">Iceland</option><option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option><option value="Iran">Iran</option><option value="Iraq">Iraq</option><option value="Ireland">Ireland</option><option value="Israel">Israel</option><option value="Italy">Italy</option>
										<option value="Japan">Japan</option><option value="Jordan">Jordan</option><option value="Kazakhstan">Kazakhstan</option><option value="Kenya">Kenya</option><option value="Kyrgyzstan">Kyrgyzstan</option><option value="Kosovo">Kosovo</option>
                                        <option value="Kuwait">Kuwait</option><option value="Latvia">Latvia</option><option value="Lebanon">Lebanon</option><option value="Liberia">Liberia</option>
                                        <option value="Luxembourg">Luxembourg</option><option value="Malaysia">Malaysia</option><option value="Maldives">Maldives</option><option value="Malta">Malta</option><option value="Mexico">Mexico</option>
                                        <option value="Morocco">Morocco</option><option value="North Korea">North Korea</option><option value="Nepal">Nepal</option><option value="Netherlands">Netherlands</option><option value="New Zealand">New Zealand</option>
                                        <option value="Nigeria">Nigeria</option><option value="Norway">Norway</option><option value="Oman">Oman</option><option value="Pakistan">Pakistan</option><option value="Palestinian">Palestinian</option>
                                        <option value="Panama">Panama</option><option value="Peru">Peru</option><option value="Philippines">Philippines</option><option value="Portugal">Portugal</option><option value="Qatar">Qatar</option>
                                        <option value="Romania">Romania</option><option value="Russian Federation">Russian Federation</option><option value="Saudi Arabia">Saudi Arabia</option><option value="Singapore">Singapore</option>
                                        <option value="South Korea">South Korea</option><option value="South Africa">South Africa</option><option value="Spain">Spain</option><option value="Sri Lanka">Sri Lanka</option><option value="Sudan">Sudan</option><option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option><option value="Tajikistan">Tajikistan</option><option value="Thailand">Thailand</option><option value="Turkey">Turkey</option>
                                        <option value="Ukraine">Ukraine</option><option value="United Arab Emirates">United Arab Emirates</option><option value="United Kingdom">United Kingdom</option><option value="United States">United States</option>
                                        <option value="Uzbekistan">Uzbekistan</option><option value="Vatican City">Vatican City</option><option value="Venezuela">Venezuela</option><option value="Western Sahara">Western Sahara</option><option value="Yemen">Yemen</option><option value="Zambia">Zambia</option><option value="Zimbabwe">Zimbabwe</option>
                                   </select>
                               </div>
                               <div className="form-group input">
                                   <label>Marital Status</label>
                                   <select  type="text"
                                       className="form-control"
                                       name="MaritalStatus"
                                       value={mainEmployeeModal.MaritalStatus}
                                       id="MaritalStatus"
                                       onChange={handleEmployeeChange}
                                       >
                                       <option value="">Select</option>
                                       <option value="Single">Single</option>
                                       <option value="Married">Married</option>
                                       <option value="Rather undefined">Rather Undefined</option>
                                   </select>
                                </div>
                                <div className="form-group input">
                                   <label>Gender</label>
                                   <select  type="text"
                                       className="form-control"
                                       name="Gender"
                                       value={mainEmployeeModal.Gender}
                                       id="Gender"
                                       onChange={handleEmployeeChange}
                                       >
                                       <option value="">Select</option>
                                       <option value="M">Male</option>
                                       <option value="M">Female</option>
                                       <option value="Rather undefined">Rather Undefined</option>
                                   </select>
                                </div>
                                <div className="form-group input">
                                <CalendarInput 
                                    label = 'Date of Birth'
                                    maxDate={new Date()}
                                    value ={mainEmployeeModal.DateOfBirth}
                                    openCalendarMenufunc= {(event) => openCalendarMenu(event, 'DateOfBirth')}
                                    anchorEl= {dateofBirth}
                                    open= {openDateofBirth}
                                    closeCalendarMenufunc= {closeCalendarMenu}
                                    handleCalendarChangefunc= {date => handleCalendarChange(date,'DateOfBirth')}
                                     
                                />
                                </div>
                                <div className={EmployeeValidationModal.CNIC?"form-group input error":"form-group input"}>
                                    <div className={EmployeeValidationModal.CNICRequierd?"form-group input error":"form-group input"}>
                                            <label>CNIC<span className='req'>*</span></label>
                                            <InputMask type="text"
                                                minLength={11}
                                                maskChar={0}
                                                mask='99999-9999999-9'
                                                className="form-control"
                                                name="CNIC"
                                                id="CNIC"
                                                value={mainEmployeeModal.CNIC}
                                                onChange={handleEmployeeChange}
                                                onKeyPress={(event) => handleNumericCheck(event)}
                                            />
                                            {EmployeeValidationModal.CNIC}
                                            {EmployeeValidationModal.CNICRequierd}
                                    </div>
                                </div>
                                <div className="form-group input">
                                    <label>Additional Info</label>
                                    <input type="text"
                                        className="form-control"
                                        name="AdditionalInfo"
                                        id="AdditionalInfo"
                                        value={mainEmployeeModal.AdditionalInfo}
                                        onChange={handleEmployeeChange}
                                    />
                                </div>
                                <div className={EmployeeValidationModal.Role?"form-group input error":"form-group input"}>
                                    <label>Role<span className="req">*</span></label>
                                    <select 
                                    className="form-control"
                                    name="Role"
                                    id="Role"
                                    aria-label="Default select example"
                                    value={mainEmployeeModal.Role}
                                    onChange={handleEmployeeChange}
                                    disabled
                                    >
                                    {ddRoles.map((e,index)=>(
                                    <option  value={e.Name} key={index}>
                                        {e.Name}
                                    </option>
                                    ))}
                                    </select>
                                    {EmployeeValidationModal.Role}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="app-accordian-info">
                        <Accordion expanded>
                            <AccordionSummary 
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <p className="heading">Address Information</p>
                            </AccordionSummary>
                            <AccordionDetails> 
                            <div className='row row-gap-15 row-half'>
                            <div className="col-md-12">
                                        <div className="input">
                                            <label>Address</label>
                                            <input type="text"
                                                className="form-control"
                                                name="Address"
                                                id="Address"
                                                value={mainEmployeeModal.Address}
                                                onChange={handleEmployeeChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='form-group input'>
                                        <label>Province</label>
                                        <select type="text"
                                            className="form-control"
                                            name="Province"
                                            id="Province"
                                            value={mainEmployeeModal.Province}
                                            onChange={handleEmployeeChange}
                                        >
                                            <option value="">Select Province</option>
                                            <option value="Federal Capital Territory">Federal Capital Territory</option>
                                            <option value="Azad Jammu and Kashmir">Azad Jammu and Kashmir</option>
                                            <option value="Balochistan">Balochistan</option>
                                            <option value="Khyber Pakhunkhwa">Khyber Pakhunkhwa</option>
                                            <option value="Punjab">Punjab</option>
                                            <option value="Sindh">Sindh</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='form-group input'>
                                            <label>City</label>
                                            <select type="text"
                                                className="form-control"
                                                name="City"
                                                id="City"
                                                value={mainEmployeeModal.City}
                                                onChange={handleEmployeeChange}
                                            >
                                            <option value="">Select City</option>
                                                        <option value="Abbottabad">Abbottabad</option><option value="Ahmadpur">Ahmadpur</option><option value="Arifwala">Arifwala</option><option value="Attock">Attock</option>
                                                        <option value="Badin">Badin</option><option value="Bahawalnagar">Bahawalnagar</option><option value="Bahawalpur">Bahawalpur</option><option value="Bhakkar">Bhakkar</option>
                                                        <option value="Bolhari">Bolhari</option><option value="Burewala">Burewala</option><option value="Chakwal">Chakwal</option><option value="Chaman">Chaman</option>
                                                        <option value="Charsadda">Charsadda</option><option value="Chiniot">Chiniot</option><option value="Chishtian">Chishtian</option><option value="Dadu">Dadu</option>
                                                        <option value="Daska">Daska</option><option value="Dera Ghazi Khan">Dera Ghazi Khan</option><option value="Dera Ismail Khan">Dera Ismail Khan</option><option value="Faisalabad">Faisalabad</option>
                                                        <option value="Firozwala">Firozwala</option><option value="Ghotki">Ghotki</option><option value="Gojra">Gojra</option><option value="Gujranwala">Gujranwala</option>
                                                        <option value="Gujrat">Gujrat</option><option value="Hafizabad">Hafizabad</option><option value="Haroonabad">Haroonabad</option><option value="Hyderabad">Hyderabad</option>
                                                        <option value="Islamabad">Islamabad</option><option value="Jacobabad">Jacobabad</option><option value="Jhang">Jhang</option><option value="Kabal">Kabal</option>
                                                        <option value="Kamoki">Kamoki</option><option value="Karachi">Karachi</option><option value="Kasur">Kasur</option><option value="Khairpur">Khairpur</option>
                                                        <option value="Khanewal">Khanewal</option><option value="Khanpur">Khanpur</option><option value="Khushab">Khushab</option><option value="Khuzdar">Khuzdar</option>
                                                        <option value="Kohat">Kohat</option><option value="Kot Addu">Kot Addu</option><option value="Lahore">Lahore</option><option value="Larkana">Larkana</option>
                                                        <option value="Layyah">Layyah</option><option value="Lodhran">Lodhran</option><option value="Mandi Bahauddin">Mandi Bahauddin</option><option value="Mansehra">Mansehra</option>
                                                        <option value="Mardan">Mardan</option><option value="Mianwali">Mianwali</option><option value="Mirpur Khas">Mirpur Khas</option><option value="Multan">Multan</option>
                                                        <option value="Muridke">Muridke</option><option value="Murree">Murree</option><option value="Muzaffargarh">Muzaffargarh</option><option value="Narowal">Narowal</option>
                                                        <option value="Nawabshah">Nawabshah</option><option value="Nowshera">Nowshera</option><option value="Okara">Okara</option><option value="Pakpattan">Pakpattan</option>
                                                        <option value="Peshawar">Peshawar</option><option value="Quetta">Quetta</option><option value="Rahimyar Khan">Rahimyar Khan</option><option value="Rawalpindi">Rawalpindi</option>
                                                        <option value="Sadiqabad">Sadiqabad</option><option value="Sahiwal">Sahiwal</option><option value="Sargodha">Sargodha</option><option value="Shahdadkot">Shahdadkot</option>
                                                        <option value="Sheikhupura">Sheikhupura</option><option value="Sialkot">Sialkot</option><option value="Sukkur">Sukkur</option><option value="Swabi">Swabi</option>
                                                        <option value="Tando Adam">Tando Adam</option><option value="Tando Muhammad Khan">Tando Muhammad Khan</option><option value="Taxila">Taxila</option><option value="Turbat">Turbat</option>
                                                        <option value="Umerkot">Umerkot</option><option value="Vehari">Vehari</option><option value="Wah Cantonment">Wah Cantonment</option><option value="Wazirabad">Wazirabad</option>
                                             </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='input'>
                                            <label>Postal Code</label>
                                            <input type="text"
                                                className="form-control"
                                                name="PostalCode"
                                                value={mainEmployeeModal.PostalCode}
                                                id="PostalCode"
                                                onChange={handleEmployeeChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded>
                            <AccordionSummary 
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <p className="heading">Contact Information</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='row row-gap-15 row-half'>
                                    <div className="col-md-4">
                                        <div className="input">
                                            <label>Mobile Number</label>
                                            <input type="text"
                                                className="form-control"
                                                name="MobileNumber"
                                                value={mainEmployeeModal.MobileNumber}
                                                id="MobileNumber"
                                                onChange={handleEmployeeChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input">
                                            <label>LandLineNumber</label>
                                            <input type="text"
                                                className="form-control"
                                                name="LaneLineNumber"
                                                id="LaneLineNumber"
                                                value={mainEmployeeModal.LaneLineNumber}
                                                onChange={handleEmployeeChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className={EmployeeValidationModal.Email?"input error":"input"}>
                                            <label>Email</label>
                                            <input type="email"
                                                value={mainEmployeeModal.Email}
                                                onChange={handleEmployeeChange}
                                                className="form-control"
                                                name="Email"
                                                id="Email"
                                                disabled
                                            />
                                            {EmployeeValidationModal.Email}
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    </div>
                </div>
                <div className='tabs-action-bottom'>
                    <div className='btns-group text-end px-3 br-top-1 br-clr d-flex align-items-center justify-content-end h-100'>
                       <button className='btn btn-gray'onClick={()=>dispatch(CancelButton(true))}>Cancel</button>
                        <button className="btn btn-orange" onClick={handleSaveEmployee}>Save</button>
                    </div>
                </div>
            </div>
        </div> */}
            <div>
                <div className={`row m-0 pt-1 basic_information basic_info ${type === 'Employee' ? '' : 'my_info_screen'}`}>
                    
                    <div className="col-md-12 p-0">
                        <div className="row m-0">
                            <Accordion expanded>
                                <AccordionSummary
                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <p className="heading">Basic Info</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="row m-0">
                                        <div className="col-md-4 ps-0">
                                            <div className={EmployeeValidationModal.Username ? "form-group input error" : "form-group input"}>
                                                <label>Username<span className='req'>*</span></label>
                                                <input type="text"
                                                    maxLength={50}
                                                    className="form-control"
                                                    name="Username"
                                                    id="Username"
                                                    value={mainEmployeeModal.Username}
                                                    onChange={handleEmployeeChange}
                                                    autoComplete="off"
                                                />
                                                {EmployeeValidationModal.Username}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className={EmployeeValidationModal.FirstName ? "form-group input error" : "form-group input"}>
                                                <label>First Name<span className='req'>*</span></label>
                                                <input type="text"
                                                    maxLength={50}
                                                    className="form-control"
                                                    name="FirstName"
                                                    id="FirstName"
                                                    value={mainEmployeeModal.FirstName}
                                                    onChange={handleEmployeeChange}
                                                    autoComplete="off"
                                                />
                                                {EmployeeValidationModal.FirstName}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className={EmployeeValidationModal.LastName ? "form-group input error" : "form-group input"}>
                                                <label>Last Name<span className='req'>*</span></label>
                                                <input type="text"
                                                    maxLength={50}
                                                    className="form-control"
                                                    name="LastName"
                                                    id="LastName"
                                                    value={mainEmployeeModal.LastName}
                                                    onChange={handleEmployeeChange}
                                                    autoComplete="off"
                                                />
                                                {EmployeeValidationModal.LastName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-md-4 ps-0">
                                            <div className={EmployeeValidationModal.DateOfBirth ? "form-group input error" : "form-group input"}>

                                                <DatePicker
                                                    label={<><label>Date of Birth</label><span className="req">*</span></>}
                                                    maxDate={new Date()}
                                                    value={mainEmployeeModal.DateOfBirth}
                                                    openCalendarMenufunc={(event) => openCalendarMenu(event, 'DateOfBirth')}
                                                    anchorEl={dateofBirth}
                                                    open={openDateofBirth}
                                                    closeCalendarMenufunc={closeCalendarMenu}
                                                    handleCalendarChangefunc={date => handleCalendarChange(date, 'DateOfBirth')}
                                                    placeholder={true}
                                                />
                                                {EmployeeValidationModal.DateOfBirth}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className={EmployeeValidationModal.Gender ? "form-group input error" : "form-group input"}>
                                                <label>Gender<span className="req">*</span></label>
                                                <select type="text"
                                                    className="form-control"
                                                    name="Gender"
                                                    value={mainEmployeeModal.Gender}
                                                    id="Gender"
                                                    onChange={handleEmployeeChange}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="M">Male</option>
                                                    <option value="FM">Female</option>
                                                    <option value="RU">Rather Undefined</option>
                                                </select>
                                                {EmployeeValidationModal.Gender}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group input">
                                                <label>Marital Status</label>
                                                <select type="text"
                                                    className="form-control"
                                                    name="MaritalStatus"
                                                    value={mainEmployeeModal.MaritalStatus}
                                                    id="MaritalStatus"
                                                    onChange={handleEmployeeChange}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Single">Single</option>
                                                    <option value="Married">Married</option>
                                                    <option value="RU">Rather Undefined</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-md-4 ps-0">
                                            <div className={EmployeeValidationModal.CNIC ? "form-group input error" : "form-group input"}>
                                                <div className={EmployeeValidationModal.CNICRequierd ? "form-group input error" : "form-group input"}>
                                                    <label>CNIC<span className='req'>*</span></label>
                                                    <InputMask type="text"
                                                        minLength={20}
                                                        maxLength={20}
                                                        maskChar={0}
                                                        mask='99999-9999999-9'
                                                        className="form-control"
                                                        name="CNIC"
                                                        id="CNIC"
                                                        value={mainEmployeeModal.CNIC}
                                                        onChange={handleEmployeeChange}
                                                        onKeyPress={(event) => handleNumericCheck(event)}
                                                        autoComplete="off"
                                                    />
                                                    {EmployeeValidationModal.CNIC}
                                                    {EmployeeValidationModal.CNICRequierd}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <div className="form-group input">
                                                <label>Nationality</label>
                                                <select type="text"
                                                    className="form-control"
                                                    name="Nationality"
                                                    id="Nationality"
                                                    maxLength={35}
                                                    value={mainEmployeeModal.Nationality}
                                                    onChange={handleEmployeeChange}
                                                >
                                                    <option>Select Nationality</option>
                                                    <option value="Afghanistan">Afghanistan</option><option value="Albania">Albania</option><option value="Algeria">Algeria</option><option value="Antarctica">Antarctica</option><option value="Argentina">Argentina</option><option value="Armenia">Armenia</option>
                                                    <option value="Australia">Australia</option><option value="Austria">Austria</option><option value="Azerbaijan">Azerbaijan</option><option value="Bahamas">Bahamas</option><option value="Bahrain">Bahrain</option><option value="Bangladesh">Bangladesh</option>
                                                    <option value="Belarus">Belarus</option><option value="Belgium">Belgium</option><option value="Bermuda">Bermuda</option><option value="Botswana">Botswana</option><option value="Bhutan">Bhutan</option><option value="Bolivia">Bolivia</option><option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                    <option value="Brazil">Brazil</option><option value="British Virgin Islands">British Virgin Islands</option><option value="Bulgaria">Bulgaria</option><option value="Cambodia">Cambodia</option><option value="Canada">Canada</option>
                                                    <option value="Chile">Chile</option><option value="China">China</option><option value="Colombia">Colombia</option><option value="Croatia">Croatia</option><option value="Cuba">Cuba</option>
                                                    <option value="Cyprus">Cyprus</option><option value="Czech Republic">Czech Republic</option><option value="Denmark">Denmark</option><option value="Egypt">Egypt</option><option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option><option value="Finland">Finland</option><option value="France">France</option>
                                                    <option value="Germany">Germany</option><option value="Ghana">Ghana</option><option value="Greece">Greece</option><option value="Greenland">Greenland</option><option value="Hong Kong">Hong Kong</option><option value="Hungary">Hungary</option><option value="Iceland">Iceland</option><option value="India">India</option>
                                                    <option value="Indonesia">Indonesia</option><option value="Iran">Iran</option><option value="Iraq">Iraq</option><option value="Ireland">Ireland</option><option value="Israel">Israel</option><option value="Italy">Italy</option>
                                                    <option value="Japan">Japan</option><option value="Jordan">Jordan</option><option value="Kazakhstan">Kazakhstan</option><option value="Kenya">Kenya</option><option value="Kyrgyzstan">Kyrgyzstan</option><option value="Kosovo">Kosovo</option>
                                                    <option value="Kuwait">Kuwait</option><option value="Latvia">Latvia</option><option value="Lebanon">Lebanon</option><option value="Liberia">Liberia</option>
                                                    <option value="Luxembourg">Luxembourg</option><option value="Malaysia">Malaysia</option><option value="Maldives">Maldives</option><option value="Malta">Malta</option><option value="Mexico">Mexico</option>
                                                    <option value="Morocco">Morocco</option><option value="North Korea">North Korea</option><option value="Nepal">Nepal</option><option value="Netherlands">Netherlands</option><option value="New Zealand">New Zealand</option>
                                                    <option value="Nigeria">Nigeria</option><option value="Norway">Norway</option><option value="Oman">Oman</option><option value="Pakistan">Pakistan</option><option value="Palestinian">Palestinian</option>
                                                    <option value="Panama">Panama</option><option value="Peru">Peru</option><option value="Philippines">Philippines</option><option value="Portugal">Portugal</option><option value="Qatar">Qatar</option>
                                                    <option value="Romania">Romania</option><option value="Russian Federation">Russian Federation</option><option value="Saudi Arabia">Saudi Arabia</option><option value="Singapore">Singapore</option>
                                                    <option value="South Korea">South Korea</option><option value="South Africa">South Africa</option><option value="Spain">Spain</option><option value="Sri Lanka">Sri Lanka</option><option value="Sudan">Sudan</option><option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option><option value="Tajikistan">Tajikistan</option><option value="Thailand">Thailand</option><option value="Turkey">Turkey</option>
                                                    <option value="Ukraine">Ukraine</option><option value="United Arab Emirates">United Arab Emirates</option><option value="United Kingdom">United Kingdom</option><option value="United States">United States</option>
                                                    <option value="Uzbekistan">Uzbekistan</option><option value="Vatican City">Vatican City</option><option value="Venezuela">Venezuela</option><option value="Western Sahara">Western Sahara</option><option value="Yemen">Yemen</option><option value="Zambia">Zambia</option><option value="Zimbabwe">Zimbabwe</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="col-md-4">
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded>
                                <AccordionSummary
                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <p className="heading">Address Information</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="row m-0">
                                        <div className="col-md-12 ps-0">
                                            <div className="col-md-12">
                                                <div className="form-group input">
                                                    <label>Address</label>
                                                    <input type="text"
                                                        maxLength={200}
                                                        className="form-control"
                                                        name="Address"
                                                        id="Address"
                                                        value={mainEmployeeModal.Address}
                                                        onChange={handleEmployeeChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0">
                                        <CountriesStates mainEmployeeModal={mainEmployeeModal} handleEmployeeChange={handleEmployeeChange} columnLength={'col-md-4'} />
                                    </div>
                                    <div className="row m-0">
                                        {/* <div className="col-md-4 ps-0">
                                            <div className='form-group input'>
                                                <label>City</label>
                                                <select type="text"
                                                    className="form-control"
                                                    name="City"
                                                    id="City"
                                                    value={mainEmployeeModal.City}
                                                    onChange={handleEmployeeChange}
                                                >
                                                    <option value="">Select City</option>
                                                    <option value="Abbottabad">Abbottabad</option><option value="Ahmadpur">Ahmadpur</option><option value="Arifwala">Arifwala</option><option value="Attock">Attock</option>
                                                    <option value="Badin">Badin</option><option value="Bahawalnagar">Bahawalnagar</option><option value="Bahawalpur">Bahawalpur</option><option value="Bhakkar">Bhakkar</option>
                                                    <option value="Bolhari">Bolhari</option><option value="Burewala">Burewala</option><option value="Chakwal">Chakwal</option><option value="Chaman">Chaman</option>
                                                    <option value="Charsadda">Charsadda</option><option value="Chiniot">Chiniot</option><option value="Chishtian">Chishtian</option><option value="Dadu">Dadu</option>
                                                    <option value="Daska">Daska</option><option value="Dera Ghazi Khan">Dera Ghazi Khan</option><option value="Dera Ismail Khan">Dera Ismail Khan</option><option value="Faisalabad">Faisalabad</option>
                                                    <option value="Firozwala">Firozwala</option><option value="Ghotki">Ghotki</option><option value="Gojra">Gojra</option><option value="Gujranwala">Gujranwala</option>
                                                    <option value="Gujrat">Gujrat</option><option value="Hafizabad">Hafizabad</option><option value="Haroonabad">Haroonabad</option><option value="Hyderabad">Hyderabad</option>
                                                    <option value="Islamabad">Islamabad</option><option value="Jacobabad">Jacobabad</option><option value="Jhang">Jhang</option><option value="Kabal">Kabal</option>
                                                    <option value="Kamoki">Kamoki</option><option value="Karachi">Karachi</option><option value="Kasur">Kasur</option><option value="Khairpur">Khairpur</option>
                                                    <option value="Khanewal">Khanewal</option><option value="Khanpur">Khanpur</option><option value="Khushab">Khushab</option><option value="Khuzdar">Khuzdar</option>
                                                    <option value="Kohat">Kohat</option><option value="Kot Addu">Kot Addu</option><option value="Lahore">Lahore</option><option value="Larkana">Larkana</option>
                                                    <option value="Layyah">Layyah</option><option value="Lodhran">Lodhran</option><option value="Mandi Bahauddin">Mandi Bahauddin</option><option value="Mansehra">Mansehra</option>
                                                    <option value="Mardan">Mardan</option><option value="Mianwali">Mianwali</option><option value="Mirpur Khas">Mirpur Khas</option><option value="Multan">Multan</option>
                                                    <option value="Muridke">Muridke</option><option value="Murree">Murree</option><option value="Muzaffargarh">Muzaffargarh</option><option value="Narowal">Narowal</option>
                                                    <option value="Nawabshah">Nawabshah</option><option value="Nowshera">Nowshera</option><option value="Okara">Okara</option><option value="Pakpattan">Pakpattan</option>
                                                    <option value="Peshawar">Peshawar</option><option value="Quetta">Quetta</option><option value="Rahimyar Khan">Rahimyar Khan</option><option value="Rawalpindi">Rawalpindi</option>
                                                    <option value="Sadiqabad">Sadiqabad</option><option value="Sahiwal">Sahiwal</option><option value="Sargodha">Sargodha</option><option value="Shahdadkot">Shahdadkot</option>
                                                    <option value="Sheikhupura">Sheikhupura</option><option value="Sialkot">Sialkot</option><option value="Sukkur">Sukkur</option><option value="Swabi">Swabi</option>
                                                    <option value="Tando Adam">Tando Adam</option><option value="Tando Muhammad Khan">Tando Muhammad Khan</option><option value="Taxila">Taxila</option><option value="Turbat">Turbat</option>
                                                    <option value="Umerkot">Umerkot</option><option value="Vehari">Vehari</option><option value="Wah Cantonment">Wah Cantonment</option><option value="Wazirabad">Wazirabad</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="col-md-4 ps-0">
                                            <div className='input'>
                                                <label>Postal Code</label>
                                                <input type="text"
                                                    maxLength={9}
                                                    className="form-control"
                                                    name="PostalCode"
                                                    value={mainEmployeeModal.PostalCode}
                                                    id="PostalCode"
                                                    onChange={handleEmployeeChange}
                                                    onKeyPress={(event) => handleNumericCheck(event)}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        {/* 
                                        <div className="col-md-4">
                                            <div className='form-group input'>
                                                <label>Province</label>
                                                <select type="text"
                                                    className="form-control"
                                                    name="Province"
                                                    id="Province"
                                                    value={mainEmployeeModal.Province}
                                                    onChange={handleEmployeeChange}
                                                >
                                                    <option value="">Select Province</option>
                                                    <option value="Federal Capital Territory">Federal Capital Territory</option>
                                                    <option value="Azad Jammu and Kashmir">Azad Jammu and Kashmir</option>
                                                    <option value="Balochistan">Balochistan</option>
                                                    <option value="Khyber Pakhunkhwa">Khyber Pakhunkhwa</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Sindh">Sindh</option>
                                                </select>
                                            </div>
                                        </div>
                                         */}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded>
                                <AccordionSummary
                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <p className="heading">Contact Information</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="row m-0">
                                        <div className="col-md-4 ps-0">
                                            <div className="input">
                                                <label>Mobile Number</label>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    maxLength={15}
                                                    name="MobileNumber"
                                                    id="MobileNumber"
                                                    value={mainEmployeeModal.MobileNumber}
                                                    onChange={handleEmployeeChange}
                                                    onKeyPress={(event) => handleNumericCheck(event)}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <div className="input">
                                                <label>Skype</label>
                                                <input type="text" minlength="11" class="form-control" name="CNIC" id="CNIC" />
                                            </div>
                                        </div> */}
                                        <div className="col-md-4">
                                            <div className={EmployeeValidationModal.Email || EmployeeValidationModal.EmailRequired ? "input error" : "input"}>

                                                <label className="d-flex align-items-center">Email<span className='req pe-2'>*</span>{mainEmployeeModal.EmailConfirmed ? <label className="d-flex align-items-center"><i className="verified bi bi-check cursor-default" style={{ fontSize: "20px", color: "green" }}></i><span>Verified</span></label> : <span onClick={handleResendEmail} className="btn btn-orange p-0">Resend</span>}</label>

                                                <input
                                                    type="email"
                                                    // maxLength={50}
                                                    value={mainEmployeeModal.Email}
                                                    onChange={handleEmployeeChange}
                                                    className="form-control"
                                                    name="Email"
                                                    id="Email"
                                                    maxLength={256}
                                                    autoComplete="off"
                                                />
                                                {EmployeeValidationModal.Email}
                                                {EmployeeValidationModal.EmailRequired}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <div className={EmployeeValidationModal.Email ? "input error" : "input"}>
                                                <label>Email</label>
                                                <input type="email"
                                                    value={mainEmployeeModal.Email}
                                                    onChange={handleEmployeeChange}
                                                    className="form-control"
                                                    name="Email"
                                                    id="Email"
                                                    maxLength={256}
                                                />
                                                {EmployeeValidationModal.Email}
                                            </div>
                                        </div> */}
                                    </div>

                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded>
                                <AccordionSummary
                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <p className="heading">Joining Detail</p>
                                </AccordionSummary>
                                <AccordionDetails className={`${!type && Role !== 1 ? 'ar_disable-popup' : ''}`}>
                                    <div class="row m-0">
                                        <div class="col-md-4 ps-0">
                                            <div className={JoiningValidationModal.JobTitle ? "input error " : "input"}>
                                                <label>Job Title<span className="req">*</span></label>
                                                <input type="text"
                                                    maxLength={100}
                                                    className="form-control form-group"
                                                    name="JobTitle"
                                                    id="JobTitle"
                                                    value={mainEmployeeModal.JobTitle}
                                                    onChange={handleEmployeeChange}
                                                    autoComplete="off"
                                                />
                                                {JoiningValidationModal.JobTitle}
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div className={EmployeeValidationModal.DepartmentID ? "input error" : "input"}>
                                                <label>Department Name</label>
                                                <select className="form-control form-group"
                                                    name="DepartmentID"
                                                    id="DepartmentID"
                                                    aria-label="Default select example"
                                                    value={mainEmployeeModal.DepartmentID}
                                                    onChange={handleEmployeeChange}
                                                >
                                                    <option value="">Select</option>
                                                    {/* <option value="EMS">EMS</option>
                                                    <option value="PMS">PMS</option>
                                                    <option value="Visiting Aid">Visiting Aid</option> */}
                                                    {/* <option value=""></option> */}
                                                    {addDepartmentDropDown.map((e, index) => (
                                                        <option value={e.DepartmentID} key={index}>
                                                            {e.DepartmentName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {EmployeeValidationModal.DepartmentID}
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div className={JoiningValidationModal.TeamID ? "input error" : "input"}>
                                                <label>Team<span className="req">*</span></label>
                                                <select className="form-control form-group"
                                                    name="TeamID"
                                                    id="TeamID"
                                                    aria-label="Default select example"
                                                    value={mainEmployeeModal.TeamID}
                                                    onChange={handleEmployeeChange}
                                                >
                                                    <option value="">Select</option>
                                                    {/* <option value="FrontEnd">FrontEnd</option>
                                                    <option value="BackEnd">BackEnd</option>
                                                    <option value="SQA">SQA</option> */}
                                                    {addTeamDropDown.map((e, index) => (
                                                        <option value={e.TeamID} key={index}>
                                                            {e.TeamName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {JoiningValidationModal.TeamID}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-4 ps-0">
                                            <div className={`input form-group ${dateValidation.DateOfInterview}`}>
                                                <DatePicker
                                                    label='Interview Date'
                                                    maxDate={new Date()}
                                                    value={mainEmployeeModal.DateOfInterview}
                                                    openCalendarMenufunc={(event) => openCalendarMenuJoining(event, 'DateOfInterview')}
                                                    anchorEl={dateOfInterview}
                                                    open={openDateOfInterview}
                                                    closeCalendarMenufunc={closeCalendarMenuJoining}
                                                    handleCalendarChangefunc={date => handleCalendarChangeJoining(date, 'DateOfInterview')}
                                                    placeholder={true}
                                                />
                                                {dateValidation.DateOfInterview === 'error' ? <label className="text-danger">Add Vaild Date</label> : ''}
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div className={`input form-group ${dateValidation.DateOfFinalization} ${JoiningValidationModal?.DateOfFinalization && "error"}`}>
                                                <DatePicker
                                                    label={<label>Hiring Date<span className='req'>*</span></label>}
                                                    maxDate={new Date()}
                                                    value={mainEmployeeModal.DateOfFinalization}
                                                    openCalendarMenufunc={(event) => openCalendarMenuJoining(event, 'DateOfFinalization')}
                                                    anchorEl={dateOfFinalization}
                                                    open={openDateOfFinalization}
                                                    closeCalendarMenufunc={closeCalendarMenuJoining}
                                                    handleCalendarChangefunc={date => handleCalendarChangeJoining(date, 'DateOfFinalization')}
                                                    placeholder={true}
                                                />
                                                {JoiningValidationModal?.DateOfFinalization}
                                                {dateValidation.DateOfFinalization === 'error' ? <label className="text-danger">Add Vaild Date</label> : ''}
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div className={`form-group input ${JoiningValidationModal?.DateOfJoining && "error"} ${dateValidation.DateOfJoining}`}>
                                                <DatePicker
                                                    label={<label>Joining Date<span className='req'>*</span></label>}
                                                    maxDate={new Date()}
                                                    value={mainEmployeeModal.DateOfJoining}
                                                    openCalendarMenufunc={(event) => openCalendarMenuJoining(event, 'DateOfJoining')}
                                                    anchorEl={dateOfJoining}
                                                    open={openDateOfJoining}
                                                    closeCalendarMenufunc={closeCalendarMenuJoining}
                                                    handleCalendarChangefunc={date => handleCalendarChangeJoining(date, 'DateOfJoining')}
                                                    placeholder={true}

                                                />
                                                {JoiningValidationModal?.DateOfJoining}
                                                {dateValidation.DateOfJoining === 'error' ? <label className="text-danger">Joining Date must be greater than or equal to Hiring Date</label> : ''}

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-4 ps-0">
                                            <div className={JoiningValidationModal.ShiftID ? "input error" : "input"}>
                                                <label>Shift</label>
                                                <select className="form-control"
                                                    name="ShiftID"
                                                    id="ShiftID"
                                                    aria-label="Default select example"
                                                    value={mainEmployeeModal.ShiftID}
                                                    onChange={handleEmployeeChange}
                                                >
                                                    <option value="">Select</option>
                                                    {/* <option value="Morning">Morning</option>
                                                    <option value="Evening">Evening</option> */}
                                                    {/* <option value=""></option> */}
                                                    {addShiftDropDown.map((e, index) => (
                                                        <option value={e.ShiftID} key={index}>
                                                            {e.ShiftName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {JoiningValidationModal.ShiftID}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className={JoiningValidationModal.DesignationID ? "input error" : "input"}>
                                                <label>Designation<span className='req'>*</span></label>
                                                <select className="form-control"
                                                    name="DesignationID"
                                                    id="DesignationID"
                                                    aria-label="Default select example"
                                                    value={mainEmployeeModal.DesignationID}
                                                    onChange={handleEmployeeChange}
                                                >
                                                    <option value="">Select</option>

                                                    {addDesignstionDropDown.map((e, index) => (
                                                        <option value={e.DesignationID} key={index}>
                                                            {e.DesignationName}
                                                        </option>
                                                    ))}
                                                    {/* <option value="Intern">Intern</option>
                                                    <option value="Software Developer">Software Developer</option> */}
                                                </select>
                                                {JoiningValidationModal.DesignationID}
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group input">
                                                <div className="input">
                                                    <label>Reporting To</label>
                                                    <select className="form-control"
                                                        name="ReportingTo"
                                                        id="ReportingTo"
                                                        aria-label="Default select example"
                                                        value={mainEmployeeModal.ReportingTo}
                                                        onChange={handleEmployeeChange}
                                                    >
                                                        <option value="">Select</option>
                                                        {/* <option value="Haris">Haris</option>
                                                        <option value="Taimoor">Taimoor</option>
                                                        <option value="Dawood">Dawood</option> */}
                                                        {addreportingTotDropDown.map((e, index) => (
                                                            <option value={e.EmployeeID} key={index}>
                                                                {e.EmployeeName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4  ps-0">
                                            <div className={JoiningValidationModal.Role ? "input error" : "input"}>
                                                <label>Role<span className='req'>*</span></label>
                                                <select className="form-control"
                                                    name="AuthRoleID"
                                                    id="AuthRoleID"
                                                    aria-label="Default select example"
                                                    value={mainEmployeeModal.AuthRoleID}
                                                    onChange={handleEmployeeChange}

                                                >
                                                    {/* <option value="">Select</option> */}
                                                    {ddRoles?.map((item) => {
                                                        return (
                                                            <option value={item?.Id}>{item?.Name}</option>
                                                        )
                                                    })}

                                                    {/* <option value="User">User</option>
                                                    <option value="HR">HR</option>
                                                    <option value="Admin">Admin</option>
                                                    <option value="SuperAdmin">Super Admin</option> */}
                                                </select>
                                                {JoiningValidationModal.Role}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className={JoiningValidationModal?.GrossSalary ? "form-group input error" : "form-group input"}>
                                                <label>Gross Salary <span class="req">*</span></label>
                                                <input type="text" class="form-control" autoComplete="off" name="GrossSalary" id="GrossSalary" onChange={handleEmployeeChange} value={mainEmployeeModal.GrossSalary} onKeyPress={(event) => handleNumericCheck(event)} />
                                                {JoiningValidationModal?.GrossSalary}
                                            </div>
                                        </div>
                                        <div className="col-md-4 ">
                                            <div className={JoiningValidationModal.JobStatus ? "input error" : "input"}>
                                                <label>JobStatus<span className='req'>*</span></label>
                                                <select className="form-control"
                                                    name="JobStatus"
                                                    id="JobStatus"
                                                    aria-label="Default select example"
                                                    value={mainEmployeeModal.JobStatus}
                                                    onChange={handleEmployeeChange}
                                                >
                                                    <option value="">Select</option>
                                                    {/* {ddRoles?.map((item) => {
                                                        return (
                                                            <option value={item?.Id}>{item?.Name}</option>
                                                        )
                                                    })} */}

                                                    <option value="Intern">Intern</option>
                                                    <option value="PartTime">Part Time</option>
                                                    <option value="Contract">Contract</option>
                                                    <option value="Permanent">Permanent</option>
                                                    <option value="Consultant">Consultant</option>
                                                    {/* <option value="SuperAdmin">Super Admin</option> */}
                                                </select>
                                                {JoiningValidationModal.JobStatus}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <div className="current_status p-0">
                                <Accordion expanded>
                                    <AccordionSummary
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        className="pt-0"
                                    >
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div className="d-flex gap-1">
                                                <p className="heading white_heading ">Current Status</p>
                                                <p className="heading gry_heading clr-red">{resignID ? mainEmployeeModal?.ResignationStatus === "Pending" ? `${mainEmployeeModal?.ResignType === "Resign" ? '~  Resign' : "~  Terminate"} ` + mainEmployeeModal?.ResignationStatus : '~  Resign ' + mainEmployeeModal?.ResignationStatus + ' by ' + mainEmployeeModal?.ResignApprovedBy : ''}</p>
                                            </div>
                                            {type
                                                ?
                                                resignID
                                                    ?
                                                    <button className="btn btn-light no-width px-2 py-0 rounded" onClick={() => handleResignationPopup(resignID, "Exist")}>View Request</button>
                                                    :
                                                    <button className="btn btn-light no-width px-2 py-0 rounded" onClick={() => handleResignationPopup(0, "New")}>Terminate</button>
                                                :
                                                resignID
                                                    ?
                                                    <button className="btn btn-light no-width px-2 py-0 rounded" onClick={() => handleResignationPopup(resignID, "New")}>Edit</button>
                                                    :
                                                    <button className="btn btn-light no-width px-2 py-0 rounded" onClick={() => handleResignationPopup(0, "New")}>Resign</button>

                                            }

                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div class="row m-0 ar_disable-popup">
                                            <div class="col-md-4 ps-0">
                                                <div className="input">
                                                    <label>Resign Type</label>
                                                    <input className="form-control"
                                                        name="ResignType"
                                                        id="ResignType"
                                                        aria-label="Default select example"
                                                        value={mainEmployeeModal.ResignType}
                                                        onChange={handleEmployeeChange}
                                                    >
                                                    {/* <select className="form-control"
                                                        name="ResignType"
                                                        id="ResignType"
                                                        aria-label="Default select example"
                                                        value={mainEmployeeModal.ResignType}
                                                        onChange={handleEmployeeChange}
                                                    > */}
                                                        {/* <option value="">Select</option>
                                                        <option value="Resigned">Resigned</option>
                                                        <option value="Terminate">Terminate</option> */}
                                                    </input>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div className="form-group input">
                                                    <DatePicker
                                                        label='Last Date of Employement'
                                                        maxDate={new Date()}
                                                        value={mainEmployeeModal.LastDate}
                                                        openCalendarMenufunc={(event) => openCalendarMenuJoining(event, 'DateOfTermination')}
                                                        anchorEl={dateOfTermination}
                                                        open={openDateOfTermination}
                                                        closeCalendarMenufunc={closeCalendarMenuJoining}
                                                        handleCalendarChangefunc={date => handleCalendarChangeJoining(date, 'DateOfTermination')}
                                                        placeholder={true}

                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group input">
                                                    <label>Notice Period</label>
                                                    <input type="text" className="form-control"
                                                        maxLength={50}
                                                        name="NoticePeriod"
                                                        id="NoticePeriod"
                                                        value={mainEmployeeModal?.NoticePeriod}
                                                        onChange={handleEmployeeChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-4 ps-0">
                                                <div class="form-group input ps-0">
                                                    <label>Reason</label>
                                                    <input type="text" className="form-control"
                                                        maxLength={50}
                                                        name="Reason"
                                                        id="Reason"
                                                        value={mainEmployeeModal.Reason}
                                                        onChange={handleEmployeeChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="btns-group text-end px-3 br-top-1 br-bottom-1 br-clr d-flex align-items-center justify-content-end py-1 ar-tabs-action-bottom">
                    {/* <button class="btn btn-gray">Cancel</button> */}
                    <button class="btn btn-orange" onClick={() => handleSaveEmployee()}>Save</button>
                </div>
            </div>
        </React.Fragment>
    )
}
export default BasicInformation