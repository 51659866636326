import React, { useEffect, useState } from 'react'
import { enumUtil } from '../../../utils/Enum';
import { ServerRequestProtected } from '../../../utils/ServerDataRequest';
import NotificationHandler from '../../../utils/NotificationHandler';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import ScreenShot from './ScreenShot';
import TodayProgress from './TodayProgress';
import OverAllSessionDetails from './OverAllSessionDetails';
import isNull from '../../../utils/NullChecking';
import { format } from 'date-fns';
import { DateFormat, DateFormat_dMy } from "../../../utils/Constants"

const AllSessionsScreenShots = (props) => {
    const { type, currentDate } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let Token = token && jwt_decode(token)
    let currentDay = currentDate.Date;
    let UserID = "";
    if (!isNull(type) && type === "Employee") {
        UserID = activeEmployee + "";
    }
    // else {
    //     UserID = Token.UserID + "";
    // }

    const API_URL = process.env.REACT_APP_API;
    const [isLoading, setIsLoading] = useState(false);
    const [ScreenShotData, setScreenShotData] = useState({});

    const LoadScreenShotData = () => {
        setScreenShotData({});
        setIsLoading(true);
        let saveModal = {
            "TableName": enumUtil.enumAPITableName.ActivitySession,
            "SearchCriteria": {
                "Datefrom": currentDay,
                "Dateto": currentDay,
                "Search": "no",
                "Session": "All",
                "EmployeeID": UserID + "",
            }
        }
        ServerRequestProtected(API_URL + "/LoadSessionDetails", "post", saveModal).then((response) => {
            setIsLoading(false);
            setScreenShotData(response);
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })

    }

    useEffect(() => {
        LoadScreenShotData()
    }, [currentDay])

    return (
        <>
            <div className='today_task'>
                {/* <div className='task_timing ms-3 me-2 mt-3'>
                    <div>
                        <p className='heading'>8 h 40 m</p>
                        <p className='text clr_gry sm'>Today’s Total</p>
                    </div>
                    <div>
                        <div class="d-flex gap-1 align-items-center ranges">
                            <div class="range w-100">
                                <div class="fill w-75"></div>
                            </div>
                            <p class="heading precentage">20%</p>
                        </div>
                        <p className='gry_heading'>Activity</p>
                    </div>
                    <div>
                        <p className='heading'>5 h 40 m</p>
                        <p className='text clr_gry sm'>Productive Time</p>
                    </div>
                    <div>
                        <p className='heading'>8 h 40 m</p>
                        <p className='text clr_gry sm'>  Non-Productive Total</p>
                    </div>
                </div> */}{console.log("ScreenShotData",Object.keys(ScreenShotData).length )}
                {Object.keys(ScreenShotData).length != 0  ?
                    <>
                        <OverAllSessionDetails SearchType={"All"} Date={currentDay} type={type} />
                        <div className='mt-3 ms-3 me-3'>
                            {ScreenShotData?.Session?.length != 0 && ScreenShotData?.Session?.map((item) => {
                                return (
                                    <>
                                        <p className='gry_heading font-m'><span className='active_time'></span>{item?.StartTime + " - " + item?.EndTime} <span className='text sm clr_gry ms-1'>{item?.SessionTime + " logged time "}</span></p>
                                        <div className='ms-3 overflow-x-auto mt-3 d-flex  pb-1 gap-3 mb-3'>
                                            {item?.Screenshot?.length != 0 && item?.Screenshot?.map((items) => {
                                                return (
                                                    <ScreenShot items={items} LoadData={LoadScreenShotData} />
                                                )
                                            })}
                                        </div>
                                    </>
                                )
                            })

                            }
                        </div>
                    </>
                    
                    :

                    isLoading ? null : Object.keys(ScreenShotData).length === 0 ? (
                        <div className="no_data">
                            <span class="icon-ar-nodata">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                                <span class="path4"></span>
                                <span class="path5"></span>
                                <span class="path6"></span>
                                <span class="path7"></span>
                                <span class="path8"></span>
                                <span class="path9"></span>
                                <span class="path10"></span>
                                <span class="path11"></span>
                                <span class="path12"></span>
                                <span class="path13"></span>
                                <span class="path14"></span>
                                <span class="path15"></span>
                                <span class="path16"></span>
                                <span class="path17"></span>
                                <span class="path18"></span>
                                <span class="path19"></span>
                                <span class="path20"></span>
                                <span class="path21"></span>
                                <span class="path22"></span>
                                <span class="path23"></span>
                                <span class="path24"></span>
                                <span class="path25"></span>
                                <span class="path26"></span>
                                <span class="path27"></span>
                                <span class="path28"></span>
                                <span class="path29"></span>
                                <span class="path30"></span>
                                <span class="path31"></span>
                                <span class="path32"></span>
                                <span class="path33"></span>
                                <span class="path34"></span>
                                <span class="path35"></span>
                                <span class="path36"></span>
                                <span class="path37"></span>
                                <span class="path38"></span>
                                <span class="path39"></span>
                                <span class="path40"></span>
                                <span class="path41"></span>
                                <span class="path42"></span>
                                <span class="path43"></span>
                                <span class="path44"></span>
                                <span class="path45"></span>
                                <span class="path46"></span>
                                <span class="path47"></span>
                                <span class="path48"></span>
                                <span class="path49"></span>
                                <span class="path50"></span>
                                <span class="path51"></span>
                                <span class="path52"></span>
                                <span class="path53"></span>
                                <span class="path54"></span>
                                <span class="path55"></span>
                                <span class="path56"></span>
                                <span class="path57"></span>
                                <span class="path58"></span>
                                <span class="path59"></span>
                                <span class="path60"></span>
                                <span class="path61"></span>
                                <span class="path62"></span>
                                <span class="path63"></span>
                                <span class="path64"></span>
                                <span class="path65"></span>
                                <span class="path66"></span>
                                <span class="path67"></span>
                                <span class="path68"></span>
                                <span class="path69"></span>
                                <span class="path70"></span>
                                <span class="path71"></span>
                                <span class="path72"></span>
                                <span class="path73"></span>
                                <span class="path74"></span>
                                <span class="path75"></span>
                                <span class="path76"></span>
                                <span class="path77"></span>
                                <span class="path78"></span>
                                <span class="path79"></span>
                                <span class="path80"></span>
                                <span class="path81"></span>
                                <span class="path82"></span>
                                <span class="path83"></span>
                                <span class="path84"></span>
                                <span class="path85"></span>
                                <span class="path86"></span>
                                <span class="path87"></span>
                                <span class="path88"></span>
                                <span class="path89"></span>
                                <span class="path90"></span>
                                <span class="path91"></span>
                                <span class="path92"></span>
                                <span class="path93"></span>
                                <span class="path94"></span>
                                <span class="path95"></span>
                                <span class="path96"></span>
                                <span class="path97"></span>
                                <span class="path98"></span>
                                <span class="path99"></span>
                                <span class="path100"></span>
                                <span class="path101"></span>
                                <span class="path102"></span>
                                <span class="path103"></span>
                                <span class="path104"></span>
                                <span class="path105"></span>
                                <span class="path106"></span>
                                <span class="path107"></span>
                                <span class="path108"></span>
                                <span class="path109"></span>
                                <span class="path110"></span>
                                <span class="path111"></span>
                                <span class="path112"></span>
                                <span class="path113"></span>
                                <span class="path114"></span>
                                <span class="path115"></span>
                                <span class="path116"></span>
                                <span class="path117"></span>
                                <span class="path118"></span>
                                <span class="path119"></span>
                                <span class="path120"></span>
                                <span class="path121"></span>
                                <span class="path122"></span>
                                <span class="path123"></span>
                                <span class="path124"></span>
                                <span class="path125"></span>
                                <span class="path126"></span>
                                <span class="path127"></span>
                                <span class="path128"></span>
                            </span>
                            <p className="text fst-italic text-center font-m">No Data Found!</p>
                        </div>
                    ) : null

                }

            </div>
        </>
    )
}

export default AllSessionsScreenShots
