import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { OrganizationID } from "../../actions/Organization";
import { Token } from "../../actions/Token";
import { Role } from "../../actions/Role";
import { useDispatch, useSelector } from "react-redux";
import FormValidator from "../../utils/FormValidator"
import NotificationHandler from "../../utils/NotificationHandler"
import { enumUtil } from "../../utils/Enum"
import { LoginServerRequestProtected } from "../../utils/ServerDataRequest"
import Loader from "../shared/Loader/Loader"
import jwt_decode from 'jwt-decode';
import logo from "../../assetsOld/img/logo.svg"
import wave from "../../assetsOld/img/screen wave.png"
import bg from "../../assetsOld/img/screen illustration.png"
import screenwave from "../../assetsOld/img/22.svg"
import Cookies from "js-cookie";
import { height } from "@mui/system";


const Login = () => {
    const AUTHENTICATE_API_URL = process.env.REACT_APP_AUHTENTICATE
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let Login_Modal = {
        Email: "",
        Password: "",
        isChecked: false,
    }
    let valModal = {}
    let ValidationModal = {
        EmailVal: "",
        PasswordVal: "",
    }
    const [isLoading, setIsLoading] = useState(false)
    const [mainLoginModal, setmainLoginModal] = useState(Login_Modal);
    const [loginSaveUpdateModal, setLoginSaveUpdateModal] = useState({});
    const [loginValidationModal, setLoginValidationModal] = useState(ValidationModal)
    const [checked, setChecked] = useState(false)
    const [hide, setHide] = useState(true);

    const getExpirationDate = () => {
        const currentDate = new Date();
        const expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1)
        return expirationDate;
    }

    useEffect(() => {
        if (localStorage.isChecked === "true") {
            setmainLoginModal({ Email: localStorage.Email, Password: localStorage.Password });
            setChecked(true);
            setLoginSaveUpdateModal({ Email: localStorage.Email, Password: localStorage.Password })
        }
    }, [])
    const handleLoginChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainLoginModal };
        let saveModal = { ...loginSaveUpdateModal }
        modal[name] = value
        setmainLoginModal(modal)
        for (var property in Login_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setLoginSaveUpdateModal(saveModal)
    }
    const handleCheckChange = (e) => {
        setChecked(!checked)
        mainLoginModal['isChecked'] = !checked;
        localStorage.isChecked = !checked;
    }
    const LoginValidation = async () => {
        let ValidationModal = {
            EmailVal: FormValidator(
                mainLoginModal.Email,
                enumUtil.enumValidationType.Required,
                'Email',
            ),
            PasswordVal: FormValidator(
                mainLoginModal.Password,
                enumUtil.enumValidationType.Required,
                'Password',
            ),
        }
        await setLoginValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    const handleSubmitEmailChange = async (email) => {
        setIsLoading(true);
        LoginServerRequestProtected(AUTHENTICATE_API_URL + "/ForgotPassword", 'post',{
        // LoginServerRequestProtected("http://192.168.18.5:438/api/Authentication/ForgotPassword", 'post', {
            'Email': email
        }).then((response) => {
            setIsLoading(false)
            NotificationHandler('Email Sent Successfully', enumUtil.enumtoaster.success)
        }).catch((error) => {
            setIsLoading(false)
        })
    }
    const handleSubmit = async (event) => {
        await LoginValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setIsLoading(true);
            let data = { ...loginSaveUpdateModal }
            LoginServerRequestProtected(AUTHENTICATE_API_URL + "/Login", 'post',{
            // LoginServerRequestProtected("http://192.168.18.5:438/api/Authentication" + "/Login", 'post', {
                ...data
            }).then((response) => {
                if (response.temporaryPassword) {
                    setIsLoading(false)
                    NotificationHandler('Email has been sent on your Account.Please update Your Password to login', enumUtil.enumtoaster.success)
                }
                else {
                    const user = jwt_decode(response.token)
                    console.log("user", user)
                    user.OrganizationID && dispatch(OrganizationID(user.OrganizationID));
                    if (user.Role) {
                        let role;
                        switch (user.Role) {
                            case 'SuperAdmin':
                            case 'Admin':
                                role = 1;
                                break;
                            case 'User':
                                role = 2;
                                break;
                            default:
                                role = 2;
                                break;
                        }
                        dispatch(Role(role));
                    }
                    // Store the session token in an HTTP-only cookie
                    response.token && Cookies.set('cookedToken', response.token, { expires: getExpirationDate() });
                    response.token && dispatch(Token(response.token));
                    setIsLoading(false)
                    event.preventDefault();
                    localStorage.Email = mainLoginModal.Email;
                    localStorage.Password = mainLoginModal.Password;
                    localStorage.isChecked = checked;
                    navigate("../ems", { replace: true });
                    NotificationHandler('Logged in Successfully', enumUtil.enumtoaster.success)
                }
                // response || handleSubmitEmailChange(mainLoginModal.Email)
                /////////////////////////////////////////
            }).catch((error) => {
                setIsLoading(false)
                NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error)
            })
        }
    }
    return (
        <React.Fragment >
            {isLoading ? <Loader /> : null}
            {/* <div className="login hk-login">
            <div className="d-lg-flex half">
                <div className="bg order-1 order-md-2">
                    <img src={process.env.PUBLIC_URL + "/assets/images/Login_EMS_animation.png"} alt="" />
                </div>
                <div className="contents order-2 order-md-1">
                    <h1 className="hk-title">Employee Management System</h1>
                    <div className="container hk-form-t">
                        <div className="row align-items-start justify-content-center">
                            <div className="col-md-7">
                                <h2 class="heading xl">Login to your Account</h2>
                                <p className="text clr-muted ">See what is going on with your bussiness.</p>
                                <div className="mt-4">
                                        <div className={loginValidationModal.EmailVal?"form-group input error":" form-group input"}>
                                        <label><b>Email / Username</b></label>
                                        <input type="text"
                                            className="form-control"
                                            name="Email"
                                            id="Email"
                                            value={mainLoginModal.Email}
                                            onChange={handleLoginChange}
                                            />
                                        {loginValidationModal.EmailVal}
                                    </div>
                                    <div className={loginValidationModal.PasswordVal?"form-group input mb-1 hk-password-field error":"form-group input mb-1 hk-password-field"}>
                                        <label htmlFor=""><b>Password</b></label>
                                        <input type={ hide ? "password" : "text"}
                                            className="form-control "
                                            name="Password"
                                            id="Password"
                                            value={mainLoginModal.Password}
                                            onChange={handleLoginChange} 
                                            />
                                        <div className="hk-eye-btn" 
                                            onMouseDown={()=>setHide(false)}
                                            onMouseUp={()=>setHide(true)}
                                            onMouseLeave={()=>setHide(true)}
                                            >
                                            {hide ? <i class="icon-eye-blocked"></i> : <i class="icon-eye"></i>}
                                        </div>
                                    </div>
                                    <div className={loginValidationModal.PasswordVal?"form-group input mb-3 hk-password-field error":"form-group input mb-3 hk-password-field"}>
                                        {loginValidationModal.PasswordVal}
                                        </div>
                                    <div className="d-flex mb-4 align-items-center justify-content-between">
                                        <div class="form-check ">
                                            <input class="form-check-input" type="checkbox" value="option1"
                                             name="isChecked"
                                             id="isChecked"
                                             checked={checked}
                                             onChange={(e)=>handleCheckChange(e)}/>
                                            <label class="form-check-label" for="isChecked">Remember Me</label>
                                        </div>
                                        <a className="forgot-pass hk-link" onClick={()=>navigate("../forgotpassword", { replace: true })}>Forgot Password?</a>
                                    </div>

                                    <input type="submit" value="Log In" className="btn rounded btn-blue w-100 log_in_btn" onClick={handleSubmit} />

                                </div>
                               <div className="doted-text">
                                <div></div>
                                <p className="text text-center my-3">Or Sign in with</p>
                                <div></div>
                               </div>
                <div class="row align-items-start justify-content-center">
                        <div class="column col-2">
                            <div className="bg order-1 order-md-1 ">
                                <button type="button" class="btn btn-link" ><span class="bi bi-linkedin fa-4x center-block"></span></button>
                                </div>   
                </div>
                        <div class="column col-2">
                            <div className="bg order-1 order-md-1">
                                <button type="button" class="btn btn-link"><span class="bi bi-facebook fa-4x center-block" ></span></button>
                                </div>
                </div>
                        <div class="column col-2">
                            <div className="bg order-1 order-md-1">
                                <button type="button" class="btn btn-link"><span class="bi bi-envelope-fill fa-4x center-block"></span></button>
                                </div>
                        </div>
                </div><br></br>
                      <p className="text text-center inst-txt mt-4">Not Registered? <a href="">Create an Account</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  */}
            <div className="login">
                <div className="d-flex pt-3 mx-3 justify-content-between align-items-center">
                    <img src={logo}></img>
                    <p className="heading"><span className="em">Employee</span><span className="sy"> Management System</span></p>
                    <p className="heading"><span className="Contact">Contact Us</span><span className="num"> +92 300 0000000</span></p>
                </div>
                <p className="text Platform">A Platform Where We Value EveryOne</p>
                <div className="login_card">
                    <p className="heading lg text-center">Login to your Account</p>
                    <p className="text text-center">See what is going on with your business</p>
                    <div className="px-5 pt-3">
                        <div className={loginValidationModal.EmailVal ? "form-group input error" : " form-group input"}>
                            <label><b>Email / Username</b></label>
                            <input type="text"
                                className="form-control"
                                name="Email"
                                id="Email"
                                value={mainLoginModal.Email}
                                onChange={handleLoginChange}
                            />
                            {loginValidationModal.EmailVal}
                        </div>
                        <div className={loginValidationModal.PasswordVal ? "form-group input mb-1 hk-password-field error" : "form-group input mb-1 hk-password-field"}>
                            <label htmlFor=""><b>Password</b></label>
                            <input type={hide ? "password" : "text"}
                                className="form-control "
                                name="Password"
                                id="Password"
                                value={mainLoginModal.Password}
                                onChange={handleLoginChange}
                            />
                            <div className="hk-eye-btn"
                                onMouseDown={() => setHide(false)}
                                onMouseUp={() => setHide(true)}
                                onMouseLeave={() => setHide(true)}
                            >
                                {hide ? <i class="icon-eye-blocked"></i> : <i class="icon-eye"></i>}
                            </div>
                        </div>
                        <div className={loginValidationModal.PasswordVal ? "form-group input mb-3 hk-password-field error" : "form-group input mb-3 hk-password-field"}>
                            {loginValidationModal.PasswordVal}
                        </div>
                        <div className="d-flex mb-2 align-items-center justify-content-between">
                            <div class="form-check custom_check_box">
                                <input class="form-check-input" type="checkbox" value="option1"
                                    name="isChecked"
                                    id="isChecked"
                                    checked={checked}
                                    onChange={(e) => handleCheckChange(e)} />
                                <label class="form-check-label" for="isChecked">Remember Me</label>
                            </div>
                            <p className="font-m clr_gry cursor-pointer text" onClick={() => navigate("../forgotpassword", { replace: true })}>Forgot Password?</p>
                        </div>
                        <input type="submit" value="Log In" className="btn rounded btn_orange w-100 log_in_btn" onClick={handleSubmit} />
                        {/* <p className="text mt-3 font-m clr_gry text-center"><span className="bottom_border mb-1 d-inline-flex w-25"></span> Sign in with <span className="bottom_border mb-1 d-inline-flex w-25"></span></p>
                        <div className="mt-2 text-center">
                            <i className="icon-Vector-6"></i>
                            <i className="icon-Vector-7 mx-1"></i>
                            <i className="icon-Vector-8"></i>
                        </div> */}
                        {/* <p className="text clr_gry rep text-center font-m">Not Registered Yet?  <span>Create an Account</span></p> */}
                    </div>
                    <div className="wave">
                        <img src={wave} className="w-100 h-100"></img>
                    </div>
                </div>
                <div className="back_image">
                    <img src={bg} className="w-100"></img>
                </div>
                <div className="screen_wave">
                    <img src={wave} className="w-100 h-100"></img>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Login