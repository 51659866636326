import { combineReducers } from 'redux'
import MainTabContentReducer from './MainTabContentReducer'
import MainTabContentTabListReducer from './MainTabContentTabListReducer'
import HandleMenuActiveTab from './HandleMenuActiveTab'
import OPEN_TAB from './HandleTabReducer'
import OPEN_TAB_PAYROLL from './HandlePayrollTabReducer'
import DeletePayrollIDReducer from './DeletePayrollIDReducer'
import NewPayrollDataReducer from './NewPayrollDataReducer'
import PayrollActiveTabReducer from './PayrollActiveTabReducer'
import TotalPayrollReducer from './TotalPayrollReducer'
import Collapse from './SideBarReducer'
import EmployeeRecordReducer from './EmployeeRecordReducer'
import EmployeeActiveTabReducer from './EmployeeActiveTabReducer'
import SearchParamReducer from './SearchParamReducer'
import SetupActiveNameReducer from './SetupActiveNameReducer'
import Organization from './Organization'
import CancelSaveReloadReducer from './CancelSaveReloadReducer'
import TotalEmployeesReducer from './TotalEmployeesReducer'
import CancelButtonReducer from './CancelButtonReducer'
import NewEmployeeDataReducer from './NewEmployeeDataReducer'
import TokenReducer from './TokenReducer'
import RoleReducer from './RoleReducer'
import SubRoleReducer from './SubRoleReducer'
import GoToReducer from './GoToReducer'
import DeleteEmployeeIDReducer from './DeleteEmployeeIDReducer'
import OpenEmployeeAttendanceReducer from './OpenEmployeeAttendanceReducer'
import Open_Attendance_Tab from './Open_Attendance_Tab'
const allreducers = combineReducers({
    MainTabContentReducer: MainTabContentReducer,
    MainTabContentTabListReducer: MainTabContentTabListReducer,
    OPEN_TAB: OPEN_TAB,
    Collapse: Collapse,
    HandleMenuActiveTab: HandleMenuActiveTab,
    EmployeeRecordReducer: EmployeeRecordReducer,
    EmployeeActiveTabReducer: EmployeeActiveTabReducer,
    SearchParamReducer: SearchParamReducer,
    SetupActiveNameReducer: SetupActiveNameReducer,
    Organization: Organization,
    CancelSaveReloadReducer: CancelSaveReloadReducer,
    TotalEmployeesReducer: TotalEmployeesReducer,
    CancelButtonReducer: CancelButtonReducer,
    DeleteEmployeeIDReducer: DeleteEmployeeIDReducer,
    NewEmployeeDataReducer: NewEmployeeDataReducer,
    TokenReducer: TokenReducer,
    RoleReducer: RoleReducer,
    SubRoleReducer: SubRoleReducer,
    GoToReducer: GoToReducer,
    OpenEmployeeAttendanceReducer: OpenEmployeeAttendanceReducer,
    OPEN_TAB_PAYROLL: OPEN_TAB_PAYROLL,
    DeletePayrollIDReducer: DeletePayrollIDReducer,
    NewPayrollDataReducer: NewPayrollDataReducer,
    PayrollActiveTabReducer: PayrollActiveTabReducer,
    TotalPayrollReducer: TotalPayrollReducer,
    OPEN_ATTENDANCE_TAB: Open_Attendance_Tab,
})

export default (state, action) => allreducers(action.type === 'USER_LOGOUT' ? undefined : state, action);
