import React, { useEffect, useMemo, useState } from 'react'
import Menu from "@mui/material/Menu";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import format from "date-fns/format";
import Modal from "react-bootstrap/Modal";
import { DateFormat_YMd } from '../../../utils/Constants'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Loader from '../../shared/Loader/Loader';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { ServerRequestProtected } from '../../../utils/ServerDataRequest';
import { enumUtil } from '../../../utils/Enum';
import NotificationHandler from '../../../utils/NotificationHandler';
import EmployeeInformation from '../../MyInfo/Account/EmployeeInformation/EmployeeInformation';

const CalendarView = ({ Active }) => {
    let OrgID = String(useSelector((state) => state.Organization));
    const token = useSelector((state) => state.TokenReducer)
    const Token = token && jwt_decode(token);
    const UserID = Active ? String(Active) : null;
    console.log("CalendarView", UserID)
    const currentYear = String(new Date().getFullYear());
    const API_URL = process.env.REACT_APP_API;

    let AttAndLv_Modal = {
        EmployeeID: UserID,
        Year: currentYear,
    }
    let OverAll_AttAndLv_Modal = {
        EmployeeID: UserID,
        // OrganizationID: OrgID,
        Months: "",
        Year: currentYear,
    }
    const [attendanceEmployeeData, setAttendanceData] = useState([]);
    const [employeeOverAllAttendance, setEmployeeOverAllAttendance] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [singleshow, setSingleShow] = useState(false);
    const singleModalClose = () => setSingleShow(false);
    const localizer = momentLocalizer(moment);
    const [mappingData, setMappingData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());

    const handleNextWeek = () => {
        const nextWeek = moment(currentDate).add(1, 'month').toDate();
        setCurrentDate(nextWeek);
    }
    const handlePrevWeek = () => {
        const prevWeek = moment(currentDate).subtract(1, 'month').toDate();
        setCurrentDate(prevWeek);
    }

    useEffect(() => {
        let attendanceData = [];
        if (!attendanceEmployeeData || attendanceEmployeeData?.length === 0) {
            attendanceData = [];
        }
        else {
            attendanceData = attendanceEmployeeData.map(item => {
                const startDate = moment(item?.Date, "DD/MM/YYYY").toDate();
                const currentDate = new Date();

                const day = currentDate.getDate(); // Get the day of the month (1-31)
                const month = currentDate.getMonth() + 1; // Get the month (0-11), add 1 to get the actual month (1-12)
                const year = currentDate.getFullYear(); // Get the full year (e.g., 2023)
                let currDate = (day.toString().padStart(2, '0')) + '/' + (month.toString().padStart(2, '0')) + '/' + year;
                let isCurrentDay = item?.Date === currDate;
                console.log("<START>", item?.Date === currDate)
                let title, className, iconName, iconPath;
                switch (item?.Status) {
                    case "A":
                        title = "Absent";
                        className = "EA-status absent d-flex align-items-center";
                        iconName = "cross";
                        iconPath = <span class="icon-absent"><span class="path1"></span><span class="path2"></span></span>;
                        break;
                    case "P":
                        title = "Present";
                        className = "EA-status present d-flex align-items-center";
                        iconName = "icon-prasent fs-16"
                        break;
                    case "WE":
                        title = "Weekend";
                        className = "EA-status gray-bgc d-flex align-items-center";
                        iconName = "W"
                        break;
                    case "L":
                        title = "Leave";
                        className = "EA-status pink-bg";
                        iconName = "L"
                        break;
                    default:
                        title = "Holiday";
                        className = "EA-status holiday_clr d-flex align-items-center";
                        iconName = "icon-national-holiday fs-16"
                }

                return {
                    start: startDate,
                    end: startDate,
                    title: (
                        <div className={className} onClick={"singleModalShow"}>
                            <i className={`${iconName}`}>{iconPath}</i> {title}
                        </div>
                    )
                };
            })
        }
        setMappingData(attendanceData);

    }, [attendanceEmployeeData])

    const LoadData = (searchParam) => {
        setIsLoading(true);
        setAttendanceData([])
        ServerRequestProtected(API_URL + "/FindByMultipleCriteria", 'post', {
            "TableName": enumUtil.enumAPITableName.EmployeeAttendence,
            "SearchCriteria": searchParam,
        })
            .then((response) => {
                let jsonStr = '';
                for (let i in response) {
                    const jsonData = response[i]['JSON_F52E2B61-18A1-11d1-B105-00805F49916B'];
                    for (let j in jsonData) {
                        jsonStr += jsonData[j];
                    }
                }
                const parsedJson = JSON.parse(jsonStr);
                console.log("PARSEDJSON___", parsedJson[0]?.Attendance)
                setAttendanceData(parsedJson[0]?.Attendance)
                // NotificationHandler("Data Fetched Successfuly", enumUtil.enumtoaster.success)
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                // NotificationHandler("Failed to load Data Yearly", enumUtil.enumtoaster.error);
            })
    }

    const LoadOverAllData = (searchOverAllEmployeeData) => {
        setIsLoading(true);
        ServerRequestProtected(API_URL + "/GetCalculatedAttendance", "post", {
            "TableName": enumUtil.enumAPITableName.EmployeeAttendence,
            "SearchCriteria": searchOverAllEmployeeData,
        }).then((response) => {
            setIsLoading(false);
            // console.log("responseData", response);
            setEmployeeOverAllAttendance(response[0]);
            // console.log("employeeOverAll", employeeOverAllAttendance);
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success);
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
        })

    }

    const formats = {
        weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
    }

    useEffect(() => {
        LoadData(AttAndLv_Modal);
    }, [])

    useEffect(() => {
        LoadOverAllData(OverAll_AttAndLv_Modal)
    }, [])

    const handleEventClick = (event) => {
        setSingleShow(true);
    };

    return (
        <>
            {isLoading ? <Loader /> : null}
            <Modal
                show={singleshow}
                onHide={singleModalClose}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="app-modal"
            >
                <Modal.Body>
                    <div className="d-flex justify-content-between mb-3">
                        <p className="heading">Add New Event</p>
                        <button className="btn-icon-transparent">
                            <i className="icon-delete"></i>
                        </button>
                    </div>
                    <div>
                        <div className="input form-group">
                            <label>Title</label>
                            <select className="form-control">
                                <option value="select"></option>
                            </select>
                        </div>
                        <div className="input form-group">
                            <label>Date</label>
                            <input type="date" className="form-control hk-date" />
                        </div>
                        <div className="input form-group">
                            <label>Notes</label>
                            <textarea rows="6" className="form-control"></textarea>
                        </div>
                        <div className="form-group input">
                            <label htmlFor="file" className="form-label">
                                Activity Proof
                            </label>
                            <div className="hk-file-picker bg-light">
                                <input
                                    name="file"
                                    id="file"
                                    type="file"
                                    className="form-control"
                                />
                                <label htmlFor="file">
                                    <i className="icon-link"></i> Attach a File
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="btns-group text-end px-3 py-3 br-top-1 br-clr">
                    <button className="btn btn-gray" onClick={singleModalClose}>
                        Cancel
                    </button>
                    <button className="btn btn-orange">Save</button>
                </div>
            </Modal>
            {/* <div className={`pb-2 basic_info employee_tabs-grid overall_view ${Active ? 'emp_atend_screen' : ''}`}> */}
            <div className={`pb-2 ${Active ? 'emp_atend_screen' : ''}`}>
                <div className='d-flex ps-2 mb-2 justify-content-between'>
                    <div className="d-flex gap-2 justify-content-start flex-wrap">
                        <div className="hk-attendance-status green-bg">Presents | {employeeOverAllAttendance?.PresentEmployees} </div>
                        <div className="hk-attendance-status litePink-bg">Absents | {employeeOverAllAttendance?.AbsentEmployees} </div>
                        <div className="hk-attendance-status gray-bg">Weekends | {employeeOverAllAttendance?.TotalWeekendDays} </div>
                        <div className="hk-attendance-status liteBlue-bg">National Holidays | {employeeOverAllAttendance?.NationalHolidays}</div>
                        <div className="hk-attendance-status pink-bg">Leaves | {employeeOverAllAttendance?.Leaves}</div>
                        <div className="hk-attendance-status liteOrnage-bg">Late count | 0</div>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='heading'>
                        Attendance Month - <span> {currentDate.toLocaleString("default", { month: "long" })} </span>
                    </div>
                    <div className='empolyee_attendance'>
                        <div className='hk-ems-searchBar'>
                            <div className="weekly">
                                <p className="text"><span className='cursor-pointer' onClick={handlePrevWeek}><i className="icon-angle-left  " ></i></span>{currentDate.toLocaleString("default", { month: "long", year: "numeric" })}<span className='cursor-pointer' onClick={handleNextWeek}> <i className="icon-right-direction " ></i></span></p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='d-flex align-items-center gap-10'>
                                <i className='icon-angle-left me-1 fs-11 cursor-pointer' onClick={handlePrevWeek}></i>
                                <i className='icon-angle-right fs-11 cursor-pointer' onClick={handleNextWeek}></i>
                            </div> */}
                </div>
                <div className="hk-EA-calender">
                    <Calendar
                        localizer={localizer}
                        date={currentDate}
                        formats={formats}
                        defaultView="month"
                        toolbar={false}
                        events={mappingData}
                        tooltipAccessor={null}
                        views={["month"]} // Allow only Month and Week views
                        style={{ height: "100vh", marginBottom: "38px" }} // Set the height to the viewport height
                    // selectable={true}
                    // onSelectSlot={handleEventClick}
                    />
                </div>
            </div>
        </>
    )
}

export default CalendarView
