import React, { Component, useEffect, useState } from 'react';
import { Nav, Tab, Tabs } from "react-bootstrap";
// import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import AttendanceSearch from '../EmployeeAttendance/AttendanceSearch';
import CalendarView from './Tab Views/CalendarView';
import TabularView from './Tab Views/TabularView';
import ListView from './Tab Views/ListView';
import LeaveView from './Tab Views/LeaveView';
import TimeAdjustment from './TimeAdjustment/TimeAdjustment';
import { useSelector } from 'react-redux';
import { enumUtil } from '../../utils/Enum';
import { ServerRequestProtected } from '../../utils/ServerDataRequest';
import NotificationHandler from '../../utils/NotificationHandler';
import Loader from '../shared/Loader/Loader';
import jwt_decode from 'jwt-decode';
import OverallView from './Tab Views/OverallView';
import EmployeeInformation from '../MyInfo/Account/EmployeeInformation/EmployeeInformation';


const MyAttendance = ({ Active }) => {
  Active = Active && String(Active);
  const currentMonth = String(new Date().getMonth() + 1);
  const currentYear = new Date().getFullYear() + "";
  let OrgID = String(useSelector((state) => state.Organization));
  const API_URL = process.env.REACT_APP_API;
  // const [time, setTime] = useState()
  const token = useSelector((state) => state.TokenReducer)
  const Token = token && jwt_decode(token);
  // const UserID = Active ? Active : Token.UserID +"";
  const UserID = Active ? Active : null;

  let AttAndLv_Modal = {
    EmployeeID: UserID,
    Year: currentYear,
  }

  let OverAll_AttAndLv_Modal = {
    EmployeeID: UserID,
    // OrganizationID: OrgID,
    Months: "",
    Year: currentYear,
  }

  let MonthlyAttAndLv_Modal = {
    EmployeeID: UserID,
    Month: currentMonth,
    Year: currentYear,
  }
  let SelectMonth_Modal = {
    Month: currentMonth,
  }

  // {
  //   "TableName": "EmployeeAttendence",
  //   "SearchCriteria": {
  //     "ParentID": "13",
  //     "Month": "6",
  //     "Year": "2023"
  //   }
  // }
  const [employeeOverAllAttendance, setEmployeeOverAllAttendance] = useState()
  const [selectDate, setSelectDate] = useState(SelectMonth_Modal)
  const [searchOverAllEmployeeData, setSearchOverAllEmployeeData] = useState(OverAll_AttAndLv_Modal);
  const [searchParam, setSearchParam] = useState(AttAndLv_Modal);
  const [searchMonthlyParam, setsearchMonthlyParam] = useState(MonthlyAttAndLv_Modal);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [attendanceEmployeeData, setAttendanceData] = useState([]);
  const [attendanceMonthlyData, setAttendanceMonthlyData] = useState([]);
  const [refresh, setRefresh] = useState(false);


  /////////// handle tabs 
  const [activeTab, setActiveTab] = useState('OverAllView'); // Set default active tab here
  const [renderedTabs, setRenderedTabs] = useState({});
  const [tabList, setTabsList] = useState([])

  // const LoadData = (searchParam) => {

  //   setIsLoading(true)

  //   ServerRequestProtected(API_URL + '/FindByMultipleCriteria', 'post', {
  //     'TableName': enumUtil.enumAPITableName.EmployeeAttendence,
  //     "SearchCriteria": searchParam,
  //   }).then((response) => {

  //     let joinStr = "";
  //     for(let i in response){
  //       const jsonData = response[i]['JSON_F52E2B61-18A1-11d1-B105-00805F49916B'];
  //       for(let j in jsonData){
  //         joinStr += jsonData[j];
  //       }
  //     }
  //     try{
  //     console.log("joinStr",joinStr);
  //     // const parsedJSON = joinStr && JSON.parse(joinStr);

  //     // console.log("parsed",parsedJSON)
  //     setAttendanceData(joinStr);
  //     console.log("parsedJSON",attendanceEmployeeData);
  //     }
  //     catch(error){
  //       console.error("Error parsing JSON:", error);
  //     }
  //     // console.log("joinStr",joinStr);
  //     // const parsedJSON = JSON.parse(joinStr);
  //     // console.log("parsedJSON",parsedJSON);
  //     // setAttendanceData(parsedJSON);
  //     NotificationHandler("Data Fetched Successfuly",enumUtil.enumtoaster.success);
  //     setIsLoading(false);
  //   }).catch((error) => {
  //     setIsLoading(false);
  //     NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
  //   })
  // }

  /// Fetch overAll Attendance of Employee ///

  const LoadOverAllData = (searchOverAllEmployeeData) => {
    setIsLoading(true);
    ServerRequestProtected(API_URL + "/GetCalculatedAttendance", "post", {
      "TableName": enumUtil.enumAPITableName.EmployeeAttendence,
      "SearchCriteria": searchOverAllEmployeeData,
    }).then((response) => {
      setIsLoading(false);
      setEmployeeOverAllAttendance(response[0]);
      // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success);
    }).catch((error) => {
      setIsLoading(false);
      // NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
    })

  }

  /// Fetch Attendance on monthly basis///

  const LoadMonthlyData = (searchMonthlyParam) => {
    setAttendanceMonthlyData([])
    setIsLoader(true);
    ServerRequestProtected(API_URL + "/FindByMultipleCriteria", 'post', {
      "TableName": enumUtil.enumAPITableName.EmployeeAttendence,
      "SearchCriteria": searchMonthlyParam,
    })
      .then((response) => {
        let jsonStr = '';
        for (let i in response) {
          const jsonData = response[i]['JSON_F52E2B61-18A1-11d1-B105-00805F49916B'];
          // console.log("jsonData",jsonData)
          for (let j in jsonData) {
            jsonStr += jsonData[j];
          }
        }
        // console.log("mothlyJsonStr",jsonStr)
        const parsedJson = JSON.parse(jsonStr);
        setAttendanceMonthlyData(parsedJson)
        // console.log("monthlyData",attendanceMonthlyData)
        // NotificationHandler("Data Fetched Successfuly", enumUtil.enumtoaster.success)
        setIsLoader(false);
      }).catch((error) => {
        setIsLoader(false);
        // NotificationHandler("Failed to load Data", enumUtil.enumtoaster.error);
      })
  }
  /// Fetch Attendance on yearly basis///

  // const LoadData = (searchParam) => {
  //   setIsLoading(true);
  //   setAttendanceData([])
  //   ServerRequestProtected(API_URL + "/FindByMultipleCriteria", 'post', {
  //     "TableName": enumUtil.enumAPITableName.EmployeeAttendence,
  //     "SearchCriteria": searchParam,
  //   })
  //     .then((response) => {
  //       let jsonStr = '';
  //       for (let i in response) {
  //         const jsonData = response[i]['JSON_F52E2B61-18A1-11d1-B105-00805F49916B'];
  //         for (let j in jsonData) {
  //           jsonStr += jsonData[j];
  //         }
  //       }
  //       const parsedJson = JSON.parse(jsonStr);
  //       setAttendanceData(parsedJson)
  //       // NotificationHandler("Data Fetched Successfuly", enumUtil.enumtoaster.success)
  //       setIsLoading(false);
  //     }).catch((error) => {
  //       setIsLoading(false);
  //       // NotificationHandler("Failed to load Data", enumUtil.enumtoaster.error);
  //     })
  // }



  /// Tabular view data fetched on basis of selected month ///

  const handleUpdateMonthSearchParam = (e) => {

    const updatedMonthlyModel = { ...searchMonthlyParam, Month: e.target.value }
    setsearchMonthlyParam(updatedMonthlyModel);
    const updatedSelectDate = { ...selectDate, Month: e.target.value }
    setSelectDate(updatedSelectDate)
  }

  // useEffect(() => {
  //   LoadData(searchParam);

  // }, [refresh])

  useEffect(() => {
    LoadMonthlyData(searchMonthlyParam);
  }, [searchMonthlyParam])

  useEffect(() => {
    LoadOverAllData(searchOverAllEmployeeData)
  }, [searchOverAllEmployeeData])


  // useEffect(()=>{
  //   let sP = {
  //     UserID: Active ? Active + "" : UserID + "",
  //     Year: currentYear + "",
  //   }
  //   let sOAE = {
  //     EmployeeIDs: Active ? Active + "" : UserID + "",
  //     OrganizationID: OrgID + "",
  //     Months: "",
  //     Year: currentYear + "",
  //   }
  //   let sMP = {
  //     UserID: Active ? Active+"" : UserID + "",
  //     Month: currentMonth + "",
  //     Year: currentYear + "",
  //   }
  //   setSearchParam(prev=>sP)
  //   setSearchOverAllEmployeeData(prev=>sOAE)
  //   setsearchMonthlyParam(prev=>sMP)

  //   console.log("Active",typeof Active , Active,
  //   "searchParam",sP,
  //   "searchOverAllEmployeeData",sOAE,
  //   "searchMonthlyParam",sMP,
  // )
  //   if(Active){
  //     LoadData(sP);
  //     LoadMonthlyData(sOAE);
  //     LoadOverAllData(sMP)
  //   }
  // },[Active])
  let MenuList = [
    {
      key: 'OverAllView',
      name: 'OverAll View',
      Component: <OverallView Active={Active} refresh={refresh} setRefresh={setRefresh} type={"Employee"} />,
    },
    {
      key: 'CalendarView',
      name: 'Calendar View',
      Component: <CalendarView Active={Active} />,
    },
    {
      key: 'TabularView',
      name: 'Tabular View',
      Component: <TabularView Active={Active} attendanceEmployeeData={attendanceMonthlyData} handleUpdateMonthSearchParam={handleUpdateMonthSearchParam} selectDate={selectDate} employeeOverAllAttendance={employeeOverAllAttendance} />,
    },
    {
      key: 'ListView',
      name: 'List View',
      Component: <ListView Active={Active} isLoader={isLoader} attendanceEmployeeData={attendanceMonthlyData} handleUpdateMonthSearchParam={handleUpdateMonthSearchParam} selectDate={selectDate} />,
    },
    {
      key: 'TimeAdjustmentView',
      name: 'Time Adjustment',
      Component: <TimeAdjustment Active={Active} />,
    },
  ]

  let activeTabElement = MenuList[0]

  const handleActiveTab = (item) => {
    const { key, name, Component } = { ...item }

    let dynamicTabs = [...tabList]
    if (tabList.length === 0) {
      const obj = {
        key: activeTabElement.key,
        name: activeTabElement.name,
        Component: activeTabElement.Component,
      }
      dynamicTabs.push(obj)
    }
    const newTabObject = {
      key: key,
      name: name,
      Component: Component,
    }
    const isTabExist = dynamicTabs.filter((tab) => {
      return tab.name === name
    })
    if (!(isTabExist && isTabExist.length > 0)) {
      dynamicTabs = [...dynamicTabs, newTabObject]
      setTabsList(dynamicTabs)
    }
    if (activeTab !== key) {
      setActiveTab(key)
    }
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div>
        {/* <h1>Attendance Employee</h1> */}
        <div className="h-100 tabs-container">
          <div className="hk-ems-v1 hk-EA h-100">
            <Tabs className="rb-tabs bg-light-blue">
              <Tab eventKey="calenderView" title="All Attendance">
                <Tab.Container defaultActiveKey="OverAllView">
                  <Nav variant="tabs" className="rb-tabs-v3 hk-tabView-btns">
                    {MenuList?.map((link) => {
                      return (
                        <>
                          <Nav.Item onClick={() => { handleActiveTab(link); }}>
                            <Nav.Link eventKey={link.key}
                            >
                              {link.name}
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      )
                    })}
                  </Nav>
                  <>
                    <div className={`row m-0 pt-1 basic_info overall_view h-auto ${Active ? 'employee_overall_veiw' : 'user_overall_view'}`}>
                      <div className={`col-md-3 pe-0 ${activeTab === "TimeAdjustmentView" ? 'd-none' : ''}`}>
                        <EmployeeInformation Active={Active} />
                      </div>
                      <div className={`hk-ems-v1 mt-3 ps-0 ${activeTab === "TimeAdjustmentView" ? 'col-md-12' : 'col-md-9'}`}>
                        {tabList?.length > 0 ? (
                          <>
                            {tabList?.map((item) => {
                              return (

                                <Tab.Pane eventKey={item.key}>
                                  <div>
                                    {item.Component}
                                  </div>
                                </Tab.Pane>

                              )
                            })}
                          </>

                        ) : (

                          <>
                            <Tab.Pane eventKey={activeTabElement.key}>
                              <>
                                {activeTabElement.Component}
                              </>
                            </Tab.Pane>
                          </>
                          // </Tab.Content>
                        )
                        }
                      </div>
                    </div>
                  </>

                </Tab.Container>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div >
    </>
  )
}

export default MyAttendance

