import React, { useEffect, useState } from "react";
import { addDays } from "date-fns";
import format from "date-fns/format";
import { Nav, Tab, Tabs } from "react-bootstrap";
import { DateFormat_MdY_Time, DateFormat_YMd, MonthsDropdown } from '../../../utils/Constants'
import { getDaysInMonth } from "../../EmployeeAttendance/MonthlyAttendance";
import Loader from "../../shared/Loader/Loader";
import EmployeeInformation from "../../MyInfo/Account/EmployeeInformation/EmployeeInformation";
import { enumUtil } from "../../../utils/Enum";
import NotificationHandler from "../../../utils/NotificationHandler";
import { ServerRequestProtected } from '../../../utils/ServerDataRequest';
import jwt_decode from 'jwt-decode';
import { useSelector } from "react-redux";

const ListView = ({ Active }) => {

    const token = useSelector((state) => state.TokenReducer)
    const Token = token && jwt_decode(token);
    const UserID = Active ? Active : null;
    const currentMonth = String(new Date().getMonth() + 1);
    const currentYear = String(new Date().getFullYear());
    let currentMonthNum = new Date().getMonth() + 1;
    let monthIndex = MonthsDropdown?.findIndex(it => it.value == currentMonthNum);
    let monthsDD = monthIndex !== -1 ? MonthsDropdown?.slice(0, monthIndex + 1) : MonthsDropdown;
    // let OverAll_AttAndLv_Modal = {
    //     EmployeeID: UserID + "",
    //     OrganizationID: OrgID + "",
    //     Months: "",
    //     Year: currentYear + "",
    // }

    let MonthlyAttAndLv_Modal = {
        EmployeeID: UserID,
        Month: currentMonth,
        Year: currentYear,
    }
    let SelectMonth_Modal = {
        Month: currentMonth,
    }

    const API_URL = process.env.REACT_APP_API;
    const [attendanceMonthlyData, setAttendanceMonthlyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectDate, setSelectDate] = useState(SelectMonth_Modal)
    const [searchMonthlyParam, setsearchMonthlyParam] = useState(MonthlyAttAndLv_Modal);

    const LoadMonthlyData = (searchMonthlyParam) => {
        setIsLoading(true);
        ServerRequestProtected(API_URL + "/FindByMultipleCriteria", 'post', {
            "TableName": enumUtil.enumAPITableName.EmployeeAttendence,
            "SearchCriteria": searchMonthlyParam,
        })
            .then((response) => {
                let jsonStr = '';
                for (let i in response) {
                    const jsonData = response[i]['JSON_F52E2B61-18A1-11d1-B105-00805F49916B'];
                    // console.log("jsonData",jsonData)
                    for (let j in jsonData) {
                        jsonStr += jsonData[j];
                    }
                }
                // console.log("mothlyJsonStr",jsonStr)
                const parsedJson = JSON.parse(jsonStr);
                setAttendanceMonthlyData(parsedJson[0]?.Attendance)
                // console.log("monthlyData",attendanceMonthlyData)
                // NotificationHandler("Data Fetched Successfuly", enumUtil.enumtoaster.success)
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                // NotificationHandler("Failed to load Data", enumUtil.enumtoaster.error);
            })
    }

    const handleUpdateMonthSearchParam = (e) => {

        const updatedMonthlyModel = { ...searchMonthlyParam, Month: e.target.value }
        setsearchMonthlyParam(updatedMonthlyModel);
        const updatedSelectDate = { ...selectDate, Month: e.target.value }
        setSelectDate(updatedSelectDate)
    }

    //     const getTimeInMilliseconds = (checkTime, formatedDate) => {
    // console.log(":",checkTime,formatedDate )
    //         let timeString = checkTime;
    //         let dateObject = new Date(formatedDate);
    //         let [time, period] = timeString.split(' ');
    //         let [hours, minutes] = time.split(':');
    //         console.log("time", time, "hours", hours);
    //         let numericHours = parseInt(hours, 10);
    //         let numericMinutes = parseInt(minutes);

    //         dateObject.setHours(numericHours)
    //         dateObject.setMinutes(numericMinutes);
    //         let timeStamp = dateObject.getTime();
    //         return timeStamp;
    //         console.log("dateObject", dateObject)
    //         console.log("timeStamp", timeStamp)
    //     }

    const getTimeInMilliseconds = (time) => {
        // Parse the time string into hours and minutes
        const [timeStr, period] = time.split(' ');
        const [hours, minutes] = timeStr.split(':').map(Number);

        // Convert to 24-hour format
        let hours24 = hours;
        if (period === "PM" && hours < 12) {
            hours24 += 12;
        } else if (period === "AM" && hours === 12) {
            hours24 = 0;
        }

        // Convert to minutes
        return hours24 * 60 + minutes;
    }
    const calculatePercentage = (checkInTime, checkOutTime, shiftStartTime, shiftEndTime) => {
        // Convert times to minutes
        // const checkInMinutes = getMinutes(checkInTime);
        // const checkOutMinutes = getMinutes(checkOutTime);
        // const shiftStartMinutes = getMinutes(shiftStartTime);
        // const shiftEndMinutes = getMinutes(shiftEndTime);

        // Calculate differences
        const checkInDifference = checkInTime - shiftStartTime;
        const checkOutDifference = checkOutTime - shiftEndTime;

        // Calculate total shift duration
        const shiftDuration = shiftStartTime - shiftEndTime;

        // Calculate percentages
        const checkInPercentage = (checkInDifference / shiftDuration) * 100;
        const checkOutPercentage = (checkOutDifference / shiftDuration) * 100;

        return {
            checkInPercentage,
            checkOutPercentage
        };
    }

    useEffect(() => {
        LoadMonthlyData(searchMonthlyParam);
    }, [searchMonthlyParam])

    return (
        <div>
            {isLoading ? <Loader /> : <></>}
            <Tabs className="rb-tabs bg-light-blue">
                <Tab eventKey="WeeklyView" title={"null"}>
                    <Tab.Container defaultActiveKey="WeeklyView">
                        <>
                            <div className="d-none">
                                <div className="container-fluid action-row br-bottom-1 br-clr d-flex justify-content-end align-items-center bg-white">
                                    {/* <div className="form-group input hk-ems-datePicker">
                                    <div className="calender-icon d-flex justify-content-center align-items-center hk-date-ranger">
                                        <span>Date</span>
                                        <i
                                            className="icon-angle-down input-icon"
                                            onClick={openDateRangePickerMenu}
                                        ></i>
                                        <input
                                            value={value}
                                            readOnly
                                            placeholder="choose date"
                                            className="form-control"
                                            onClick={openDateRangePickerMenu}
                                        />
                                        <i
                                            className="icon-angle-down input-icon"
                                            onClick={openDateRangePickerMenu}
                                        ></i>
                                    </div>
                                    <Menu
                                        className="calender-menu"
                                        anchorEl={position}
                                        open={openDateRangePicker}
                                        onClose={closeDateRangePickerMenu}
                                        MenuListProps={{
                                            "aria-labelledby": "basic-button",
                                        }}
                                    >
                                        <div className="DateRangePicker-Wrapper">
                                            <DateRangePicker
                                                onChange={(item) => handleRangeChange([item.selection])}
                                                editableDateInputs={true}
                                                moveRangeOnFirstSelection={false}
                                                ranges={range}
                                                months={2}
                                                direction="horizontal"
                                                className={showCalendarRange ? "" : "hide"}
                                            />
                                            <div className="DateRangePicker-Action">
                                                <button className="btn btn-gray btn-sm">Cancel</button>
                                                <button className="btn btn-orange btn-sm">Apply</button>
                                            </div>
                                        </div>
                                    </Menu>
                                </div> */}
                                    <div className="hk-btnContainer">
                                        {/* <button className="btn">Apply Leave</button>
                                    <button className="btn">Timesheet</button>
                                    <button className="btn">Regularize</button> */}
                                        Month :   <div className="input br-bottom-1 br-clr ms-3">
                                            <select className="form-control border-0"
                                                name="Month"
                                                id="Month"
                                                aria-label="Default select example"
                                                value={selectDate.Month}
                                                onChange={(e) => handleUpdateMonthSearchParam(e)}
                                            >
                                                {monthsDD?.map((item) => {
                                                    return (
                                                        <option value={item.value} >{item.display}</option>
                                                    )
                                                })}
                                                {/* <option value={1}>January</option>
                                                <option value={2}>February</option>
                                                <option value={3}>March</option>
                                                <option value={4}>April</option>
                                                <option value={5}>May</option>
                                                <option value={6}>June</option>
                                                <option value={7}>July</option>
                                                <option value={8}>August</option>
                                                <option value={9}>September</option>
                                                <option value={10}>October</option>
                                                <option value={11}>November</option>
                                                <option value={12}>December</option> */}
                                            </select>
                                        </div>
                                        <div>
                                            <Nav
                                                variant="tabs"
                                                className="rb-tabs-v3 hk-tabView-innerbtns"
                                            >
                                                {/* <Nav.Item>
                                                <Nav.Link eventKey="monthlyView">Monthly</Nav.Link>
                                            </Nav.Item> */}
                                                <Nav.Item>
                                                    <Nav.Link eventKey="WeeklyView">Monthly</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-0">
                                    <div className="col-md-9 ps-2 pe-0">
                                        {/* <Tab.Pane eventKey="monthlyView">hi</Tab.Pane> */}
                                        <Tab.Pane eventKey="WeeklyView">
                                            <div className="weeklyview-screen">
                                                <div className="weekly-statusbar">
                                                    <div className="weekly-statusbar__tiles">
                                                        <div className="weekly-statusbar__tiles__pill available">
                                                            Availablity
                                                        </div>
                                                        <div className="weekly-statusbar__tiles__pill absent">
                                                            Absent
                                                        </div>
                                                        <div className="weekly-statusbar__tiles__pill weekend">
                                                            Weekend
                                                        </div>
                                                        <div className="weekly-statusbar__tiles__pill shortbreak">
                                                            Short Break
                                                        </div>
                                                        <div className="weekly-statusbar__tiles__pill holiday">
                                                            Holiday
                                                        </div>
                                                        <div className="weekly-statusbar__tiles__pill leave">
                                                            Leave
                                                        </div>
                                                        <div className="weekly-statusbar__tiles__pill latecount">
                                                            Late Count
                                                        </div>
                                                    </div>
                                                    {/* <div className="weekly-statusbar__checkInStatus">
                                                    Check-in 11:23:44 PM
                                                </div> */}
                                                </div>
                                                <div className="listview-content">
                                                    <div className="listview-content__header">
                                                        <div className="listview-content__header__left">
                                                            <span></span>
                                                            <span>Check-in</span>
                                                        </div>
                                                        <div className="listview-content__header__right">
                                                            <span>Check-out</span>
                                                            <span >Total Hours</span>
                                                        </div>
                                                    </div>
                                                    <div className="listview-content__body">
                                                        {/* <ProgressStatBar weekend={true} /> */}
                                                        {attendanceMonthlyData && attendanceMonthlyData.map((item) => {
                                                            const date = item.Date.split('/')
                                                            const month = new Date(date[2], date[1] - 1, date[0]);
                                                            const dayName = month.toLocaleDateString('en-US', { weekday: 'short' })
                                                            // Use regular expression to extract the day number
                                                            const dayNumberMatch = month.toString().match(/\b\d{1,2}\b/);

                                                            // Get the day number from the matched result
                                                            const dayNumber = dayNumberMatch ? dayNumberMatch[0] : null;

                                                            const formatedDate = DateFormat_YMd(item.Date);
                                                            const checkInTimestamp = item.FirstIn && getTimeInMilliseconds(item.FirstIn, formatedDate)
                                                            const checkOutTimestamp = item.LastOut && getTimeInMilliseconds(item.LastOut, formatedDate)
                                                            const shiftStartTimeStamp = item.ShiftStartTime && getTimeInMilliseconds(item.ShiftStartTime, formatedDate)
                                                            const shiftEndTimeStamp = item.ShiftEndTime && getTimeInMilliseconds(item.ShiftEndTime, formatedDate)

                                                            if (item.Status === "Present") {
                                                                return (
                                                                    <ProgressStatBar
                                                                        dayName={dayName}
                                                                        dayNumber={dayNumber}
                                                                        totalHours={item.TotalHours}
                                                                        checkIn={item.FirstIn}
                                                                        checkOut={item.LastOut}
                                                                        shiftStartTime={shiftStartTimeStamp}
                                                                        shiftEndTime={shiftEndTimeStamp}
                                                                        checkInTime={checkInTimestamp}
                                                                        checkOutTime={checkOutTimestamp}
                                                                        status={"available"}
                                                                    />
                                                                )
                                                            } else if (item.Status === "Weekend") {
                                                                return (
                                                                    <ProgressStatBar status={"weekend"} dayName={dayName} dayNumber={dayNumber} weekend={true} />
                                                                )

                                                            } else if (item.Status === "NH") {
                                                                return (
                                                                    <ProgressStatBar
                                                                        dayName={dayName}
                                                                        dayNumber={dayNumber}
                                                                        sshiftStartTime={shiftStartTimeStamp}
                                                                        shiftEndTime={shiftEndTimeStamp}
                                                                        checkInTime={shiftStartTimeStamp}
                                                                        checkOutTime={shiftEndTimeStamp}
                                                                        status={"holiday"}
                                                                    />
                                                                )
                                                            } else if (item.Status === "Absent") {
                                                                return (
                                                                    <ProgressStatBar
                                                                        dayName={dayName}
                                                                        dayNumber={dayNumber}
                                                                        sshiftStartTime={shiftStartTimeStamp}
                                                                        shiftEndTime={shiftEndTimeStamp}
                                                                        checkInTime={shiftStartTimeStamp}
                                                                        checkOutTime={shiftEndTimeStamp}
                                                                        status={"absent"}
                                                                    />
                                                                )

                                                            } else if (item.Status === "Leave") {
                                                                return (
                                                                    <ProgressStatBar
                                                                        dayName={dayName}
                                                                        dayNumber={dayNumber}
                                                                        sshiftStartTime={shiftStartTimeStamp}
                                                                        shiftEndTime={shiftEndTimeStamp}
                                                                        checkInTime={shiftStartTimeStamp}
                                                                        checkOutTime={shiftEndTimeStamp}
                                                                        status={"leave"}
                                                                    />
                                                                )
                                                            }
                                                        })}


                                                        {/* <ProgressStatBar
                                                        shiftStartTime={8}
                                                        shiftEndTime={20}
                                                        checkInTime={10}
                                                        checkOutTime={18}
                                                        status={"available"}
                                                    />
                                                    <ProgressStatBar
                                                        shiftStartTime={8}
                                                        shiftEndTime={20}
                                                        checkInTime={10}
                                                        checkOutTime={18}
                                                        status={"available"}
                                                    />
                                                    <ProgressStatBar
                                                        shiftStartTime={8}
                                                        shiftEndTime={20}
                                                        checkInTime={10}
                                                        checkOutTime={15}
                                                        status={"shortbreak"}
                                                    />
                                                    <ProgressStatBar
                                                        shiftStartTime={8}
                                                        shiftEndTime={20}
                                                        checkInTime={14}
                                                        checkOutTime={20}
                                                        status={"absent"}
                                                    />
                                                    <ProgressStatBar
                                                        shiftStartTime={8}
                                                        shiftEndTime={20}
                                                        checkInTime={8}
                                                        checkOutTime={20}
                                                        status={"holiday"}
                                                    />
                                                    <ProgressStatBar weekend={true} />
                                                    <ProgressStatBar weekend={true} /> */}
                                                    </div>
                                                    <div className="listview-content__footer">
                                                        <div className="listview-content__footer__middle">
                                                            <span>08:00AM</span>
                                                            <span>10:00AM</span>
                                                            <span>12:00AM</span>
                                                            <span>2:00PM</span>
                                                            <span>4:00PM</span>
                                                            <span>6:00PM</span>
                                                            <span>8:00PM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </div>
                                    <div className="col-md-3 px-0">
                                        <div className="hk-Stats-sidebar">
                                            <div className="hk-Stats-sidebar__header">
                                                <p className="heading">Overall Stats</p>
                                            </div>
                                            <div className="hk-Stats-sidebar__content">
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Payable Days</p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span>Days</span>
                                                            <span className="count bg-org">19.5 Days</span>
                                                        </div>
                                                        <div>
                                                            <span>Hours</span>
                                                            <span className="count bg-yel">0.0h</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Present Days</p>
                                                        <p className="title">Absent Days</p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span></span>
                                                            <span className="count bg-grn">13.5 Days</span>
                                                        </div>
                                                        <div>
                                                            <span></span>
                                                            <span className="count bg-red">7 Days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">On Duty</p>
                                                        <p className="title"></p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span>Days</span>
                                                            <span className="count bg-prp">13.5 Days</span>
                                                        </div>
                                                        <div>
                                                            <span>Hours</span>
                                                            <span className="count bg-yel">7 Days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Paid Leave</p>
                                                        <p className="title">UnPaid Leave</p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span>Days</span>
                                                            <span className="count bg-yel">13.5 Days</span>
                                                        </div>
                                                        <div>
                                                            <span>Hours</span>
                                                            <span className="count bg-tea">7 Days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Holidays</p>
                                                        <p className="title"></p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span>Days</span>
                                                            <span className="count bg-blu">13.5 Days</span>
                                                        </div>
                                                        <div>
                                                            <span>Hours</span>
                                                            <span className="count bg-yel">7 Days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Weekend</p>
                                                        <p className="title"></p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span>Days</span>
                                                            <span className="count bg-org">6 Days</span>
                                                        </div>
                                                        <div>
                                                            <span>Hours</span>
                                                            <span className="count bg-yel">0.00h</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="hk-Stats-sidebar__content__item mt-4">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Total Hours</p>
                                                    </div>
                                                    <input type="text" placeholder="12:01 AM - 11:59 PM" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="tabs-action-bottom">
                                <div className="btns-group text-end px-3 br-top-1 br-clr d-flex align-items-center justify-content-end h-100">
                                    <button className="btn btn-gray">Cancel</button>
                                    <button className="btn btn-orange">Save</button>
                                </div>
                            </div> */}
                            </div>
                            <div className={`pb-2 basic_info list_view overall_view h-auto ${Active ? 'emp_atend_screen' : ''}`}>
                                <div className="d-flex px-2 justify-content-between align-items-center me-1 dropdown-bgc-position date-slect-bg">
                                    <div className="d-flex gap-4">
                                        <p className="text clr_gry"><span className="present status"></span>Present</p>
                                        <p className="text clr_gry"><span className="absent status"></span>Absent</p>
                                        <p className="text clr_gry"><span className="late_count status"></span>Late Count</p>
                                        <p className="text clr_gry"><span className="holiday status"></span>Holiday</p>
                                        <p className="text clr_gry"><span className="weekend status"></span>Weekend</p>
                                        <p className="text clr_gry"><span className="leave status"></span>Leave</p>
                                    </div>
                                    <div>
                                        <div class="input">
                                            <select className="form-control"
                                                name="Month"
                                                id="Month"
                                                aria-label="Default select example"
                                                value={selectDate.Month}
                                                onChange={(e) => handleUpdateMonthSearchParam(e)}
                                            >
                                                {monthsDD?.map((item) => {
                                                    return (
                                                        <option value={item.value} >{item.display}</option>
                                                    )
                                                })}
                                                {/* <option value={1}>January</option>
                                                        <option value={2}>February</option>
                                                        <option value={3}>March</option>
                                                        <option value={4}>April</option>
                                                        <option value={5}>May</option>
                                                        <option value={6}>June</option>
                                                        <option value={7}>July</option>
                                                        <option value={8}>August</option>
                                                        <option value={9}>September</option>
                                                        <option value={10}>October</option>
                                                        <option value={11}>November</option>
                                                        <option value={12}>December</option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 mt-2">
                                    <div className="col-md-8 p-0 weeklyview-screen">
                                        <div className="listview-content">
                                            <div className="listview-content__header">
                                                <div className="listview-content__header__left">
                                                    <span></span>
                                                    <span>Check-in</span>
                                                </div>
                                                <div className="listview-content__header__right">
                                                    <span>Check-out</span>
                                                    <span >Total Hours</span>
                                                </div>
                                            </div>
                                            <div className="listview-content__body">
                                                {/* <ProgressStatBar weekend={true} /> */}
                                                {attendanceMonthlyData && attendanceMonthlyData.reverse().map((item) => {
                                                    const date = item.Date.split('/')
                                                    const month = new Date(date[2], date[1] - 1, date[0]);
                                                    const dayName = month.toLocaleDateString('en-US', { weekday: 'short' })
                                                    // Use regular expression to extract the day number
                                                    const dayNumberMatch = month.toString().match(/\b\d{1,2}\b/);

                                                    // Get the day number from the matched result
                                                    const dayNumber = dayNumberMatch ? dayNumberMatch[0] : null;

                                                    // console.log("Day Number:", dayNumber); // This will correctly output "Day Number: 2"

                                                    // console.log("dayInMonth", month);
                                                    // console.log("dayNumber", dayNumber);
                                                    // console.log("dayName", dayName);
                                                    // console.log("vvv", item);
                                                    const formatedDate = DateFormat_YMd(item?.Date);
                                                    const checkInTimestamp = item?.FirstIn && getTimeInMilliseconds(item.FirstIn, formatedDate)
                                                    const checkOutTimestamp = item?.LastOut && getTimeInMilliseconds(item.LastOut, formatedDate)
                                                    const shiftStartTimeStamp = item?.ShiftStartTime && getTimeInMilliseconds(item.ShiftStartTime, formatedDate)
                                                    const shiftEndTimeStamp = item?.ShiftEndTime && getTimeInMilliseconds(item.ShiftEndTime, formatedDate)

                                                    if (item?.Status === "Present") {
                                                        return (
                                                            <ProgressStatBar
                                                                date={formatedDate}
                                                                dayName={dayName}
                                                                dayNumber={dayNumber}
                                                                totalHours={item?.TotalHours}
                                                                checkIn={item?.FirstIn}
                                                                checkOut={item?.LastOut}
                                                                shiftStartTime={shiftStartTimeStamp}
                                                                shiftEndTime={shiftEndTimeStamp}
                                                                checkInTime={checkInTimestamp}
                                                                checkOutTime={checkOutTimestamp}
                                                                status={"available"}
                                                            />
                                                        )
                                                    } else if (item.Status === "Weekend") {
                                                        return (
                                                            <ProgressStatBar status={"weekend"} dayName={dayName} dayNumber={dayNumber} weekend={true} />
                                                        )

                                                    } else if (item.Status === "NH") {
                                                        return (
                                                            <ProgressStatBar
                                                                date={formatedDate}
                                                                dayName={dayName}
                                                                dayNumber={dayNumber}
                                                                sshiftStartTime={shiftStartTimeStamp}
                                                                shiftEndTime={shiftEndTimeStamp}
                                                                checkInTime={shiftStartTimeStamp}
                                                                checkOutTime={shiftEndTimeStamp}
                                                                status={"holiday"}
                                                            />
                                                        )
                                                    } else if (item.Status === "Absent") {
                                                        return (
                                                            <ProgressStatBar
                                                                date={formatedDate}
                                                                dayName={dayName}
                                                                dayNumber={dayNumber}
                                                                sshiftStartTime={shiftStartTimeStamp}
                                                                shiftEndTime={shiftEndTimeStamp}
                                                                checkInTime={shiftStartTimeStamp}
                                                                checkOutTime={shiftEndTimeStamp}
                                                                status={"absent"}
                                                            />
                                                        )

                                                    } else if (item.Status === "Leave") {
                                                        return (
                                                            <ProgressStatBar
                                                                date={formatedDate}
                                                                dayName={dayName}
                                                                dayNumber={dayNumber}
                                                                sshiftStartTime={shiftStartTimeStamp}
                                                                shiftEndTime={shiftEndTimeStamp}
                                                                checkInTime={shiftStartTimeStamp}
                                                                checkOutTime={shiftEndTimeStamp}
                                                                status={"leave"}
                                                            />
                                                        )
                                                    }
                                                })}


                                                {/* <ProgressStatBar
                                                        shiftStartTime={8}
                                                        shiftEndTime={20}
                                                        checkInTime={10}
                                                        checkOutTime={18}
                                                        status={"available"}
                                                    />
                                                    <ProgressStatBar
                                                        shiftStartTime={8}
                                                        shiftEndTime={20}
                                                        checkInTime={10}
                                                        checkOutTime={18}
                                                        status={"available"}
                                                    />
                                                    <ProgressStatBar
                                                        shiftStartTime={8}
                                                        shiftEndTime={20}
                                                        checkInTime={10}
                                                        checkOutTime={15}
                                                        status={"shortbreak"}
                                                    />
                                                    <ProgressStatBar
                                                        shiftStartTime={8}
                                                        shiftEndTime={20}
                                                        checkInTime={14}
                                                        checkOutTime={20}
                                                        status={"absent"}
                                                    />
                                                    <ProgressStatBar
                                                        shiftStartTime={8}
                                                        shiftEndTime={20}
                                                        checkInTime={8}
                                                        checkOutTime={20}
                                                        status={"holiday"}
                                                    />
                                                    <ProgressStatBar weekend={true} />
                                                    <ProgressStatBar weekend={true} /> */}
                                            </div>
                                            <div className="listview-content__footer">
                                                <div className="listview-content__footer__middle">
                                                    <span>08:00AM</span>
                                                    <span>10:00AM</span>
                                                    <span>12:00AM</span>
                                                    <span>2:00PM</span>
                                                    <span>4:00PM</span>
                                                    <span>6:00PM</span>
                                                    <span>8:00PM</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-0">
                                        <div className="hk-Stats-sidebar">
                                            <div className="hk-Stats-sidebar__header">
                                                <p className="heading">Overall Stats</p>
                                            </div>
                                            <div className="hk-Stats-sidebar__content">
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Payable Days</p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span>Days</span>
                                                            <span className="count bg-org">19.5 Days</span>
                                                        </div>
                                                        <div>
                                                            <span>Hours</span>
                                                            <span className="count bg-yel">0.0h</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Present Days</p>
                                                        <p className="title">Absent Days</p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span></span>
                                                            <span className="count bg-grn">13.5 Days</span>
                                                        </div>
                                                        <div>
                                                            <span></span>
                                                            <span className="count bg-yel">7 Days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">On Duty</p>
                                                        <p className="title"></p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span>Days</span>
                                                            <span className="count bg-prp">13.5 Days</span>
                                                        </div>
                                                        <div>
                                                            <span>Hours</span>
                                                            <span className="count bg-yel">7 Days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Paid Leave</p>
                                                        <p className="title">UnPaid Leave</p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span>Days</span>
                                                            <span className="count bg-tea">13.5 Days</span>
                                                        </div>
                                                        <div>
                                                            <span>Hours</span>
                                                            <span className="count bg-yel">7 Days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Holidays</p>
                                                        <p className="title"></p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span>Days</span>
                                                            <span className="count bg-blu">13.5 Days</span>
                                                        </div>
                                                        <div>
                                                            <span>Hours</span>
                                                            <span className="count bg-yel">7 Days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Weekend</p>
                                                        <p className="title"></p>
                                                    </div>
                                                    <div className="hk-Stats-sidebar__content__item__Stat">
                                                        <div>
                                                            <span>Days</span>
                                                            <span className="count bg-gry">6 Days</span>
                                                        </div>
                                                        <div>
                                                            <span>Hours</span>
                                                            <span className="count bg-yel">0.00h</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hk-Stats-sidebar__content__item mt-4">
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="title">Total Hours</p>
                                                    </div>
                                                    <input type="text" placeholder="12:01 AM - 11:59 PM" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Tab.Container>
                </Tab>
            </Tabs>
        </div>
    )
}

export default ListView

const ProgressStatBar = ({ shiftStartTime, shiftEndTime, checkInTime, checkOutTime, weekend, status, checkIn, checkOut, totalHours, dayName, dayNumber, date }) => {
    //status color
    const statusColor = [
        {
            status: "available",
            color: "#549F4E",
        },
        {
            status: "absent",
            color: "#FD7777",
        },
        {
            status: "leave",
            color: "#D79DF5",
        },
        {
            status: "shortbreak",
            color: "#EFBF2B",
        },
        {
            status: "holiday",
            color: "#80ADBC",
        },
    ];
    /////////////////////////////////////////
    const calculatePercentage = (checkInTime, checkOutTime, shiftStartTime, shiftEndTime) => {
        // Convert times to minutes
        // const checkInMinutes = getMinutes(checkInTime);
        // const checkOutMinutes = getMinutes(checkOutTime);
        // const shiftStartMinutes = getMinutes(shiftStartTime);
        // const shiftEndMinutes = getMinutes(shiftEndTime);

        // Calculate differences
        const checkInDifference = (checkInTime || 0) - (shiftStartTime || 0);
        const checkOutDifference = checkOutTime ? (checkOutTime - shiftEndTime) : 0;


        // Calculate total shift duration
        const shiftDuration = shiftStartTime - shiftEndTime;

        // Calculate percentages
        const checkInPercentage = (checkInDifference / shiftDuration) * 100;
        const checkOutPercentage = (checkOutDifference / shiftDuration) * 100;

        return {
            checkInPercentage,
            checkOutPercentage
        };
    }
    let percentageObj = calculatePercentage(checkInTime, checkOutTime, shiftStartTime, shiftEndTime)
    // Calculate the percentage of completion checkInPercentage,
    // checkOutPercentage

    // const shiftDuration = shiftEndTime - shiftStartTime;
    const dayStatus = ["holiday", "leave", "absent"];
    // const startPercentage = ((checkInTime - shiftStartTime) / 9) * 100;
    // const endPercentage = 100 - ((checkOutTime - shiftStartTime) / 9) * 100;
    const startPercentage = percentageObj?.checkInPercentage * -1;
    const endPercentage = percentageObj?.checkOutPercentage;
    let isCurrentDay = DateFormat_MdY_Time(date) == DateFormat_MdY_Time(new Date());
    function clampToRange(number, min, max) {
        return Math.min(Math.max(number, min), max);
    }

    return (
        <div className="statprogress__row">
            <div className="statprogress__row__left">

                <span className={`d-flex flex-column ${isCurrentDay ? 'rbc-now' : ''}`}>
                    <span className="text-center">{dayName}</span>
                    <span className="text-center">{dayNumber}</span>
                </span>
                {/* <span>{dayName + " " + dayNumber}</span> */}
                <span className="check_in mt-2">{checkIn}</span>
            </div>
            <div className="statprogress__row__main">
                <div className="WorkProgress-container">
                    <div className="WorkProgress-container__bar">
                        {weekend ? (
                            <span className="WorkProgress-container__bar__weekend">
                                Weekend
                            </span>
                        ) : (
                            <div
                                style={{
                                    left: `${dayStatus.includes(status) ? '0' : (startPercentage || 0) < 0 ? clampToRange(startPercentage, 0, 100) : startPercentage}%`,
                                    right: `${dayStatus.includes(status) ? '0' : (endPercentage || 0) < 0 ? clampToRange(endPercentage, 0, 100) : endPercentage}%`,
                                    backgroundColor: `${statusColor.find(
                                        (item) => item.status === status
                                    ).color}`,
                                }}
                                className="WorkProgress-container__bar__fill"
                            ></div>
                        )}
                    </div>
                </div>
            </div>
            <div className="statprogress__row__right">
                <span className="check_out">{checkOut}</span>
                <span className="totalHour">{totalHours} {status === "holiday" || status === "leave" || status === "absent" || status === "weekend" ? " " : "Hrs"}</span>
            </div>
        </div>
    );
};