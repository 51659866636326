import React, { useEffect, useReducer, useState } from "react";
import { enumUtil } from "../../../utils/Enum";
import NotificationHandler from "../../../utils/NotificationHandler";
import { ServerRequestProtected, ServerRequestPublic } from "../../../utils/ServerDataRequest";
import { saveAs, } from "file-saver"
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import AddNewTask from "./AddNewTask";
import ReactDataGrid from "react-data-grid";
import Loader from "../../shared/Loader/Loader";
import CustomUseRef from "../../shared/CustomUseRef/CustomUseRef";
import { format } from "date-fns";
import { DateFormat, DateFormat_dMy } from "../../../utils/Constants";
import CalendarInput from "../../../utils/CalendarInput";
import CalendaRangeInput from "../../shared/General/CalendarRangeInput";
import isNull from "../../../utils/NullChecking";
import DOMPurify from 'dompurify';
import GridColumnHeader from "../../shared/GridColumnHeader/GridColumnHeader";

const AllEmployeesTask = (props) => {
  const { type, currentMonth } = { ...props };

  const currentDay = format(new Date(), DateFormat_dMy);
  const currentDaySave = format(new Date(), DateFormat);

  let ScreenShot_Modal = {
    Date: currentDay,
  };

  let ScreenShotSave_Modal = {
    DateFrom: currentDaySave,
    DateTo: currentDaySave,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [TrackingData, setTrackingData] = useState([]);
  const [TaskID, setTaskID] = useState();
  const [isRefresh, setIsRefresh] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [delID, setDelID] = useState(0);
  const [isOpenTask, setIsOpenTask] = useState(false);
  const [actionsbutton, setActionsbutton] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [EmployeeName, setEmployeeName] = useState(null);
  const [Enter, setEnter] = useState();
  const [mainScreenShotDateModal, setMainScreenShotDateModal] =
    useState(ScreenShot_Modal);
  const [saveScreenShotDateModal, setSaveScreenShotDateModal] =
    useState(ScreenShotSave_Modal);
  const [gridHeader, handleGridHeader] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, { defaultData: '', activeSortIcon: '' })
  const API_URL = process.env.REACT_APP_API;

  let token = useSelector((state) => state.TokenReducer);
  let activeEmployee = useSelector((state) => state.EmployeeActiveTabReducer);
  let Token = token && jwt_decode(token);
  let UserID = "";
  if (!isNull(type) && type === "Employee") {
    UserID = activeEmployee + "";
  }
  // else {
  //     UserID = Token.UserID + "";
  // }
  const HtmlContent = ({ html }) => {
    const sanitizedHtml = DOMPurify.sanitize(html);
    const inlineHtml = sanitizedHtml
      .replace(/<ul>/g, '') // Remove <ul> tags
      .replace(/<\/ul>/g, '') // Remove </ul> tags
      .replace(/<ol>/g, '') // Remove <ol> tags
      .replace(/<\/ol>/g, '') // Remove </ol> tags
      .replace(/<li>/g, '') // Remove <li> tags
      .replace(/<\/li>/g, ' ') // Replace </li> tags with a space
      .replace(/<p>/g, '') // Remove <p> tags
      .replace(/<\/p>/g, ' ') // Replace </p> tags with a space
      .replace(/<blockquote>/g, ' ') // Replace </blockquote> tags with a space
      .replace(/<\/blockquote>/g, ' ') // Replace </blockquote> tags with a space
      .replace(/<strong>/g, ' ') // Replace </strong> tags with a space
      .replace(/<\/strong>/g, ' ') // Replace </strong> tags with a space
      .replace(/<s>/g, ' ') // Replace </s> tags with a space
      .replace(/<\/s>/g, ' ') // Replace </strong> tags with a space
      .replace(/<a>/g, ' ') // Replace </a> tags with a space
      .replace(/<\/a>/g, ' ') // Replace </strong> tags with a space
      .replace(/<span>/g, ' ') // Replace </span> tags with a space
      .replace(/<\/span>/g, ' '); // Replace </strong> tags with a space

    return <div dangerouslySetInnerHTML={{ __html: inlineHtml }} />;
  };

  const CloseActionPopup = () => {
    setActionsbutton(false);
  };
  const handeActionPopUp = (e, id) => {
    e.stopPropagation();
    setActionsbutton(true);
    setSelectedRow(id);
  };

  let Srchdata = {
    // "RequestType": RequestType,
    SearchLetter: EmployeeName,
    DateFrom: saveScreenShotDateModal?.DateFrom,
    DateTo: saveScreenShotDateModal?.DateTo,
  };

  const downloadFile = (filepath, filename) => {
    setIsLoading(true)
    var postData = {
      "BucketPath": filepath
    };
    const API_URL = 'https://emsbackend.theeasyedi.com/wasabi/DownloadFile'
    ServerRequestPublic(API_URL, 'post', postData, "Download")
      .then((res) => {
        let blob = new Blob([res]);
        saveAs(blob, filename);
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const columns = [
    {
      key: "EmployeeName",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Employees'} sortIcon={true} type={'string'} colKey={'EmployeeName'} data={TrackingData} setData={setTrackingData} />),

      // flex: 2,
      // height: "40px",
      width: 180,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <p
            className="text link-text font-m validation_message cursor-pointer"
            onClick={() => AddTask(row?.DailyProgressID)} title={row?.EmployeeName}
          >
            {row?.EmployeeName}
          </p>
        );
      },
    },
    {
      key: "Date",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Date'} sortIcon={true} type={'date'} colKey={'Date'} data={TrackingData} setData={setTrackingData} />),

      // flex: 1,
      // height: "40px",
      width: 80,
      resizable: true,
      formatter: ({ row }) => {
        return <div className="">{row?.Date}</div>;
      },
    },
    {
      key: "TotalTime",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Total Hours'} sortIcon={true} type={'string'} colKey={'TotalHours'} data={TrackingData} setData={setTrackingData} />),

      flex: 1,
      resizable: true,
      width: 115,
      resizable: true,
      formatter: ({ row }) => (
        <div className="validation_message" title={row?.TotalTime}>
          {row?.TotalTime}
        </div>
      ),
    },
    {
      key: "SessionTime",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Active Hours'} sortIcon={true} type={'string'} colKey={'Activehours'} data={TrackingData} setData={setTrackingData} />),

      // flex: 1,
      resizabl: true,
      width: 115,
      formatter: ({ row }) => (
        <div className="validation_message" title={row?.SessionTime}>
          {row?.SessionTime}
        </div>
      ),
    },
    {
      key: "TodayPlan",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Today Plan'} sortIcon={true} type={'string'} colKey={'TodayPlan'} data={TrackingData} setData={setTrackingData} />),
      width: 300,
      // flex: 2,
      resizable: true,
      // width: "40px",
      formatter: ({ row }) => (
        <div className="validation_message" title={row?.TodayPlan}>
          {HtmlContent({ html: row.TodayPlan })}
        </div>
      ),
    },
    // {
    //     key: 'YesterdayProgress',
    //     name: 'Yesterday Progress',
    //     flex: 1,
    //     resizable: true,
    //     formatter: ({ row }) => (
    //         <>
    //             {row.YesterdayProgress}
    //         </>
    //     ),
    // },
    {
      key: "Blockers",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Blockers'} sortIcon={true} type={'string'} colKey={'Blockers'} data={TrackingData} setData={setTrackingData} />),
      // flex: 1,
      width: 90,
      resizable: true,
      formatter: ({ row }) => <>{row?.Blockers === 'Yes' ? row?.Blockers : 'No'}</>,
    },
    {
      key: "FileName",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'File'} sortIcon={true} type={'string'} colKey={'FileName'} data={TrackingData} setData={setTrackingData} />),
      // name: 'File',
      flex: 1,
      // width: 130,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <>
            {(row?.AttachmentPath && row?.FileName) ?
              <div className="d-flex gap-1 align-items-center" onClick={() => downloadFile(row?.AttachmentPath, row?.FileName)}>
                <p className='text cursor-pointer  validation_message' title={row?.AttachmentPath}
                >
                  {row?.FileName}
                </p>
                <i className='icon-download-svgrepo-com fs-22 mt-1'
                ></i>
              </div> : <></>}
          </>
        )
      }
    },
    {
      key: "Actions",
      name: "Actions",
      width: 80,
      flex: 1,
      formatter: ({ row }) => {
        return (
          <>
            <div className="">
              <i
                className="icon-menuicon cursor-pointer"
                onClick={(e) => handeActionPopUp(e, row?.DailyProgressID)}
              ></i>
            </div>
            {selectedRow === row?.DailyProgressID && (
              <CustomUseRef
                onClose={CloseActionPopup}
                IsActivePtPayments={actionsbutton}
              >
                <>
                  <div className="actions_popup cursor-pointer">
                    <p
                      className="text  p-2 py-0"
                      onClick={() => AddTask(row?.DailyProgressID)}
                    >
                      <i className="icon-edit-pencil me-1"></i>Edit
                    </p>
                    {/* <p className="text p-2 py-0" onClick={() => handleDeleteModal(row?.DailyProgressID)}><i className="icon-Group-3994 me-1"></i>Delete</p> */}
                  </div>
                </>
              </CustomUseRef>
            )}
          </>
        );
      },
    },
  ];

  const LoadData = (Srchdata) => {
    setTrackingData([]);
    setIsLoading(true);
    let saveModal = {
      TableName: enumUtil.enumAPITableName.DAILYPROGRESS,
      SearchCriteria: {
        ...Srchdata,
      },
    };
    ServerRequestProtected(
      API_URL + "/EmployeeDailyProgress",
      "post",
      saveModal
    )
      .then((response) => {
        setIsLoading(false);
        setTrackingData(response);
        handleGridHeader({ defaultData: response })
        // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
      })
      .catch((error) => {
        setIsLoading(false);
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
      });
  };

  const SearchOnEnter = (e) => {
    if (e.key === "Enter") {
      setEnter(e.target.value.trim(""));
      setEmployeeName(e.target.value.trim(""));
      /// change ///
      LoadData(Srchdata);
    }
  };

  const [position, setPosition] = useState(null);
  const openDateRangePicker = Boolean(position);

  const openDateRangePickerMenu = (event) => {
    setPosition(event.currentTarget);
  };
  const closeDateRangePickerMenu = () => {
    setPosition(null);
  };
  const handleCalendarChange = (date, name) => {
    var FormatedDateSave = format(date, DateFormat);
    var FormatedDateShow = format(date, DateFormat_dMy);
    let modal = { ...mainScreenShotDateModal };
    let saveModal = { ...saveScreenShotDateModal };
    modal[name] = FormatedDateShow;
    setMainScreenShotDateModal(modal);
    for (var property in ScreenShotSave_Modal) {
      if (name === property) {
        saveModal[name] = FormatedDateSave;
      }
    }
    setSaveScreenShotDateModal(saveModal);
    closeDateRangePickerMenu();
  };

  const AddTask = (ID) => {
    setIsOpenTask(true);
    setTaskID(ID);
  };

  const CloseTask = () => {
    setIsOpenTask(false);
    if (isRefresh) {
      LoadData(Srchdata);
    }
  };

  let PopupScreen = "";
  if (isOpenTask) {
    PopupScreen = (
      <AddNewTask
        isOpenTask={isOpenTask}
        onClose={CloseTask}
        ID={TaskID}
        setIsRefresh={setIsRefresh}
        type={type}
      />
    );
  }

  useEffect(() => {
    LoadData(Srchdata);
  }, []);

  return (
    <>
      {PopupScreen}
      {isLoading ? <Loader /> : <></>}
      <div className="row flex-grow-1 mt-3 mb-2 pb-1 m-0 align-items-center">
        <div className="col-3">
          <div className="form-group input mb-0">
            <input
              type="text"
              className="form-control rounded"
              placeholder="First Name,Last Name/ID"
              onChange={(e) => setEmployeeName(e.target.value.trim(""))}
              onKeyDown={SearchOnEnter}
            />
          </div>
        </div>
        {/* <div className="col-3">
                    <div className="input">
                        <div className="input-group">
                            <select type="text" className="form-control"
                                placeholder="Search Status"
                                onChange={e => setStatus(e.target.value.trim(""))}
                                value={status}
                            // onKeyDown={SearchOnEnter}
                            >
                                <option value={''}>Select</option>
                                <option value={'Pending'}>Pending</option>
                                <option value={'Denied'}>Denied</option>
                                <option value={'Approved'}>Approved</option>
                            </select>
                        </div>
                    </div>
                </div> */}
        <div className="col-md-3">
          <CalendaRangeInput
            searchParam={saveScreenShotDateModal} setSearchParam={setSaveScreenShotDateModal} options={['Date']} label={false}
            placeholder={'Date From - Date To'}
          />
        </div>

        <div className="col-1 ">
          <button
            className="w-100 btn btn-orange no-width rounded"
            type="button"
            onClick={() => LoadData(Srchdata)}
          >
            Search
          </button>
        </div>
      </div>
      <ReactDataGrid
        columns={columns}
        rows={TrackingData}
        rowHeight={30}
        className={"fill-grid DataGrid-Table hk-exp-table daily-progress-grid"}
        style={{
          width: "100%",
          // height:'100px',
        }}
      />
      {isLoading ? null : TrackingData?.length === 0 ? (
        <div className="no_data">
          <span class="icon-ar-nodata">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span>
            <span class="path5"></span>
            <span class="path6"></span>
            <span class="path7"></span>
            <span class="path8"></span>
            <span class="path9"></span>
            <span class="path10"></span>
            <span class="path11"></span>
            <span class="path12"></span>
            <span class="path13"></span>
            <span class="path14"></span>
            <span class="path15"></span>
            <span class="path16"></span>
            <span class="path17"></span>
            <span class="path18"></span>
            <span class="path19"></span>
            <span class="path20"></span>
            <span class="path21"></span>
            <span class="path22"></span>
            <span class="path23"></span>
            <span class="path24"></span>
            <span class="path25"></span>
            <span class="path26"></span>
            <span class="path27"></span>
            <span class="path28"></span>
            <span class="path29"></span>
            <span class="path30"></span>
            <span class="path31"></span>
            <span class="path32"></span>
            <span class="path33"></span>
            <span class="path34"></span>
            <span class="path35"></span>
            <span class="path36"></span>
            <span class="path37"></span>
            <span class="path38"></span>
            <span class="path39"></span>
            <span class="path40"></span>
            <span class="path41"></span>
            <span class="path42"></span>
            <span class="path43"></span>
            <span class="path44"></span>
            <span class="path45"></span>
            <span class="path46"></span>
            <span class="path47"></span>
            <span class="path48"></span>
            <span class="path49"></span>
            <span class="path50"></span>
            <span class="path51"></span>
            <span class="path52"></span>
            <span class="path53"></span>
            <span class="path54"></span>
            <span class="path55"></span>
            <span class="path56"></span>
            <span class="path57"></span>
            <span class="path58"></span>
            <span class="path59"></span>
            <span class="path60"></span>
            <span class="path61"></span>
            <span class="path62"></span>
            <span class="path63"></span>
            <span class="path64"></span>
            <span class="path65"></span>
            <span class="path66"></span>
            <span class="path67"></span>
            <span class="path68"></span>
            <span class="path69"></span>
            <span class="path70"></span>
            <span class="path71"></span>
            <span class="path72"></span>
            <span class="path73"></span>
            <span class="path74"></span>
            <span class="path75"></span>
            <span class="path76"></span>
            <span class="path77"></span>
            <span class="path78"></span>
            <span class="path79"></span>
            <span class="path80"></span>
            <span class="path81"></span>
            <span class="path82"></span>
            <span class="path83"></span>
            <span class="path84"></span>
            <span class="path85"></span>
            <span class="path86"></span>
            <span class="path87"></span>
            <span class="path88"></span>
            <span class="path89"></span>
            <span class="path90"></span>
            <span class="path91"></span>
            <span class="path92"></span>
            <span class="path93"></span>
            <span class="path94"></span>
            <span class="path95"></span>
            <span class="path96"></span>
            <span class="path97"></span>
            <span class="path98"></span>
            <span class="path99"></span>
            <span class="path100"></span>
            <span class="path101"></span>
            <span class="path102"></span>
            <span class="path103"></span>
            <span class="path104"></span>
            <span class="path105"></span>
            <span class="path106"></span>
            <span class="path107"></span>
            <span class="path108"></span>
            <span class="path109"></span>
            <span class="path110"></span>
            <span class="path111"></span>
            <span class="path112"></span>
            <span class="path113"></span>
            <span class="path114"></span>
            <span class="path115"></span>
            <span class="path116"></span>
            <span class="path117"></span>
            <span class="path118"></span>
            <span class="path119"></span>
            <span class="path120"></span>
            <span class="path121"></span>
            <span class="path122"></span>
            <span class="path123"></span>
            <span class="path124"></span>
            <span class="path125"></span>
            <span class="path126"></span>
            <span class="path127"></span>
            <span class="path128"></span>
          </span>
          <p className="text fst-italic text-center font-m">No Data Found!</p>
        </div>
      ) : null}
    </>
  );
};

export default AllEmployeesTask;
