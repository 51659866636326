import React from 'react'
import BarChart from '../Charts/BarChart';
import NoDataFound from './NoDataFound';

const EmployementStatus = ({ data }) => {
    let seriesStraightBarData = data?.map(item => {
        return {
            ...item,
            name: parseFloat(item.PercentageEmployee).toFixed(2) + '%',
            data: [parseInt(item?.NumberOfEmployees)]
        }
    });
    return (
        <div className="col-md-5 pe-0 d-flex flex-column gap-1">
            <div className='employe_stetus card_tabs dashboard overflow-hidden'>
                <p className='heading'>Employement Status</p>
                <div className='scroll ar_hov-scrollbar'>
                    {seriesStraightBarData?.length > 0 ? <>
                        <div className=''>
                            <BarChart seriesStraightBarData={seriesStraightBarData} />
                        </div>
                        <div className='d-flex justify-content-between align-items-center px-4 pb-2 mb-1'>
                            <p className='text clr_gry font-m'>Total Employees</p>
                            <p className='text font-m'>{seriesStraightBarData?.[0]?.TotalEmployee}</p>
                        </div>
                        <div className='px-4 d-flex flex-column gap-15'>
                            {seriesStraightBarData?.map((item, index) => {
                                let colors = {Permanent :'Permanent ', Consultant: 'Consultant',PartTime :'PartTime', Contract :'Contract', Intern :'Intern'};
                                let Colors = [];
                                seriesStraightBarData.map(item => {
                                    let res = colors[item.JobStatus] || 'PartTime'
                                    Colors.push(res)
                                })
                                return (
                                    <div className='card_tabs ar-sm-card text d-flex align-items-center justify-content-between'>
                                        <p className='heading font-m d-flex align-items-center'>
                                            <span className={`box ${Colors[index]} me-1`}></span> {item?.JobStatus}</p>
                                        <div className='d-flex align-items-center '>
                                            <p className='heading font-m me-3'>{item.NumberOfEmployees}</p>
                                            <p className='heading font-m'>{item.name}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div></>
                        : <div className='position-relative' style={{height:200}}><NoDataFound/></div>}
                </div>
            </div>
            <div className='card_tabs  h-25'></div>
        </div>
    )
}

export default EmployementStatus