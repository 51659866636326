import React, { useEffect, useState } from 'react'
import ModalFooter from '../../shared/ModalFooter/ModalFooter'
import Modal from 'react-bootstrap/Modal'
import close from "../../../assetsOld/img/x-close.svg"
import CalendarInput from '../../../utils/CalendarInput';
import { enumUtil } from '../../../utils/Enum';
import { ServerRequestProtected, ServerRequestProtectedParentID, ServerRequestPublic } from '../../../utils/ServerDataRequest';
import isNull from '../../../utils/NullChecking';
import NotificationHandler from '../../../utils/NotificationHandler';
import FormValidator from '../../../utils/FormValidator';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { DateFormat, DateFormat_YMd_String, DateFormat_YMd_Strings, DateFormat_dMy } from "../../../utils/Constants"
import jwt_decode from 'jwt-decode';
import Loader from '../../shared/Loader/Loader';
import { saveAs, } from "file-saver"
import TextEditor from "../../../shared-components/TextEditor.jsx"
import DatePicker from '../../../utils/DatePicker.js';

const AddNewTask = (props) => {
 
    const { type, isOpenTask, onClose, ID, setIsRefresh, view, OnCloseSave } = { ...props }
    // let token = useSelector(state => state.TokenReducer);
    // let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    // let Token = token && jwt_decode(token)
    // let UserID = "";
    // if (!isNull(type) && type === "Employee") {
    //     UsercID = activeEmployee + "";
    // }
    // else {
    //     UserID = Token.UserID + "";
    // }
    // let OrgID = useSelector(state => state.Organization)

    let TASK_Modal = {
        UserID: "",
        EmployeeID: "",
        CreatedDate: null,
        Blockers: "",
        AttachmentPath: "",
        Name: "",
        FileName: "",

    }
    let TASK_Progress_Modal = {
        TodayPlan: "",
    }

    let valModal = {}
    let ValidationModal = {
        CreatedDate: "",
        TodayPlan: "",
    }

    const [TaskID, setTaskID] = useState(0);
    const [SaveTaskModal, setSaveTaskModal] = useState({});
    const [mainTaskModal, setmainTaskModal] = useState(TASK_Modal);
    const [SaveTaskProgressModal, setSaveTaskProgressModal] = useState({});
    const [mainTaskProgressModal, setmainTaskProgressModal] = useState(TASK_Progress_Modal);
    const [TaskValidationModal, setTaskValidationModal] = useState(ValidationModal)
    const [isLoading, setIsLoading] = useState(false);
    const [position, setPosition] = useState(null);
    const [file, setFile] = useState([])
    const [fileName, setFileName] = useState('')
    const API_URL = process.env.REACT_APP_API;

    const handleTaskChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainTaskModal };
        let saveModal = { ...SaveTaskModal }
       
        modal[name] = value;
        setmainTaskModal(modal);
        for (let property in TASK_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setSaveTaskModal(saveModal)
    }
    const handleTaskProgressChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainTaskProgressModal };
        let saveModal = { ...SaveTaskProgressModal }
       
        modal[name] = value;
        setmainTaskProgressModal(modal);
        for (let property in TASK_Progress_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setSaveTaskProgressModal(saveModal)
    }

    const TaskValidation = async () => {
        let ValidationModal = {
            CreatedDate: FormValidator(
                mainTaskModal.CreatedDate,
                enumUtil.enumValidationType.Required,
                'Created Date',
            ),
            TodayPlan: FormValidator(
                mainTaskProgressModal.TodayPlan,
                enumUtil.enumValidationType.Required,
                'Today Plan',
            ),
        }
        await setTaskValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    // const handleSaveTaskkk = async (e) => {
    //     await TaskValidation()
    //     let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
    //     if (validation) {
    //         NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
    //         return
    //     } else {
    //         // SaveTaskModal["OrganizationID"] = OrgID
    //         SaveTaskModal["EmployeeID"] = UserID
    //         let saveModal = {
    //             TableName: enumUtil.enumAPITableName.DAILYPROGRESS,
    //             ID: TaskID,
    //             // UserID: UserID,
    //             data: { ...SaveTaskModal },
    //             value: null
    //         }
    //         setIsLoading(true)
    //         ServerRequestProtected(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
    //             if (!isNull(response.id)) {
    //                 setIsLoading(false)
    //                 setTaskID(response?.id)
    //                 NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
    //                 setIsRefresh(true)
    //             }
    //         }).catch((error) => {
    //             setIsLoading(false)
    //             setIsRefresh(false)
    //             NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         })
    //     }
    // }

    const handleCalendarChange = (date, name) => {

        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, "MM/dd/yyyy")
        let modal = { ...mainTaskModal };
        let saveModal = { ...SaveTaskModal };
        modal[name] = FormatedDateShow;
        setmainTaskModal(modal)
        for (var property in TASK_Modal) {
            if (name === property) {
                saveModal[name] = FormatedDateSave
            }
        }
        setSaveTaskModal(saveModal)
        closeDateRangePickerMenu()
    }

    const openDateRangePicker = Boolean(position);

    const openDateRangePickerMenu = (event) => {
        setPosition(event.currentTarget);
    };

    const closeDateRangePickerMenu = () => {
        setPosition(null);
    };

    // let maxIndex = 0;
    const handleSavefile = (e) => {
        const file = e.target.files[0];
        setFile(file);
        // let fileLengths = allFiles.length;
        // for (let i; i < fileLengths; i++) {
        //     let tempObj = {
        //         file: e.target.files[i],
        //         key: maxIndex + 1,
        //     }
        //     maxIndex = maxIndex + 1;
        //     file.push(tempObj);
        // }
    }

    useEffect(() => {
        if (!isNull(ID)) {
            setTaskID(ID)
            setIsLoading(true);
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.DAILYPROGRESS,
            }).then((response) => {
                setIsLoading(false);
                setmainTaskModal(response);
                setmainTaskProgressModal(response);
                setFileName(response?.FileName);
            }).catch((error) => {
                setIsLoading(false)
            })
        }
    }, [ID])

    const handleCopy = (e) => {
        e.preventDefault();
    };

    // const downloadFile = (filepath, filename) => {
    //     setIsLoading(true)
    //     var postData = {
    //         "BucketPath": filepath
    //     };
    //     const API_URL = 'https://emsbackend.theeasyedi.com/wasabi/DownloadFile'
    //     ServerRequestPublic(API_URL, 'post', postData, "Download")
    //         .then((res) => {
    //             console.log("Download",res)
    //             let blob = new Blob([res]);
    //             saveAs(blob, filename);
    //             setIsLoading(false)
    //         })
    //         .catch((error) => {
    //             setIsLoading(false)
    //         })
    // }

    const handleSaveTask = async (e) => {
        await TaskValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setIsLoading(true);
            let data = {
                TodayPlan: mainTaskProgressModal.TodayPlan,
                Blockers: mainTaskModal.Blockers,
                CreatedDate: DateFormat_YMd_Strings(mainTaskModal.CreatedDate),
                
            }
            const formdata = new FormData()
            formdata.append("TableName", enumUtil.enumAPITableName.DAILYPROGRESS)
            formdata.append("ID", TaskID);
            if(mainTaskModal.Blockers != 'NO'){
                formdata.append('Files', file);
                data = {...data, FileName: fileName}
            } else {
                formdata.append('Files', "");
                data = {...data, FileName: ""}
            }
            
            for (var key in data) {
                formdata.append(`data[${key}]`, data[key]);
            }
            ServerRequestPublic(API_URL + "/SaveDailyProgress", 'post', formdata, 'Upload',)
                .then((response) => {
                    setIsLoading(false);
                    // setIsRefresh(true);
                    OnCloseSave()
                    setTaskID(response?.id)
                    while (file.length > 0) {
                        file.pop();
                    }
                    // onClose()
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                }).catch((error) => {
                    setIsLoading(false);
                    setIsRefresh(false);
                    NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);

                })
        }
    }
    
    return (
        <div>
            <Modal show={isOpenTask} onHide={isOpenTask} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal add_prog">
                <Modal.Body>
                    {isLoading ? <Loader /> : <></>}
                    <div className='d-flex justify-content-end'>
                        <img className="close" src={close} onClick={() => onClose()}></img>
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <div className='icon_bg'>
                                    <i className="icon-Vector-5"></i>
                                </div>
                            </span>
                            <p class="heading mt-2">Add Daily Progress</p>
                        </div>
                    </div>
                    <div>
               
                        <div className={`form-group input ${!isNull(ID) ? 'ar_disable-popup' : ''} ${TaskValidationModal.CreatedDate ? 'error' : ''} `}>
                            
                            <DatePicker
                                label={<span>Created Date<span className="req"> *</span></span>}
                                value={mainTaskModal?.CreatedDate}
                                openCalendarMenufunc={(event) => openDateRangePickerMenu(event, 'CreatedDate')}
                                maxDate={new Date()}
                                anchorEl={position}
                                open={openDateRangePicker}
                                closeCalendarMenufunc={closeDateRangePickerMenu}
                                handleCalendarChangefunc={date => handleCalendarChange(date, 'CreatedDate')}
                                placeholder={true}
                            />
                            {TaskValidationModal.CreatedDate}
                        </div>
                        <div className={`form-group input hk-EmailFormat-box position-relative ${TaskValidationModal.TodayPlan ? 'error' : ''}`}>
                            <label>  Today’s Progress<span className="req">*</span></label>
                            {/* <textarea rows="7" type="text" className="form-control" name="TodayPlan" id="TodayPlan" maxlength="" onChange={handleTaskChange} value={mainTaskModal.TodayPlan} >

                            </textarea> */}
                            {console.log("mainTaskProgressModal?.TodayPlan",mainTaskProgressModal?.TodayPlan)}
                            <div className="hk-EmailFormat-Editor">
                                <TextEditor value={mainTaskProgressModal?.TodayPlan} setValue={(value) => handleTaskProgressChange({ target: { name: "TodayPlan", value } })} />
                            </div>
                            {TaskValidationModal.TodayPlan}
                        </div>
                        {/* <div className={`form-group input ${TaskValidationModal.YesterdayProgress ? 'error' : ''}`}>
                            <label>  Yesterday’s Plan <span className="req">*</span></label>
                            <input type="text" className="form-control" name="YesterdayProgress" id="YesterdayProgress" maxlength="" onChange={handleTaskChange} value={mainTaskModal.YesterdayProgress} />
                            {TaskValidationModal.YesterdayProgress}
                        </div> */}
                        <div className="form-group input">
                            <label> Blockers </label>
                            <select className="form-control" name="Blockers" id="Blockers" value={mainTaskModal.Blockers} onChange={handleTaskChange}>
                                <option value={""}></option>
                                <option value={"NO"}>No</option>
                                <option value={"Yes"}>Yes</option>
                            </select>
                        </div>
                        {mainTaskModal.Blockers === 'Yes' ?
                            <div className="form-group input position-relative">
                                <label >Attach File<span className='req'>*</span></label>
                                <div className="form-group input upload-file">
                                    <label className='form-control d-flex align-items-center'>
                                        <input type="file"
                                            className="form-control ps-4"
                                            name="File"
                                            id="File"
                                            onChange={(e) => {
                                                handleSavefile(e)
                                                setFileName(e.target.files[0]?.name)
                                            }}
                                            accept=" .gif, .jpg, .pdf,.png "

                                        />
                                        {!isNull(fileName) ? <span className='office_name_truncate'>{fileName}</span> : <label htmlFor="file" className='pe-none'><i class="icon-link"></i>{"Upload a File"}</label>}
                                    </label>
                                </div>
                            </div> : ""

                        }
                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveTask} />
            </Modal>
        </div>
    )
}

export default AddNewTask
