import plusIcon from "../../../../assetsOld/img/plus_icon.png";
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import close from "../../../../assetsOld/img/x-close.svg";
import logo from "../../../../assetsOld/img/logo.svg";
import googleplay from "../../../../assetsOld/img/Google Play.svg";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import AddNewLunchAndDinner from "./AddNewLunchAndDinner";
import CalendarInput from "../../../../utils/CalendarInput";
import ModalFooter from "../../../shared/ModalFooter/ModalFooter";

// import LeaveView from "../../../MyAttendance/Tab Views/LeaveView";
// import TimeAdjustment from "../../../MyAttendance/TimeAdjustment/TimeAdjustment";
// import close from "../../../../assetsOld/img/x-close.svg"
const LunchAndDinner = (props) => {
  const [isOpenLunchAndDinner, setIsOpenLunchAndDinner] = useState(false);
  const [activeTab, setActiveTab] = useState("leaves");
  const [activeTab2, setActiveTab2] = useState("allRequests");
  const [activeTab3, setActiveTab3] = useState("myreq");
  const [activeTab4, setActiveTab4] = useState("allreqa");
  const [adddelete1, setpopdelete] = useState(false);
  const [adddelete2, setpopdelete1] = useState(false);
  const [adddelete3, setpopdelete2] = useState(false);
  const [isLeaveOpen, setIsLeaveOpen] = useState(false);
  const [isLeaveOpen1, setIsLeaveOpen1] = useState(false);
  const { isOpenShift, onClose, setIsRefresh } = { ...props };

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  const handleTabSelect2 = (eventKey) => {
    setActiveTab2(eventKey);
  };
  const handleTabSelect3 = (eventKey) => {
    setActiveTab3(eventKey);
  };
  const handleTabSelect4 = (eventKey) => {
    setActiveTab4(eventKey);
  };
  const AddLunchAndDinner = () => {
    setIsOpenLunchAndDinner(true);
  };

  const CloseLunchAndDinner = () => {
    setIsOpenLunchAndDinner(false);
  };
  const handleSaveShift = () => { };

  let PopupScreen = "";
  if (isOpenLunchAndDinner) {
    PopupScreen = (
      <AddNewLunchAndDinner
        isOpenLunchAndDinner={isOpenLunchAndDinner}
        onClose={CloseLunchAndDinner}
      />
    );
  }
  return (
    <>
      <div className="lunch-dinner">
        <button
          className="btn btn-orange btn-xs no-width rounded mb-0 master_list mx-5 mt-5"
          onClick={() => setpopdelete1(true)}
        >
          Announcement
        </button>
        <button
          className="btn btn-orange btn-xs no-width rounded mb-0 master_list mx-5 mt-5"
          onClick={() => setpopdelete2(true)}
        >
          Add New Policy
        </button>
        <div className="img-container m-auto s-77 circle">
          <p className="align-items-center d-flex h-100 heading justify-content-center w-100 fs-24 text-white male">
            HS
          </p>
        </div>
        <Modal
          show={adddelete2}
          onHide={adddelete2}
          backdrop="static"
          keyboard={false}
          centered
          dialogClassName="delete-modal hk-modal-v1 ems_modal add_new_announcement"
        >
          <ModalBody>
            <div className="d-flex justify-content-end">
              <i
                className="icon-Vector7"
                onClick={() => setpopdelete1(false)}
              ></i>
            </div>
            <div className="modal_header">
              <div className="text-center">
                <span className="icon">
                  <i className="icon-Group-1 fs-22"></i>
                </span>
                <p class="heading mt-2 mb-3">Add Announcement</p>
              </div>
            </div>
            <div className="d-flex flex-column gap-15">
              <div className="form-group input">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="Address"
                  id="Address"
                  maxLength={250}
                ></input>
              </div>
              <div>
                <p className="text clr_gry">Send To</p>
                <div className="d-flex">
                  <div className="col-md-6">
                    <div className="ar_radio-btn d-flex gap-1 align-items-center">
                      <input type="radio" />
                      <p className="text clr_gry">All Organization Users</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex gap-1 align-items-center">
                      <div className="ar_radio-btn">
                        <input type="radio" />
                      </div>
                      <p className="text clr_gr">Departments</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-md-6">
                    <div className="ar_radio-btn d-flex gap-1 align-items-center">
                      <input type="radio" />
                      <p className="text clr_gry">Shifts</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ar_radio-btn d-flex gap-1 align-items-center">
                      <input type="radio" />
                      <p className="text clr_gry">Teams</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pe-0">
                  <p className="text clr_gry">Start Date</p>
                  <CalendarInput />
                </div>
                <div className="col-md-6">
                  <p className="text clr_gry">End Date</p>
                  <CalendarInput />
                </div>
              </div>
              <div className="form-group input">
                <label>Attachement</label>
                <input
                  type="text"
                  className="form-control"
                  name="Address"
                  id="Address"
                  maxLength={250}
                ></input>
              </div>
            </div>
          </ModalBody>
          <ModalFooter isSaved={onClose} handleSave={handleSaveShift} />
        </Modal>
        <Modal
          show={adddelete3}
          onHide={adddelete3}
          backdrop="static"
          keyboard={false}
          centered
          dialogClassName="delete-modal hk-modal-v1 ems_modal add_new-policy "
        >
          <ModalBody>
            <div className="d-flex justify-content-end">
              <i
                className="icon-Vector7"
                onClick={() => setpopdelete2(false)}
              ></i>
            </div>
            <div className="modal_header">
              <div className="text-center">
                <span className="icon">
                  <i className="icon-Group-1 fs-22"></i>
                </span>
                <p class="heading mt-2 mb-3">Add New Policy</p>
              </div>
            </div>
            <Tab.Container defaultActiveKey="superAdmin">
              <div className="d-flex mt-1 mb-4">
                <Nav
                  variant="tabs"
                  className="d-flex border-0 gx-0 justify-content-between w-100"
                >
                  <Nav.Item>
                    <Nav.Link
                      eventKey="superAdmin"
                      className="text clr_gry fs-11 d-flex align-content-center gap-2 position-relative"
                    >
                      {" "}
                      <p className="align-items-center clr_gry d-flex fs-10 justify-content-center number heading">
                        1
                      </p>
                      Add Leave Type <p className="ar-br-right-1 mb-0"></p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="admin"
                      className="text clr_gry fs-11 d-flex align-content-center gap-2 position-relative"
                    >
                      {" "}
                      <p className="align-items-center clr_gry d-flex fs-10 justify-content-center number heading">
                        2
                      </p>
                      Set Policy <p className="ar-br-right-1 mb-0 pe-none"></p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="user"
                      className="text clr_gry fs-11 d-flex align-content-center gap-2"
                    >
                      {" "}
                      <p className="align-items-center clr_gry d-flex fs-10 justify-content-center number heading">
                        3
                      </p>
                      Advanced
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="superAdmin">
                  <div className="d-flex flex-column gap-15">
                    <div className="form-group input">
                      <label>Leave Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Address"
                        id="Address"
                        maxLength={250}
                      ></input>
                    </div>
                    <div className={`form-group input ps-0`}>
                      <label>Allocation Frequency</label>
                      <select
                        className="form-control fc-xs"
                        name="Nationality"
                        id="Nationality"
                        aria-label="Default select example"
                      >
                        <option selected>Yearly</option>
                      </select>
                    </div>
                    <div className="form-group input">
                      <label htmlFor="HolidayDescription">
                        Leave Description
                      </label>
                      <textarea
                        name="HolidayDescription"
                        id="HolidayDescription"
                        className="form-control"
                        cols="30"
                        rows="3"
                      // placeholder="Enter Reason for leave"
                      ></textarea>
                    </div>
                    <div className="d-flex gap-1 align-items-center">
                      <div className="hk-table-input custom_check_box d-flex align-items-center">
                        <input type="checkbox" className="mt-0" />
                      </div>
                      <p className="text clr_gry">has subtypes</p>
                    </div>
                    <div className="row m-0">
                      <div className="col-md-8 ps-0">
                        <div className="form-group input">
                          <label>Subtype 1</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Address"
                            id="Address"
                            maxLength={250}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-4 ps-0">
                        <p className="text clr_gry mb-2">Units</p>
                        <div className="d-flex align-items-center gap-2">
                          <div className="ar_radio-btn d-flex align-items-center gap-1">
                            <input type="radio" />
                            <p className="text">Days</p>
                          </div>
                          <div className="ar_radio-btn d-flex align-items-center gap-1">
                            <input type="radio" />
                            <p className="text">Hours</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-md-8 ps-0">
                        <div className="form-group input">
                          <label>Subtype 1</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Address"
                            id="Address"
                            maxLength={250}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-4 ps-0">
                        <p className="text clr_gry mb-2">Units</p>
                        <div className="d-flex align-items-center gap-2">
                          <div className="ar_radio-btn d-flex align-items-center gap-1">
                            <input type="radio" />
                            <p className="text">Days</p>
                          </div>
                          <div className="ar_radio-btn d-flex align-items-center gap-1">
                            <input type="radio" />
                            <p className="text">Hours</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-md-8 ps-0">
                        <div className="form-group input">
                          <label>Subtype 1</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Address"
                            id="Address"
                            maxLength={250}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-4 ps-0">
                        <p className="text clr_gry mb-1">Units</p>
                        <div className="d-flex align-items-center gap-2">
                          <div className="ar_radio-btn d-flex align-items-center gap-1">
                            <input type="radio" />
                            <p className="text">Days</p>
                          </div>
                          <div className="ar_radio-btn d-flex align-items-center gap-1">
                            <input type="radio" />
                            <p className="text">Hours</p>
                            <i className="icon-plus fs-22"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="admin">
                  <div className="d-flex flex-column gap-15">
                    <div className="form-group input">
                      <label>Policy Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Address"
                        id="Address"
                        maxLength={250}
                      ></input>
                    </div>
                    <div className="form-group input">
                      <label>Policy Period</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Address"
                        id="Address"
                        maxLength={250}
                      ></input>
                    </div>
                    <p className="heading">Sub Types</p>
                    <div className="row m-0">
                      <div className="col-md-6 ps-0">
                        <p className="text ar_right-input d-flex align-items-center">
                          Full Day
                        </p>
                      </div>
                      <div className="col-md-6 px-0">
                        <div className="form-group input">
                          <input
                            type="text"
                            className="form-control"
                            name="Address"
                            id="Address"
                            maxLength={250}
                            placeholder="Allowed Days"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-md-6 ps-0">
                        <p className="text ar_right-input d-flex align-items-center">
                          Half Day
                        </p>
                      </div>
                      <div className="col-md-6 px-0">
                        <div className="row m-0">
                          <div className="col-md-8 ps-0">
                            <div className="form-group input">
                              <input
                                type="text"
                                className="form-control"
                                name="Address"
                                id="Address"
                                maxLength={250}
                                placeholder="Allowed Days"
                              ></input>
                            </div>
                          </div>
                          <div className="col-md-4 px-0">
                            <div className="form-group input">
                              <input
                                type="text"
                                className="form-control"
                                name="Address"
                                id="Address"
                                maxLength={250}
                                placeholder="Qty"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-md-6 ps-0">
                        <p className="text ar_right-input d-flex align-items-center">
                          Short Leaves
                        </p>
                      </div>
                      <div className="col-md-6 px-0">
                        <div className="row m-0">
                          <div className="col-md-8 ps-0">
                            <div className="form-group input">
                              <input
                                type="text"
                                className="form-control"
                                name="Address"
                                id="Address"
                                maxLength={250}
                                placeholder="Allowed Days"
                              ></input>
                            </div>
                          </div>
                          <div className="col-md-4 px-0">
                            <div className="form-group input">
                              <input
                                type="text"
                                className="form-control"
                                name="Address"
                                id="Address"
                                maxLength={250}
                                placeholder="Qty"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-md-7 ps-0">
                        <div className="d-flex  align-items-center gap-15">
                          <p className="heading">Effective After</p>
                          <div className={`form-group input ps-0`}>
                            <select
                              className="form-control fc-xs"
                              name="Nationality"
                              id="Nationality"
                              aria-label="Default select example"
                            >
                              <option selected> 3 Month(s)</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 px-0 ">
                        <div className="d-flex gap-2 align-items-center justify-content-end">
                          <p className="text clr_gry">From</p>
                          <div className={`form-group input ps-0`}>
                            <select
                              className="form-control fc-xs"
                              name="Nationality"
                              id="Nationality"
                              aria-label="Default select example"
                            >
                              <option selected> Date of Joining</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <p className="heading fs-11 mb-2">
                        Who can approve leaves to employees:
                      </p>
                      <div className="d-flex align-items-center gap-10">
                        <div className="d-flex gap-1 align-items-center">
                          <div className="hk-table-input custom_check_box d-flex align-items-center">
                            <input type="checkbox" className="mt-0" />
                          </div>
                          <p className="text clr_gry">Admin</p>
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                          <div className="hk-table-input custom_check_box d-flex align-items-center">
                            <input type="checkbox" className="mt-0" />
                          </div>
                          <p className="text clr_gry">Team Lead</p>
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                          <div className="hk-table-input custom_check_box d-flex align-items-center">
                            <input type="checkbox" className="mt-0" />
                          </div>
                          <p className="text clr_gry">HR</p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <p className="heading fs-11 mb-2">
                        Employees can apply beyond the current leave balance:
                      </p>
                      <div className="d-flex align-items-center gap-10">
                        <div className="ar_radio-btn d-flex gap-1 align-items-center">
                          <input type="radio" />
                          <p className="text clr_gry">Yes</p>
                        </div>
                        <div className="ar_radio-btn d-flex gap-1 align-items-center">
                          <input type="radio" />
                          <p className="text clr_gry">No</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <p className="heading">Allowed Leaves Combination</p>
                      <div className="ar_radio-btn d-flex gap-1 align-items-center">
                        <input type="radio" />
                        <p className="text clr_gry">
                          1 Full Day OR 2 Half Days OR 4 Short Leaves per Month
                        </p>
                      </div>
                      <div className="ar_radio-btn d-flex gap-1 align-items-center">
                        <input type="radio" />
                        <p className="text clr_gry">
                          1 Half Day 2 Short Leaves OR per Month
                        </p>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="user">
                  <div className="d-flex flex-column gap-15">
                    <div className={`form-group input ps-0`}>
                      <label>Leave Notice Period</label>
                      <select
                        className="form-control fc-xs"
                        name="Nationality"
                        id="Nationality"
                        aria-label="Default select example"
                      >
                        <option selected></option>
                      </select>
                    </div>
                    <div className={`form-group input ps-0`}>
                      <label>Send Email Notifications</label>
                      <select
                        className="form-control fc-xs"
                        name="Nationality"
                        id="Nationality"
                        aria-label="Default select example"
                      >
                        <option selected></option>
                      </select>
                    </div>
                    <div className={`form-group input ps-0`}>
                      <label>Leave Carried Forward</label>
                      <select
                        className="form-control fc-xs"
                        name="Nationality"
                        id="Nationality"
                        aria-label="Default select example"
                      >
                        <option selected></option>
                      </select>
                    </div>
                    <div className="form-group input">
                      <label>Percentage of Leave Carried Forward </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Address"
                        id="Address"
                        maxLength={250}
                      ></input>
                    </div>
                    <div className={`form-group input ps-0`}>
                      <label>Availability Period</label>
                      <select
                        className="form-control fc-xs"
                        name="Nationality"
                        id="Nationality"
                        aria-label="Default select example"
                      >
                        <option selected></option>
                      </select>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </ModalBody>
          <ModalFooter isSaved={onClose} handleSave={handleSaveShift} />
        </Modal>
        <div className="d-flex justify-content-center align-items-center">
          <div className="ar-email-varification">
            <div className="ar_header d-flex flex-column">
              <div className="text-center">
                <img src={logo} />
              </div>
              <div className="text-center">
                <p className="text fs-18">Hi ISRAH,</p>
                <p className="text fs-18">
                  {" "}
                  Your Request for Email Verification has been received.
                </p>
              </div>
              <div className="line mx-auto"></div>
            </div>
            <div className="content">
              <div className="text-center">
                <p className="text fs-16 pb-3">
                  Your Email has been verified and you can now log in.
                </p>

                <div className="pb-5">
                  <p className="text fs-16">
                    You can access EMS online or on any device by going to
                  </p>
                  <p className="text fs-14 blue-text cursor-pointer">
                    https://ems.starhealthcaretech.com/#/ems
                  </p>
                </div>
                <div className="line mt-1 mb-3 mx-auto"></div>
                <p className="text fs-24 pb-3">Get the EMS app!</p>
                <p className="text fs-16 pb-3">
                  Get the most of EMS by installing the mobile app. You can log
                  in by using your existing emails address and password.
                </p>
                <div className="cursor-pointer">
                  <img src={googleplay} />
                </div>
              </div>
            </div>
            <div className="ar-footer text-center">
              <div className="d-flex align-items-center justify-content-center gap-25 pb-3">
                <i className="icon-twiter fs-24"></i>
                <i className="icon-facebook fs-24"></i>
                <i className="icon-linkin fs-24"></i>
              </div>
              <p className="text fs-24 f-family">ems</p>
              <p className="text fs-14 f-family pb-3">A platform where we value everyone!</p>
              <p className="text f2-family">Copyright © 2020</p>
            </div>
          </div>
        </div>
        {/* ..... */}
        <div className="d-flex gap-10 align-items-center">
          <div className="za-annual-leave">

            <div className="text-center mb-2">
              <p className="text font-m">Annual Leaves</p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-center">
                <p className="heading fs-15">12</p>
                <p className="text fs-10">Leave Balance</p>
              </div>
              <div className="text-center">
                <p className="heading fs-15">28</p>
                <p className="text fs-10">Leave Used</p>
              </div>
            </div>

          </div>
          <div className="za-casual-leave">

            <div className="d-flex justify-content-between align-items-center za-header pb-1 mb-1 ">

              <div className="col-md-5">
              <p className="heading fs-10 w-100">Casual Leaves</p>
              </div>

              <div className="col-md-2">
              <p className="heading fs-10 w-100">Bal</p>
              </div>

              <div className="col-md-5">
              <p className="heading fs-10 w-100">Allowed days/hours</p>
              </div>
              
            </div>
            <div className="d-flex align-items-center ">
              <div className="col-md-5 ">
                <div className="d-flex flex-column gap-2 w-100">
                  <p className="text fs-10">Full Day</p>
                  <p className="text fs-10">Half Day</p>
                  <p className="text fs-10">Short Day</p>
                </div>
              </div>
              <div className="col-md-2 ">
                <div className="d-flex flex-column gap-2 w-100">
                  <p className="text fs-10">1</p>
                  <p className="text fs-10">2</p>
                  <p className="text fs-10">4</p>
                </div>


              </div>
              <div className="col-md-5">
                <div className="d-flex flex-column gap-2 w-100">
                  <p className="text fs-10">1 day</p>
                  <p className="text fs-10">4 hours</p>
                  <p className="text fs-10">2 hours</p>
                </div>
              </div>
            </div>
          </div>
        </div>






        {/* ..... */}


      </div>
    </>
  );
};

export default LunchAndDinner;
