import React, { useEffect, useState } from 'react'
import Nav from "react-bootstrap/Nav";
import Modal from 'react-bootstrap/Modal'
import close from "../../../assetsOld/img/x-close.svg"
import EmployeeInformation from '../../MyInfo/Account/EmployeeInformation/EmployeeInformation'
import ModalFooter from "../../shared/ModalFooter/ModalFooter";
import CalendarInput from '../../../utils/CalendarInput';
import { enumUtil } from '../../../utils/Enum';
import NotificationHandler from '../../../utils/NotificationHandler';
import { ServerRequestProtected } from '../../../utils/ServerDataRequest';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import DeleteConfirmation from '../../shared/Delete/DeleteConfirmation';
import AddNewTask from './AddNewTask';
import Loader from '../../shared/Loader/Loader';
import { format } from 'date-fns';
import { DateFormat, DateFormat_dMy } from "../../../utils/Constants"
import ScreenShot from './ScreenShot';
import OverAllSessionDetails from './OverAllSessionDetails';
import isNull from '../../../utils/NullChecking';
import DOMPurify from 'dompurify';
import AddTaskNew from './AddTask';

const TodayProgress = (props) => {
    const { type, setIsRefresh, isRefresh } = { ...props }
    let currentDay = format(new Date(), DateFormat);
    const API_URL = process.env.REACT_APP_API

    let token = useSelector(state => state.TokenReducer);
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let Token = token && jwt_decode(token)
    let UserID = "";
    if (!isNull(type) && type === "Employee") {
        UserID = activeEmployee + "";
    }
    //  else {
    //     UserID = Token.UserID + "";
    // }

    const [activeTab2, setActiveTab2] = useState("todayProgress");
    const [addTodayTask, setAddTodayTask] = useState(false);
    const handleTabSelect2 = (eventKey) => {
        setActiveTab2(eventKey);
    }

    const [TaskID, setTaskID] = useState();
    // const [isRefresh, setIsRefresh] = useState(0);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [delID, setDelID] = useState(0);
    const [isOpenTask, setIsOpenTask] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [TrackingData, setTrackingData] = useState([]);
    const [ScreenShotData, setScreenShotData] = useState({});

    const LoadData = () => {
        setTrackingData([]);
        setIsLoading(true);
        let saveModal = {
            "TableName": enumUtil.enumAPITableName.DAILYPROGRESS,
            "SearchCriteria": {
                "EmployeeID": UserID + "",
                "DateFrom": currentDay,
                "DateTo": currentDay,
            }
        }
        ServerRequestProtected(API_URL + "/FindByMultipleCriteria", "post", saveModal).then((response) => {
            setIsLoading(false);
            setTrackingData(response);
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })

    }
    const LoadScreenShotData = () => {
        setScreenShotData([]);
        setIsLoading(true);
        let saveModal = {
            "TableName": enumUtil.enumAPITableName.ActivitySession,
            "SearchCriteria": {
                "Datefrom": currentDay,
                "Dateto": currentDay,
                "Search": "no",
                "Session": "Current",
                "EmployeeID": UserID + "",
            }
        }
        ServerRequestProtected(API_URL + "/LoadSessionDetails", "post", saveModal).then((response) => {
            setIsLoading(false);
            setScreenShotData(response);
            setIsRefresh(false)
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
        }).catch((error) => {
            setIsLoading(false);
            setIsRefresh(false)
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })

    }

    const HtmlContent = ({ html }) => {
        const sanitizedHtml = DOMPurify.sanitize(html);

        return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
    };

    const handleDeleteModal = (ID) => {
        setDelID(ID)
        setIsOpenAlert(true)
    }
    const onCloseDelete = () => {
        // setDateValidation({StartDate:'',EndDate:''})
        // setmainEducationModal(Education_Modal)
        setTaskID(0)
        setIsOpenAlert(false)
    }

    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.DAILYPROGRESS} ID={delID} onClose={onClose} />
    }
    const AddTask = (ID) => {
        setIsOpenTask(true)
        setTaskID(ID)
    }

    const CloseTask = () => {
        setIsOpenTask(false)
    }
    const CloseTaskOnSave = () => {
        setIsOpenTask(false);
        LoadData()
    }

    let PopupScreen = "";
    if (isOpenTask) {
        PopupScreen = <AddNewTask isOpenTask={isOpenTask} onClose={CloseTask} OnCloseSave={CloseTaskOnSave} ID={TaskID} setIsRefresh={setIsRefresh} type={type} />
    }

    useEffect(() => {

        LoadData()
        LoadScreenShotData()

    }, [isRefresh])
    return (
        <div className='ar_daily_tracking_screnshot e'>
            <>
                {PopupScreen}
                {ConfirmDelete}
                {isLoading ? <Loader /> : <></>}
                <div className="d-flex m-2 ms-3 justify-content-between align-items-center">
                    <p className="heading">Today’s Progress</p>
                    {!type && <button className="btn px-1 btn-blue rounded" onClick={() => AddTask(0)}>Add Daily Progress</button>}
                </div>
                <div className="today_task">
                    {TrackingData?.map((item) => {
                        return (
                            <div className="card_section ms-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="gry_heading">{item?.Date}</p>
                                    {/* <p className="clr_gry text sm">3/5/3034</p> */}
                                </div>
                                <div className="text-center mt-2 cursor-pointer" onClick={() => AddTask(item?.DailyProgressID)}>
                                    <p className="text"><i className="icon-edit-pencil me-1"></i>Edit</p>
                                </div>
                                <div className="d-flex  justify-content-between align-items-center">
                                </div>
                                <p className="text my-2">{HtmlContent({ html: item?.TodayPlan })}</p>
                                <div className="d-flex  justify-content-between align-items-center">
                                </div>
                                <div className="blocker d-flex justify-content-between align-items-center">
                                    <p className="heading">Blockers</p>
                                    <p className="text">{item?.Blockers}</p>
                                    {/* <p className="text"><i className="icon-Icon-metro-attachment me-1"></i>2</p> */}
                                </div>
                            </div>
                        )
                    })}

                    <div className={`ar_today_nodata position-relative ${Object.keys(ScreenShotData)?.length != 0 ?'':'ar-height'}`}>
                        <p className='heading mt-3 ms-3'>Today’s  Screenshots</p>
                        {Object.keys(ScreenShotData)?.length != 0 ?
                            <>
                                <OverAllSessionDetails SearchType={"Current"} Date={currentDay} type={type} />
                                <div className='mt-3 ms-3 me-3'>
                                    {ScreenShotData?.Session?.length != 0 && ScreenShotData?.Session?.map((item) => {
                                        return (
                                            <>
                                                <p className='gry_heading font-m'><span className='active_time'></span>{item?.StartTime + " - " + item?.EndTime} <span className='text sm clr_gry ms-1'>{item?.SessionTime + " logged time "}</span></p>
                                                <div className='ms-3 overflow-x-auto mt-3 d-flex pb-1 gap-3'>
                                                    {item?.Screenshot?.length != 0 && item?.Screenshot?.map((items) => {
                                                        return (
                                                            <ScreenShot items={items} LoadData={LoadScreenShotData} />
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </>
                            :

                            isLoading ? null : Object.keys(ScreenShotData).length === 0 ? (
                                <div className="no_data">
                                    <span class="icon-ar-nodata">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                        <span class="path3"></span>
                                        <span class="path4"></span>
                                        <span class="path5"></span>
                                        <span class="path6"></span>
                                        <span class="path7"></span>
                                        <span class="path8"></span>
                                        <span class="path9"></span>
                                        <span class="path10"></span>
                                        <span class="path11"></span>
                                        <span class="path12"></span>
                                        <span class="path13"></span>
                                        <span class="path14"></span>
                                        <span class="path15"></span>
                                        <span class="path16"></span>
                                        <span class="path17"></span>
                                        <span class="path18"></span>
                                        <span class="path19"></span>
                                        <span class="path20"></span>
                                        <span class="path21"></span>
                                        <span class="path22"></span>
                                        <span class="path23"></span>
                                        <span class="path24"></span>
                                        <span class="path25"></span>
                                        <span class="path26"></span>
                                        <span class="path27"></span>
                                        <span class="path28"></span>
                                        <span class="path29"></span>
                                        <span class="path30"></span>
                                        <span class="path31"></span>
                                        <span class="path32"></span>
                                        <span class="path33"></span>
                                        <span class="path34"></span>
                                        <span class="path35"></span>
                                        <span class="path36"></span>
                                        <span class="path37"></span>
                                        <span class="path38"></span>
                                        <span class="path39"></span>
                                        <span class="path40"></span>
                                        <span class="path41"></span>
                                        <span class="path42"></span>
                                        <span class="path43"></span>
                                        <span class="path44"></span>
                                        <span class="path45"></span>
                                        <span class="path46"></span>
                                        <span class="path47"></span>
                                        <span class="path48"></span>
                                        <span class="path49"></span>
                                        <span class="path50"></span>
                                        <span class="path51"></span>
                                        <span class="path52"></span>
                                        <span class="path53"></span>
                                        <span class="path54"></span>
                                        <span class="path55"></span>
                                        <span class="path56"></span>
                                        <span class="path57"></span>
                                        <span class="path58"></span>
                                        <span class="path59"></span>
                                        <span class="path60"></span>
                                        <span class="path61"></span>
                                        <span class="path62"></span>
                                        <span class="path63"></span>
                                        <span class="path64"></span>
                                        <span class="path65"></span>
                                        <span class="path66"></span>
                                        <span class="path67"></span>
                                        <span class="path68"></span>
                                        <span class="path69"></span>
                                        <span class="path70"></span>
                                        <span class="path71"></span>
                                        <span class="path72"></span>
                                        <span class="path73"></span>
                                        <span class="path74"></span>
                                        <span class="path75"></span>
                                        <span class="path76"></span>
                                        <span class="path77"></span>
                                        <span class="path78"></span>
                                        <span class="path79"></span>
                                        <span class="path80"></span>
                                        <span class="path81"></span>
                                        <span class="path82"></span>
                                        <span class="path83"></span>
                                        <span class="path84"></span>
                                        <span class="path85"></span>
                                        <span class="path86"></span>
                                        <span class="path87"></span>
                                        <span class="path88"></span>
                                        <span class="path89"></span>
                                        <span class="path90"></span>
                                        <span class="path91"></span>
                                        <span class="path92"></span>
                                        <span class="path93"></span>
                                        <span class="path94"></span>
                                        <span class="path95"></span>
                                        <span class="path96"></span>
                                        <span class="path97"></span>
                                        <span class="path98"></span>
                                        <span class="path99"></span>
                                        <span class="path100"></span>
                                        <span class="path101"></span>
                                        <span class="path102"></span>
                                        <span class="path103"></span>
                                        <span class="path104"></span>
                                        <span class="path105"></span>
                                        <span class="path106"></span>
                                        <span class="path107"></span>
                                        <span class="path108"></span>
                                        <span class="path109"></span>
                                        <span class="path110"></span>
                                        <span class="path111"></span>
                                        <span class="path112"></span>
                                        <span class="path113"></span>
                                        <span class="path114"></span>
                                        <span class="path115"></span>
                                        <span class="path116"></span>
                                        <span class="path117"></span>
                                        <span class="path118"></span>
                                        <span class="path119"></span>
                                        <span class="path120"></span>
                                        <span class="path121"></span>
                                        <span class="path122"></span>
                                        <span class="path123"></span>
                                        <span class="path124"></span>
                                        <span class="path125"></span>
                                        <span class="path126"></span>
                                        <span class="path127"></span>
                                        <span class="path128"></span>
                                    </span>
                                    <p className="text fst-italic text-center font-m">No Data Found!</p>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </>
        </div>
    )
}

export default TodayProgress
