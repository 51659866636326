import React, { useEffect, useRef, useState, forwardRef } from "react";
import PopupCheckout from "./PopupCheckout";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import MonthlyAttendance from "./MonthlyAttendance";
import WeeklyAttendance from "./WeeklyAttendance";
import Tab from "react-bootstrap/Tab";
import close from "../../assetsOld/img/x-close.svg"
import Tabs from "react-bootstrap/Tabs";
import Nav from "react-bootstrap/Nav";
import { ServerRequestProtectedParentID, ServerRequestProtected } from "../../utils/ServerDataRequest";
import { enumUtil } from "../../utils/Enum";
import NotificationHandler from "../../utils/NotificationHandler";
import Loader from "../shared/Loader/Loader"
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from 'jwt-decode';
import EmployeeInformation from "../MyInfo/Account/EmployeeInformation/EmployeeInformation";
import { TabActionAttendance } from "../../actions/TabActions";
import isNull from "../../utils/NullChecking";
import DailyAttendance from "./DailyAttendance";
import { format } from 'date-fns';
import { DateFormat, DateFormat_dMy } from "../../utils/Constants"
import CalendarInput from "../../utils/CalendarInput";
import CustomUseRef from "../shared/CustomUseRef/CustomUseRef";

const AttendanceSearch = ({ Active, setRecordsNum, refresh, setRefresh, type }) => {

  let dispatch = useDispatch()
  const popperRef = useRef(null);
  let token = useSelector(state => state.TokenReducer);
  let Token = token && jwt_decode(token)
  let UserID = Active ? String(Active) : null;
  let OrgID = String(useSelector(state => state.Organization))
  /////////Year Options///////
  const currentMonth = String(new Date().getMonth() + 1);
  const currentYear = String(new Date().getFullYear());
  const yearOptions = Array.from({ length: 3 }, (_, i) => currentYear - 2 + i);
  const currentDay = format(new Date(), DateFormat_dMy);
  const currentDaySave = format(new Date(), DateFormat);
  ////////////////////////////
  let AttAndLv_Modal = {
    // "OrganizationID": OrgID,
    "ShiftIDs": null,
    "TeamIDs": null,
    "DepartmentIDs": null,
    "Search": null,
    Month: currentMonth,
    Year: currentYear,
  }
  let OverAllData_Search = {
    // "OrganizationID": OrgID,
    "ShiftIDs": null,
    "TeamIDs": null,
    "DepartmentIDs": null,
    "EmployeeIDs": null,
    "Month": currentMonth,
    "Year": currentYear,
  }
  const Attendance_Details = {
    "PresentEmployees": "",
    "TotalWeekendDays": "",
    "Leaves": "",
    "AbsentEmployees": "",
    "TotalHolidays": ""
  }
  const Month_Year = {
    Month: currentMonth,
    Year: currentYear,
  }

  let AttAndLvEmp_Modal = {
    EmployeeID: UserID,
    Year: currentYear,
  }

  let OverAll_AttAndLv_Modal = {
    EmployeeID: UserID,
    // OrganizationID: OrgID + "",
    Months: "",
    Year: currentYear,
  }
  let SelectYear_Modal = {
    Year: currentYear,
  }

  let ScreenShot_Modal = {
    Date: currentDay,
  }
  let ScreenShotSave_Modal = {
    Date: currentDaySave,
  }
  const [mainAttendanceDateModal, setAttendanceDateModal] = useState(ScreenShot_Modal)
  const [saveAttendanceDateModal, setSaveAttendanceDateModal] = useState(ScreenShotSave_Modal)
  const [showFilter, setShowFilter] = useState(false)
  const [searchMonthlyParam, setsearchMonthlyParam] = useState(AttAndLvEmp_Modal);
  const hasProps = type && type === "Employee";
  // const [totalEmployees, setTotalEmployees] = useState(null);
  const [selectDate, setSelectDate] = useState(Month_Year);
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState()
  const API_URL = process.env.REACT_APP_API
  const [activeTab, setActiveTab] = useState("today");
  const [counter, setCounter] = useState(0)
  const [attendanceEmployeeData, setAttendanceEmployeeData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([])
  const [selectedEmployeeIDs, setSelectedEmployeeIDs] = useState([]);
  const [employeeOverAllAttendance, setEmployeeOverAllAttendance] = useState()
  const [selectDateEmp, setSelectDateEmp] = useState(SelectYear_Modal)
  const [searchOverAllEmployeeData, setSearchOverAllEmployeeData] = useState(OverAll_AttAndLv_Modal);
  const [searchParam, setSearchParam] = useState(AttAndLv_Modal)
  const [monthlyParam, setMonthlyParam] = useState(AttAndLv_Modal)
  const [searchOverAllDataParam, setSearchOverAllDataParam] = useState(OverAllData_Search)
  const [YearOptions, setYearOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [filtersButton, setFiltersButton] = useState(false)

  const [dropDownDataShift, setDropDownDataShift] = useState([])
  const [shiftValue, setShiftValue] = useState([])
  const [dropDownDataDepartment, setDropDownDataDepartment] = useState([])
  const [departmentValue, setDepartmentValue] = useState([])
  const [dropDownDataTeam, setDropDownDataTeam] = useState([])
  const [teamValue, setTeamValue] = useState([])
  const [mainAttendanceAndLeavesModal, setMainAttendanceAndLeavesModal] = useState(Attendance_Details);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  }
  const handleInputChange = (event, value, reason) => {
    if (reason === 'input') {
      setIsDropdownOpen(true);
    }
  };
  const selectedDate = new Date()
  selectedDate.setMonth(parseInt(selectDate.Month, 10) - 1)
  selectedDate.setFullYear(parseInt(selectDate.Year, 10));
  const [currentWeek, setCurrentWeek] = useState(selectedDate);
  const [position, setPosition] = useState(null);
  const openDateRangePicker = Boolean(position);

  const openDateRangePickerMenu = (event) => {
    setPosition(event.currentTarget);
  };
  const closeDateRangePickerMenu = () => {
    setPosition(null);
  };

  /////////Year Options///////
  const getYearsOptions = (joiningYear) => {
    const currentMonth = String(new Date().getMonth() + 1);
    const currentYear = String(new Date().getFullYear());
    for (let year = joiningYear; year <= currentYear; year++) {
      let yearExist = YearOptions.find((option) => year === option);
      if (!yearExist) {
        YearOptions.push(year);
      }
    }

  }

  const SearchOnEnter = (e) => {
    if (e.key === "Enter") {
      setSearch(e.target.value.trim(""))
      setSearchParam({ ...searchParam, Search: e.target.value.trim("") })
      /// change ///
      setShiftValue([])
      setTeamValue([])
      setDepartmentValue([])
      SearchByParam(searchParam)
    }
  }
  const SearchByParam = (searchParam) => {
    if (!isNull(searchParam)) {
      LoadData(searchParam)
      setMonthlyParam(searchParam);
    } else {
      LoadData()
      setMonthlyParam();
    }
  }


  const handleCalendarChange = (date, name) => {
    var FormatedDateSave = format(date, DateFormat)
    var FormatedDateShow = format(date, DateFormat_dMy)
    let modal = { ...mainAttendanceDateModal };
    let saveModal = { ...saveAttendanceDateModal }
    modal[name] = FormatedDateShow
    setAttendanceDateModal(modal)
    for (var property in ScreenShotSave_Modal) {
      if (name === property) {
        saveModal[name] = FormatedDateSave
      }
    }
    setSaveAttendanceDateModal(saveModal)
    closeDateRangePickerMenu()
  }
  //////----Changes------//////

  const handleUpdateMonthSearchParam = (e) => {//////----Changes------///////
    e.preventDefault();
    setSelectedEmployeeIDs([]);
    const updatedSearchOverAllDataParam = {
      ...searchOverAllDataParam,
      Month: e.target.value,
    };

    setSearchOverAllDataParam(updatedSearchOverAllDataParam);
    const updatedAttAndLv_Modal = { ...searchParam, Month: e.target.value };
    setSearchParam(updatedAttAndLv_Modal);
    const updated_Modal = { ...monthlyParam, Month: e.target.value };
    setMonthlyParam(updated_Modal);
    const updatedSelectDate = {
      ...selectDate,
      Month: e.target.value,
    };
    setSelectDate(updatedSelectDate);
  };

  //   const OpenAttendance = (data) => {
  //     let obj = {
  //         key: 2,
  //         name: "Attendance",
  //         active: true,
  //         data: {
  //             id: data.id,
  //             name: data.name,
  //         },
  //     };
  //     dispatch(TabActionAttendance(obj));
  // }
  //////----Changes------///////
  const handleUpdateYearSearchParam = (e) => { //////----Changes------///////
    e.preventDefault();
    setSelectedEmployeeIDs([]);
    const updatedSearchOverAllDataParam = {
      ...searchOverAllDataParam,

      Year: e.target.value,
    };

    setSearchOverAllDataParam(updatedSearchOverAllDataParam);
    const updatedAttAndLv_Modal = { ...searchParam, Year: e.target.value };
    setSearchParam(updatedAttAndLv_Modal);
    const updated_Modal = { ...monthlyParam, Year: e.target.value };
    setMonthlyParam(updated_Modal);
    const updatedSelectDate = {
      ...selectDate,

      Year: e.target.value,
    };
    setSelectDate(updatedSelectDate);
  };
  /////----EmployeeChanges---///////
  const handleUpdateEmployeeYearSearchParam = (e) => {
    const updatedOverAllModal = { ...searchOverAllEmployeeData, Year: e.target.value }
    setSearchOverAllEmployeeData(updatedOverAllModal);
    const updatedMonthlyModel = { ...searchMonthlyParam, Year: e.target.value }
    setsearchMonthlyParam(updatedMonthlyModel);
    const updatedSelectDate = { ...selectDateEmp, Year: e.target.value }
    setSelectDateEmp(updatedSelectDate)
  }
  const handleUpdateEmployeeDailySearchParam = (e) => {
    const updatedOverAllModal = { ...searchOverAllEmployeeData, Year: e.target.value }
    setSearchOverAllEmployeeData(updatedOverAllModal);
    const updatedMonthlyModel = { ...searchMonthlyParam, Year: e.target.value }
    setsearchMonthlyParam(updatedMonthlyModel);
    const updatedSelectDate = { ...selectDateEmp, Year: e.target.value }
    setSelectDateEmp(updatedSelectDate)
  }
  ////////////////////////////////////////////
  const handleCheckboxChange = (e, employeeId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setCounter(counter + 1)
      setSelectedEmployeeIDs([...selectedEmployeeIDs, employeeId]);
      setSearchOverAllDataParam({ ...searchOverAllDataParam, EmployeeIDs: [...selectedEmployeeIDs, employeeId].toString(), Month: selectDate.Month })
      // setSearchOverAllDataParam({ ...searchOverAllDataParam, Month: selectDate.Month })
    } else {
      setCounter(counter - 1)
      setSelectedEmployeeIDs(selectedEmployeeIDs.filter(id => id !== employeeId));
      if (selectedEmployeeIDs.filter(id => id !== employeeId).length === 0) { setSearchOverAllDataParam({ ...searchOverAllDataParam, EmployeeIDs: null }) }
      else { setSearchOverAllDataParam({ ...searchOverAllDataParam, EmployeeIDs: selectedEmployeeIDs.filter(id => id !== employeeId).toString() }) }
      // else { setSearchOverAllDataParam({ ...searchOverAllDataParam, }) }
    }
  };
  const LoadDropDowns = () => {
    ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
      "TableName": enumUtil.enumAPITableName.Shift,
    }).then((response) => {
      setDropDownDataShift(response)
    }).catch((error) => {
      setIsLoading(false);
      // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
    })


    ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
      "TableName": enumUtil.enumAPITableName.Department,
    }).then((response) => {
      setDropDownDataDepartment(response)
    }).catch((error) => {
      // setIsLoading(false);
      // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
    })

    ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
      "TableName": enumUtil.enumAPITableName.Team,
    }).then((response) => {
      setDropDownDataTeam(response)
    }).catch((error) => {
      // setIsLoading(false);
      // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
    })

  }
  const LoadData = (searchParam) => {
    setIsLoading(true);
    // setSearchOverAllDataParam(OverAllData_Search)
    setAttendanceData([])
    ServerRequestProtected(API_URL + "/FindByMultipleCriteria", 'post', {
      "TableName": enumUtil.enumAPITableName.Attendance,
      "SearchCriteria": searchParam,
    })
      .then((response) => {
        let jsonStr = '';
        for (let i in response) {
          const jsonData = response[i]['JSON_F52E2B61-18A1-11d1-B105-00805F49916B'];
          for (let j in jsonData) {
            jsonStr += jsonData[j];
          }
        }
        // console.log("jsonStr",jsonStr);
        const parsedJson = JSON.parse(jsonStr);
        setAttendanceData(parsedJson.Employees);
        setIsLoading(false);
        CloseFiltersPopup()
      }).catch((error) => {
        setIsLoading(false);
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
      })
  }

  ///////////////// Logged User Attendance Data /////////////////
  const LoadDataEmployee = (searchMonthlyParam) => {
    setIsLoading(true);
    setAttendanceEmployeeData([])
    ServerRequestProtected(API_URL + "/FindByMultipleCriteria", 'post', {
      "TableName": enumUtil.enumAPITableName.EmployeeAttendence,
      "SearchCriteria": searchMonthlyParam,
    })
      .then((response) => {
        let jsonStr = '';
        for (let i in response) {
          const jsonData = response[i]['JSON_F52E2B61-18A1-11d1-B105-00805F49916B'];
          for (let j in jsonData) {
            jsonStr += jsonData[j];
          }
        }
        const parsedJson = JSON.parse(jsonStr);
        setAttendanceEmployeeData(parsedJson);
        getYearsOptions(parsedJson[0]?.JoiningYear);
        // NotificationHandler("Data Fetched Successfuly", enumUtil.enumtoaster.success)
        setIsLoading(false);
      }).catch((error) => {
        setIsLoading(false);
        // NotificationHandler("Failed to load Data", enumUtil.enumtoaster.error);
      })
  }
  const LoadOverAllData = (searchOverAllDataSearch) => {
    ServerRequestProtected(API_URL + "/GetCalculatedAttendance", 'post', {
      "TableName": enumUtil.enumAPITableName.Attendance,
      "SearchCriteria": searchOverAllDataSearch,
    })
      .then((response) => {
        console.log("first", response)
        setMainAttendanceAndLeavesModal(response[0])
        setIsLoading(false);
        // setTotalEmployees(response[0].TotalEmployees);
      }).catch((error) => {
        setIsLoading(false);
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
      })
  }

  const LoadOverAllDataEmployee = (searchOverAllEmployeeData) => {
    setIsLoading(true);
    ServerRequestProtected(API_URL + "/GetCalculatedAttendance", "post", {
      "TableName": enumUtil.enumAPITableName.EmployeeAttendence,
      "SearchCriteria": searchOverAllEmployeeData,
    }).then((response) => {
      setIsLoading(false);
      console.log("responseData", response);
      setEmployeeOverAllAttendance(response[0]);
      console.log("employeeOverAll", employeeOverAllAttendance);
      // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success);
    }).catch((error) => {
      setIsLoading(false);
      // NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
    })
  }
  const handleSearchValue = (e) => {
    setSearch(e.target.value.trim(""))
    setSearchParam({ ...searchParam, Search: e.target.value.trim("") })
  }
  const handleLoadData = (searchParam) => {
    LoadData(searchParam);
    setMonthlyParam(searchParam);
  }
  const clearFilters = () => {
    setSearch("")
    setDepartmentValue([]);
    setTeamValue([]);
    setShiftValue([]);
    setSearchParam(AttAndLv_Modal);
  }

  const CloseFiltersPopup = () => {
    clearFilters();
    setFiltersButton(false);
  }
  const handeFiltersPopUp = (e, id) => {
    e.stopPropagation();
    setFiltersButton(true);
  }
  //////////////////////// Pre, Next week, /////////////////////////
  const handlePrevWeek = () => {
    // setCounter(0)
    const prevWeek = new Date(currentWeek);
    prevWeek.setDate(prevWeek.getDate() - 7);
    setCurrentWeek(prevWeek);
  };

  const handleNextWeek = () => {
    // setCounter(0)
    const nextWeek = new Date(currentWeek);
    nextWeek.setDate(nextWeek.getDate() + 7);
    setCurrentWeek(nextWeek);
  };

  // Accessing data for a specific month and year (e.g., May 2023)
  // const may2023Data = groupedData['5/2023'];
  // console.log(may2023Data);

  //////----Changes------///////
  useEffect(() => {
    LoadDataEmployee(searchMonthlyParam);
  }, [searchMonthlyParam.Year, OrgID]);

  useEffect(() => {
    setCounter(0);
    LoadData(searchParam);
  }, [searchParam.Month, searchParam.Year, OrgID]);

  useEffect(() => {
    LoadDropDowns();
  }, [OrgID]);

  useEffect(() => {
    LoadOverAllData(searchOverAllDataParam);
  }, [searchOverAllDataParam, OrgID]);

  useEffect(() => {
    LoadOverAllDataEmployee(searchOverAllEmployeeData);
  }, [searchOverAllEmployeeData, OrgID]);

  useEffect(() => {
    const selectedDate = new Date()
    selectedDate.setMonth(parseInt(selectDate.Month, 10) - 1)
    selectedDate.setFullYear(parseInt(selectDate.Year, 10));
    setCurrentWeek(selectedDate);
  }, [selectDate])
  //////----Changes------///////

  const handleMultiSelectShiftChange = (values, type, reason, e) => {

    let arr = [];
    let arrVal = []
    values.map((key, index) => {
      arr.push(key)
    })
    values.map((key, index) => {
      arrVal.push(key.ShiftID)
    })
    setShiftValue(arr)
    if (arrVal == "") { setSearchParam({ ...searchParam, ShiftIDs: null }) }
    else { setSearchParam({ ...searchParam, ShiftIDs: arrVal.toString() }) }
  }
  const handleMultiSelectTeamChange = (values, type, reason) => {
    let arr = [];
    let arrVal = []
    values.map((key, index) => {
      arr.push(key)
    })
    values.map((key, index) => {
      arrVal.push(key.TeamID)
    })
    setTeamValue(arr)
    if (arrVal == "") { setSearchParam({ ...searchParam, TeamIDs: null }) }
    else { setSearchParam({ ...searchParam, TeamIDs: arrVal.toString() }) }
  }
  const handleMultiSelectDepartmentChange = (values, type, reason) => {
    let arr = [];
    let arrVal = []
    values.map((key, index) => {
      arr.push(key)
    })
    values.map((key, index) => {
      arrVal.push(key.DepartmentID)
    })
    setDepartmentValue(arr)
    if (arrVal == "") { setSearchParam({ ...searchParam, DepartmentIDs: null }) }
    else { setSearchParam({ ...searchParam, DepartmentIDs: arrVal.toString() }) }
  }
  const handleWeekSearch = (date) => {
    setCounter(0)
    setSelectedEmployeeIDs([]);
    const startOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1);
    const endOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 7);
    const dateStringStart = `${startOfWeek.getFullYear()}/${startOfWeek.getMonth() < 9 && '0'}${startOfWeek.getMonth() + 1}/${startOfWeek.getDate() < 10 ? '0' + startOfWeek.getDate() : startOfWeek.getDate()}`;
    const dateStringEnd = `${startOfWeek.getFullYear()}/${endOfWeek.getMonth() < 9 && '0'}${endOfWeek.getMonth() + 1}/${endOfWeek.getDate() < 10 ? '0' + endOfWeek.getDate() : endOfWeek.getDate()}`;
    setSearchOverAllDataParam({ ...searchOverAllDataParam, EmployeeIDs: null, Month: searchParam.Month, Year: searchParam.Year, StartDate: dateStringStart, EndDate: dateStringEnd })
  }
  const handleMonthSearch = () => {
    setCounter(0);
    setSelectedEmployeeIDs([]);
    setSearchOverAllDataParam({ ...searchOverAllDataParam, EmployeeIDs: null, StartDate: null, EndDate: null, Month: searchParam.Month, Year: currentYear + "" })
  }
  const getMonthName = (monthNumber) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return months[monthNumber - 1]; // Adjusting for array index
  };
  return (
    <div className="hk-ems-v1 empolyee_attendance">
      <Tabs className="rb-tabs bg-light-blue">
        <Tab eventKey="allattendance" title="All Attendance">
          <Tab.Container defaultActiveKey="today">
            <div className="ar_action-row align-items-center br-bottom-1 container-fluid d-flex justify-content-between clr_light">
              <div className="d-flex gap-2 justify-content-start flex-wrap">
                <div className="hk-attendance-status green-bg">Present | {mainAttendanceAndLeavesModal?.PresentEmployees || 0}</div>
                <div className="hk-attendance-status litePink-bg">
                  Absent | {mainAttendanceAndLeavesModal?.AbsentEmployees || 0}
                </div>
                <div className="hk-attendance-status gray-bg">Weekends | {mainAttendanceAndLeavesModal?.TotalWeekendDays || 0}</div>
                <div className="hk-attendance-status liteBlue-bg">
                  National Holidays | {mainAttendanceAndLeavesModal?.NationalHolidays || 0}
                </div>
                <div className="hk-attendance-status pink-bg">
                  Leaves | {mainAttendanceAndLeavesModal?.Leaves || 0}
                </div>
                <div className="hk-attendance-status liteOrnage-bg">
                  Late count | {mainAttendanceAndLeavesModal?.LateCount || 0}
                </div>
              </div>
              <div className="d-flex align-items-center gap-15">
                <div class="input employee_screen">
                  <div class="input-group hk-input-search">
                    <input type="text"
                      class="form-control fc-xs"
                      placeholder="Search by name or id…"
                      value={search}
                      onChange={e => handleSearchValue(e)}
                      onKeyDown={SearchOnEnter}
                    />
                    <span class="input-group-text cursor-pointer" id="basic-addon2" onClick={() => handleLoadData(searchParam)}>
                      <p className="text">Search</p>
                    </span>
                  </div>
                </div>
                <i className="fs-18 icon-Vector5" onClick={(e) => handeFiltersPopUp(e)}></i>
              </div>
            </div>
            <>


              {/* <CustomUseRef IsActivePtPayments={filtersButton} onClose={CloseFiltersPopup}> */}
              {filtersButton &&
                <div className="education_popup employee_filter_popup">
                  <img className="cursor-pointer" src={close} onClick={(e) => CloseFiltersPopup(e)} ></img>
                  <div className="text-center">
                    <p className="heading mt-2">Filters</p>
                  </div>
                  <div>
                    {/* <div className="form-group input position-relative">
                          <label>Employee</label>
                          <Autocomplete
                            multiple
                            limitTags={2}
                            id='ShiftIDs'
                            name='ShiftIDs'
                            value={shiftValue}
                            options={dropDownDataShift}
                            isOptionEqualToValue={(option, value) => option.ShiftID === value.ShiftID}
                            onChange={(event, value, reason) => handleMultiSelectShiftChange(value, 'ShiftIDs', reason)}
                            getOptionLabel={(option) => option.ShiftName}
                            noOptionsText={"Not Available"}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard"
                              // placeholder="Shifts" 
                              />
                            )}
                          />
                        </div> */}
                    <div className="form-group input position-relative">
                      <label>Shifts</label>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id='ShiftIDs'
                        name='ShiftIDs'
                        value={shiftValue}
                        options={dropDownDataShift}
                        isOptionEqualToValue={(option, value) => option.ShiftID === value.ShiftID}
                        onChange={(event, value, reason, e) => handleMultiSelectShiftChange(value, 'ShiftIDs', reason, e)}
                        getOptionLabel={(option) => option.ShiftName}
                        noOptionsText={false}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard"
                          // placeholder="Shifts" 
                          />
                        )}

                      />
                    </div>
                    <div className="form-group input">
                      <label>Departments </label>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id='DepartmentIDs'
                        name='DepartmentIDs'
                        value={departmentValue}
                        options={dropDownDataDepartment}

                        isOptionEqualToValue={(option, value) => option.DepartmentID === value.DepartmentID}
                        onChange={(event, value, reason) => handleMultiSelectDepartmentChange(value, 'DepartmentIDs', reason)}
                        getOptionLabel={(option) => option.DepartmentName}
                        noOptionsText={"Not Available"}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard"
                          // placeholder="Departments" 
                          />
                        )}
                      />
                    </div>
                    <div className="form-group input">
                      <label>Teams </label>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id='TeamIDs'
                        name='TeamIDs'
                        value={teamValue}
                        options={dropDownDataTeam}
                        isOptionEqualToValue={(option, value) => option.TeamID === value.TeamID}
                        onChange={(event, value, reason) => handleMultiSelectTeamChange(value, 'TeamIDs', reason)}
                        getOptionLabel={(option) => option.TeamName}
                        noOptionsText={"Not Available"}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard"
                          // placeholder="Teams" 
                          />
                        )}
                      />
                    </div>
                    <div className="d-flex justify-content-end gap-3 ">
                      <button class="btn btn-gray" onClick={(e) => CloseFiltersPopup(e)} >Cancel</button>
                      <button class="btn btn-blue" onClick={() => handleLoadData(searchParam)}>Apply</button>
                    </div>
                  </div>
                </div>
              }
              {/* </CustomUseRef> */}
            </>
            <div className="container-fluid ar_action-row br-bottom-1 clr_light py-2 d-flex justify-content-between flex-column flex-sm-row dropdown-bgc-position date-slect-bg py-0 align-items-center">
              <div className="col-md-6 align-items-center justify-content-between d-flex">
                <div>
                  <p className="heading">Attendance Month - <span className="text clr_gry">{getMonthName(selectDate.Month)}</span></p>
                </div>
                <div className="hk-timeline-btns w-fit-content">
                  <div className='app-tabs'>
                    <div className='position-relative mx-auto'>
                      <div className="row h-100 justify-content-center align-items-cente">
                        <div className="tabs-v2-custom ar_tab-v2-custom">
                          <Nav variant="tabs" className="rb-tabs-v3 tabs_view" activeKey={activeTab} onSelect={handleTabSelect}>
                            <Nav.Item>
                              <Nav.Link eventKey="today">Today</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="monthly">Monthly</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="weekly">Weekly</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hk-ems-searchBar p-0 col-md-6 justify-content-end gap-3">
                {activeTab === "monthly" &&
                  <div className="input flex-row gap-2 align-items-center">
                    <label>Month</label>
                    <select className="form-control"
                      name="Month"
                      id="Month"
                      aria-label="Default select example"
                      value={selectDate.Month}
                      onChange={(e) => handleUpdateMonthSearchParam(e)}
                    >
                      <option value={1}>January</option>
                      <option value={2}>February</option>
                      <option value={3}>March</option>
                      <option value={4}>April</option>
                      <option value={5}>May</option>
                      <option value={6}>June</option>
                      <option value={7}>July</option>
                      <option value={8}>August</option>
                      <option value={9}>September</option>
                      <option value={10}>October</option>
                      <option value={11}>November</option>
                      <option value={12}>December</option>
                    </select>
                  </div>}
                {activeTab === "monthly" &&
                  <div className="input flex-row gap-2 align-items-center">
                    <label>Year</label>
                    <select className="form-control"
                      name="Year"
                      id="Year"
                      aria-label="Default select example"
                      value={selectDate.Year}
                      onChange={(e) => handleUpdateYearSearchParam(e)}
                    >
                      {yearOptions.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>}
                {activeTab == "weekly" &&
                  <>
                    <div className="weekly">
                      <p className="text"><span><i className="icon-angle-left" onClick={() => handlePrevWeek()}></i></span>{currentWeek.toLocaleString("default", { month: "long", year: "numeric" })}<span> <i className="icon-right-direction" onClick={() => handleNextWeek()}></i></span></p>
                    </div>
                  </>

                }
                {activeTab == "today" &&
                  <>
                    <div className="today">
                      <CalendarInput
                        // label='Valid Till'
                        value={mainAttendanceDateModal.Date}
                        openCalendarMenufunc={(event) => openDateRangePickerMenu(event, 'CreatedDate')}
                        maxDate={new Date()}
                        anchorEl={position}
                        open={openDateRangePicker}
                        closeCalendarMenufunc={closeDateRangePickerMenu}
                        handleCalendarChangefunc={date => handleCalendarChange(date, 'Date')}
                      />
                    </div>
                  </>

                }
              </div>
            </div>

            <div className="row row-gap-15 row-half pe-2">
              <Tab.Content>
                <Tab.Pane eventKey={"today"}>
                  <DailyAttendance handleCheckboxChange={handleCheckboxChange} searchParamDaily={saveAttendanceDateModal} searchParamMonthly={monthlyParam} />
                </Tab.Pane>
                <Tab.Pane eventKey={"monthly"}>
                  <MonthlyAttendance attendanceData={attendanceData} handleCheckboxChange={handleCheckboxChange} handleMonthSearch={handleMonthSearch} searchParamMonthly={monthlyParam} />
                </Tab.Pane>
                <Tab.Pane eventKey={"weekly"}>
                  <WeeklyAttendance setCounter={setCounter} attendanceData={attendanceData} selectDate={selectDate} handleCheckboxChange={handleCheckboxChange} handleWeekSearch={handleWeekSearch} current={currentWeek} isLoading={isLoading} />
                </Tab.Pane>
              </Tab.Content>
            </div>

          </Tab.Container>
        </Tab>
      </Tabs>

    </div>
  )

};

const CustomPopper = forwardRef((props, ref) => {
  useEffect(() => {
    if (ref?.current) {
      ref?.current?.addEventListener('click', (event) => {
        event?.stopPropagation();
      });
    }

    // Clean up event listener on unmount
    return () => {
      if (ref?.current) {
        ref?.current?.removeEventListener('click', (event) => {
          event?.stopPropagation();
        });
      }
    };
  }, [ref]);

  return (
    <div ref={ref} {...props}>
      {props?.children}
    </div>
  );
});


export default AttendanceSearch;

